import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {ISavingProblem} from '../../../../../interfaces/saving/ISavingProblem';
import {ISavingInspection} from '../../../../../interfaces/saving/ISavingInspection';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {addTempFiles} from '../../../../../api/cache/utils/addTempFiles';

export type IAddPresaleChecksResponse = IPresaleCheck[];
export type IAddProblemsResponse = IProblem[];
export type IAddInspectionsResponse = IInspection[];

/**
 * Добавляет предпродажные проверки с сущностями (нарушение/осмотр)
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param presaleCheckType тип выбранной сущности (нарушение/осмотр)
 * @param savingData данные для сохранения осмотра/нарушения
 */

export const requestAddMultiplePresaleChecks = async (
	objectId: string,
	spaceIds: string[],
	links: IPresaleCheck['links'] | undefined,
	savingData: ISavingProblem | ISavingInspection,
	presaleCheckType?: string
) => {
	savingData.newAttachments = await addTempFiles(savingData.newAttachments);

	const response = await httpRequests.withToken.post<
		Record<string, IAddPresaleChecksResponse | IAddProblemsResponse | IAddInspectionsResponse>
	>('/presale-checks/add-with-entities', {
		objectId,
		spaceIds,
		links,
		presaleCheckType,
		savingData
	});

	return response.data;
};
