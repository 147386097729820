import declination from '@tehzor/tools/utils/declination';
import {useMemo} from 'react';

interface IExportTemplatesPageHeaderProps {
	total: number;
}

const words = ['шаблон', 'шаблона', 'шаблонов'];

export const ExportTemplatesPageHeader = ({total}: IExportTemplatesPageHeaderProps) => {
	const amount = useMemo(() => `Всего: ${total} ${declination(total, words)}`, [total]);

	return (
		<div className="manage-export-templates-page__page-header">
			<p className="manage-export-templates-page__page-header-amount">{amount}</p>
		</div>
	);
};
