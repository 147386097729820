import classNames from 'classnames';
import {CSSProperties, ReactNode} from 'react';

interface ITableHeadProps {
	className?: string;
	style?: CSSProperties;
	role?: string;
	children?: ReactNode;
}

const TableHead = ({className, style, role, children}: ITableHeadProps) => (
	<div
		className={classNames('table2__head', className)}
		style={style}
		role={role}
	>
		{children}
	</div>
);

export default TableHead;
