import {IPreparedClaim} from '@src/pages/WarrantyClaimsPage/interfaces/IPreparedClaim';
import {useTranslation} from 'react-i18next';
import {SelectionActions} from '../selection/SelectionActions';
import {useWarrantyClaimsExport} from '@src/core/hooks/export/useWarrantyClaimsExport';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useMemo} from 'react';

interface IMobileRightButtonsProps {
	selectedEntities?: IPreparedClaim[];
	selectedClearing: () => void;
	objectId?: string;
}

const exportIcon = <i className="tz-export-20" />;

export const MobileRightButtons = (props: IMobileRightButtonsProps) => {
	const {selectedEntities, selectedClearing, objectId} = props;
	const showSelectionActions = selectedEntities && selectedEntities.length > 0;
	const {t} = useTranslation();

	const updateData = useAppUpdateMenuItem();
	const {canExport} = useWarrantyClaimsPermissions(objectId);
	const [exportDialog, openExportDialog] = useWarrantyClaimsExport(objectId);

	const items = useMemo(() => {
		const baseItems = [updateData];
		if (canExport) {
			baseItems.push(
				<MenuItem
					key="export"
					icon={exportIcon}
					onClick={openExportDialog}
				>
					{t('exportButton.label')}
				</MenuItem>
			);
		}
		return baseItems;
	}, [updateData, canExport, t, openExportDialog]);

	return (
		<div>
			{showSelectionActions ? (
				<SelectionActions
					selectedEntities={selectedEntities}
					selectedClearing={selectedClearing}
				/>
			) : null}
			<IconMenu>{items}</IconMenu>
			{canExport && exportDialog}
		</div>
	);
};
