import {createMutation} from 'react-query-kit';
import {internalAcceptancesQueryKeys} from '../keys';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {IAddInternalAcceptanceParams} from '../defaults/useInternalAcceptancesMutationDefaults';

export const useAddInternalAcceptanceMutation = createMutation<
	IInternalAcceptance,
	IAddInternalAcceptanceParams
>({
	mutationKey: internalAcceptancesQueryKeys.add(),
	scope: {id: 'internalAcceptances'}
});
