import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IEntitySelectProps} from '../components/EntitySelect/EntitySelect';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export const getTitle = (
	selectedStage: ObjectStageIds,
	selectedProcess: ProcessIds,
	entitiesSelectMapProps: Record<ObjectStageIds, Record<ProcessIds, IEntitySelectProps>>,
	entityButtonTitle?: string
) => {
	if (entityButtonTitle) {
		return entityButtonTitle;
	}

	const {name, newName, selected, entities, localEntities} =
		entitiesSelectMapProps?.[selectedStage]?.[selectedProcess];

	if (!selected) {
		return newName;
	}
	const entity = entities && entities.find(entity => selected === entity.id);
	if (entity) {
		return `${name} №${entity.number ?? entity.localNumber}`;
	}

	const localEntity =
		localEntities && localEntities.find(localEntity => selected === localEntity.id);
	if (localEntity) {
		return `${name} №${localEntity.number}`;
	}
	return newName;
};
