import {ReactNode, useCallback} from 'react';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {ExportDialog} from '@src/components/ExportDialog';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractWarrantyClaimsPageSettings} from '@src/store/modules/settings/pages/warrantyClaims/selectors';
import useToggle from 'react-use/lib/useToggle';
import {useExportWarrantyClaims} from '../mutations/warrantyClaims/useExportWarrantyClaims';

const availableDestinations = [ExportTemplatesDestinationId.WARRANTY_CLAIMS];

export function useWarrantyClaimsExport(objectId = 'all'): [ReactNode, () => void] {
	const {filters, sort, selectedRows} = useAppSelector(s =>
		extractWarrantyClaimsPageSettings(s, objectId)
	);
	const [isOpen, toggleOpen] = useToggle(false);
	const {mutateAsync: exportWarrantyClaims} = useExportWarrantyClaims();
	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			await exportWarrantyClaims({
				templateId: template.id,
				objectId: objectId !== 'all' ? objectId : undefined,
				createDocument,
				email,
				filters,
				sort,
				selected: selectedRows
			});
		},
		[exportWarrantyClaims, objectId, filters, sort, selectedRows]
	);

	const dialog = (
		<ExportDialog
			objectId={objectId}
			pageKey="warrantyClaims"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={toggleOpen}
		/>
	);

	return [dialog, toggleOpen];
}
