export const stages: Record<string, string> = {
	building: 'Строительство',
	acceptance: 'Приёмка',
	transfer: 'Передача',
	warranty: 'Гарантия'
};

// export const stages: IStage[] = [
// 	{
// 		id: 'building',
// 		name: 'Строительство'
// 	},
// 	{
// 		id: 'acceptance',
// 		name: 'Приёмка'
// 	},
// 	{
// 		id: 'transfer',
// 		name: 'Передача'
// 	},
// 	{
// 		id: 'warranty',
// 		name: 'Гарантия'
// 	}
// ];
