import {useCallback} from 'react';
import Table from './Table';
import {Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeListOffset, changePageSize} from '@src/store/modules/settings/pages/spaces/actions';
import {batch} from 'react-redux';
import {useDesktopColumns} from '@src/pages/SpacesPage/hooks/useDesktopColumns';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';
import {useSpacesList, useSpacesUnlimitedList} from '../../../../core/hooks/queries/spaces/hooks';
import {useTargetObjects} from '../../../../core/hooks/queries/objects/hooks';

const pageSizes = [10, 20, 50, 100];

interface IDesktopTableProps {
	objectId: string;
}

const DesktopTable = ({objectId}: IDesktopTableProps) => {
	const {pageSize, listOffset} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const {data: targetObjects} = useTargetObjects(objectId);
	const {total} = useSpacesList(objectId, {objects: targetObjects});

	//! Выборка без лимита (pageSize) синхронизации помещений с шахматкой
	const {byId: allSpacesByTargetObjects} = useSpacesUnlimitedList(objectId, {
		objects: targetObjects
	});

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			dispatch(changeListOffset(objectId, selected * pageSize));
		},
		[objectId, pageSize]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			batch(() => {
				dispatch(changePageSize(objectId, value));
				dispatch(changeListOffset(objectId, listOffset - (listOffset % value)));
			});
		},
		[objectId, listOffset]
	);

	const pageCount = Math.ceil(total / pageSize);
	const currentPage = Math.floor(listOffset / pageSize);

	const columns = useDesktopColumns();

	return (
		<>
			<Plate
				className="spaces-page__plate"
				withoutPadding
			>
				<Table
					objectId={objectId}
					columns={columns}
					selectable
					allSpacesById={allSpacesByTargetObjects || {}}
				/>
			</Plate>

			<PaginationAndSize
				pagination={
					<Pagination
						pageCount={pageCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</>
	);
};

export default DesktopTable;
