import {useExtractCheckListsAsArrayForWorkAcceptances} from '@src/core/hooks/queries/checkLists/hooks';
import {useExtractCheckRecordStatusesByEntityTypeAsArray} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {useProblemStatusesAsArray} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useExtractWorkAcceptanceStatusesAsArray} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useExtractStructureTypesAsArray} from '@src/core/hooks/queries/structureTypes/hooks';
import {useTranslation} from 'react-i18next';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {StructuresDisplayMode} from '@src/interfaces/StructuresDisplayMode';
import {extractStructuresPageSettings, IStructuresFiltersState} from '@/entities/Structures';
import {EntitiesFilters} from '@/shared/lib/EntitiesFilters/ui/EntitiesFilters';
import {useEntitiesFiltersCtx} from '@/shared/lib/EntitiesFilters/hooks/useEntitiesFiltersCtx';
import {CategoriesSetFilter, ObjectsFilter, StatusesFilter} from '@/widgets/Filters';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';
import {
	useTargetObjects,
	useTargetObjectIdsHaveStructures
} from '@src/core/hooks/queries/objects/hooks';

interface IStructuresFiltersProps {
	objectId: string;
}

export const Filters = ({objectId}: IStructuresFiltersProps) => {
	const {t} = useTranslation();
	const {state} = useEntitiesFiltersCtx<IStructuresFiltersState>();
	const {data: problemStatuses} = useProblemStatusesAsArray();
	const {data: workAcceptanceStatuses} = useExtractWorkAcceptanceStatusesAsArray();
	const {data: checkListRecordStatuses} =
		useExtractCheckRecordStatusesByEntityTypeAsArray('check-list');
	const {data: checkLists} = useExtractCheckListsAsArrayForWorkAcceptances(objectId);
	const {data: types} = useExtractStructureTypesAsArray();
	const {displayMode} = useAppSelector(s => extractStructuresPageSettings(s, objectId));
	const {schemaStructureTypeId} = useAppSelector(s =>
		extractStructuresSchemaPageSettings(s, objectId)
	);

	const {data: targetObjects} = useTargetObjects(objectId);
	const {data: targetObjectsHaveStructures} = useTargetObjectIdsHaveStructures({
		objectId,
		filter: schemaStructureTypeId ? {type: schemaStructureTypeId} : undefined
	});

	return (
		<EntitiesFilters objectId={objectId}>
			{targetObjectsHaveStructures.length > 1 && (
				<ObjectsFilter
					value={state?.objects}
					targetObjects={targetObjects}
				/>
			)}

			{displayMode === StructuresDisplayMode.LIST && types && (
				<StatusesFilter
					label={t('entitiesFilters.statusesFilter.structureTypes.label')}
					filterName="types"
					statuses={state?.types}
					allStatuses={types}
					translationDictionaryKey={dictionaryKeys.structureTypes}
				/>
			)}

			{workAcceptanceStatuses && (
				<StatusesFilter
					label={t('entitiesFilters.statusesFilter.workAcceptanceStatuses.label')}
					filterName="workAcceptanceStatuses"
					statuses={state?.workAcceptanceStatuses}
					allStatuses={workAcceptanceStatuses}
					translationDictionaryKey={dictionaryKeys.workAcceptanceStatuses}
				/>
			)}

			{problemStatuses && (
				<StatusesFilter
					label={t('entitiesFilters.statusesFilter.problemStatuses.label')}
					filterName="problemStatuses"
					statuses={state?.problemStatuses}
					allStatuses={problemStatuses}
					translationDictionaryKey={dictionaryKeys.problemStatuses}
				/>
			)}

			<CategoriesSetFilter
				objectId={objectId}
				categories={state?.categories}
				label={t('entitiesFilters.CategoriesSetFilter.label')}
				visibleObjects={targetObjectsHaveStructures}
			/>

			{displayMode === StructuresDisplayMode.LIST && checkListRecordStatuses && (
				<StatusesFilter
					label={t('entitiesFilters.statusesFilter.checkListStatuses.label')}
					filterName="checkListStatuses"
					statuses={state?.checkListStatuses}
					allStatuses={checkListRecordStatuses}
					translationDictionaryKey={dictionaryKeys.checkListStatuses}
				/>
			)}

			{displayMode === StructuresDisplayMode.LIST && checkLists && (
				<StatusesFilter
					label={t('entitiesFilters.statusesFilter.checkList.label')}
					filterName="checkListIds"
					statuses={state?.checkListIds}
					allStatuses={checkLists}
				/>
			)}
		</EntitiesFilters>
	);
};
