import {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useSpacesExport} from '@src/core/hooks/export/useSpacesExport';

interface ISpacesExportBProps {
	objectId: string;
}

const DesktopSpacesExportBtn = ({objectId}: ISpacesExportBProps) => {
	const [exportDialog, openExportDialog] = useSpacesExport(objectId);
	const handleExport = useCallback(() => {
		openExportDialog(objectId);
	}, [objectId, openExportDialog]);

	return (
		<>
			<IconButton
				type="accent-blue"
				onClick={handleExport}
			>
				<i className="tz-export-20" />
			</IconButton>
			{exportDialog}
		</>
	);
};

export default DesktopSpacesExportBtn;
