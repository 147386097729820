import {EntityAdding} from './EntityAdding';

interface IDesktopTopActionsProps {
	objectId: string;
	structureId: string;
}

export const MainActionsDesktop = ({objectId, structureId}: IDesktopTopActionsProps) => (
	<EntityAdding
		objectId={objectId}
		structureId={structureId}
	/>
);
