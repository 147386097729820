import {useWorkAcceptancesExport} from '@src/core/hooks/export/useWorkAcceptancesExport';
import {Button, IconButton} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IDesktopWorkAcceptancesExportProps {
	objectId?: string;
}

const exportIcon = <i className="tz-export-20" />;

export const DesktopWorkAcceptancesExport = ({objectId}: IDesktopWorkAcceptancesExportProps) => {
	const {t} = useTranslation();
	const [exportDialog, openExportDialog] = useWorkAcceptancesExport(objectId);

	const button = objectId ? (
		<IconButton
			type="accent-blue"
			onClick={openExportDialog}
		>
			{exportIcon}
		</IconButton>
	) : (
		<Button
			type="accent-blue"
			label={t('exportButton.label')}
			leftIcon={exportIcon}
			onClick={openExportDialog}
		/>
	);

	return (
		<>
			{button}
			{exportDialog}
		</>
	);
};
