/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
	IEditableEntityAction,
	IEditableEntityState,
	isEntityEdited
} from '@tehzor/tools/core/states/editableEntityState';
import IFile from '@tehzor/tools/interfaces/IFile';
import {
	IObject,
	IObjectDateStageRange,
	IObjectManager
} from '@tehzor/tools/interfaces/objects/IObject';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingObject} from '@src/interfaces/saving/ISavingObject';
import {isEqual} from 'lodash';

export type IEditableObjectState = IEditableEntityState<{
	name?: string;
	companyId?: string;
	city?: string;
	address?: string;
	generalContractor?: string;
	constructionManager?: IObjectManager;
	projectManager?: IObjectManager;
	stages?: {
		[key in ObjectStageIds]?: IObjectDateStageRange | null;
	};
	image?: IFile;
}>;

export type IEditableObjectAction = IEditableEntityAction<IEditableObjectState, ISavingObject>;

const makeEmptyState = (): IEditableObjectState => ({
	name: undefined,
	companyId: undefined,
	city: undefined,
	address: undefined,
	generalContractor: undefined,
	constructionManager: undefined,
	projectManager: undefined,
	stages: undefined,
	image: undefined,
	customFields: {},
	errors: {
		name: false,
		companyId: false,
		city: false,
		generalContractor: false,
		constructionManager: false,
		projectManager: false,
		stages: false,
		image: false,
		customFields: {}
	}
});

export const makeDefaultData = (object?: IObject): ISavingObject | undefined => {
	if (!object) {
		return undefined;
	}

	return {
		name: object.name,
		companyId: object.companyId,
		city: object.city,
		address: object.address,
		generalContractor: object.generalContractor,
		constructionManager: object.constructionManager,
		projectManager: object.projectManager,
		stages: object.stages,
		image: object.fullImage,
		customFields: object.customFields
	};
};

export const init = (original?: ISavingObject): IEditableObjectState => {
	const empty = makeEmptyState();
	if (!original) {
		return empty;
	}

	return {
		name: original.name,
		companyId: original.companyId,
		city: original.city,
		address: original.address,
		generalContractor: original.generalContractor,
		constructionManager: original.constructionManager,
		projectManager: original.projectManager,
		stages: original.stages,
		image: original.image,
		errors: empty.errors,
		customFields: original.customFields
	};
};

const isNameEdited = (state: IEditableObjectState, original?: ISavingObject) =>
	original?.name ? original.name !== state.name : !!state.name;

const isCompanyIdEdited = (state: IEditableObjectState, original?: ISavingObject) =>
	original?.companyId ? original.companyId !== state.companyId : !!state.companyId;

const isCityEdited = (state: IEditableObjectState, original?: ISavingObject) =>
	original?.city ? original.city !== state.city : !!state.city;

const isAddressEdited = (state: IEditableObjectState, original?: ISavingObject) =>
	original?.address ? original.address !== state.address : !!state.address;

const isGeneralContractorEdited = (state: IEditableObjectState, original?: ISavingObject) =>
	original?.generalContractor
		? original.generalContractor !== state.generalContractor
		: !!state.generalContractor;

const isConstructionManagerEdited = (state: IEditableObjectState, original?: ISavingObject) =>
	original?.constructionManager
		? original.constructionManager?.fullName !== state.constructionManager?.fullName ||
		  original.constructionManager?.phone !== state.constructionManager?.phone
		: !!state.constructionManager;

const isProjectManagerEdited = (state: IEditableObjectState, original?: ISavingObject) =>
	original?.projectManager
		? original.projectManager?.fullName !== state.projectManager?.fullName ||
		  original.projectManager.phone !== state.projectManager?.phone
		: !!state.projectManager;

const isStagesEdited = (state: IEditableObjectState, original?: ISavingObject) =>
	state?.stages || original?.stages
		? Object.keys(state?.stages || original?.stages || {}).reduce((acc, stage) => {
				if (original?.stages?.[stage]?.from !== state.stages?.[stage]?.from) {
					acc = true;
				}
				if (original?.stages?.[stage]?.to !== state.stages?.[stage]?.to) {
					acc = true;
				}

				return acc;
		  }, false)
		: !!state.stages;

const isImageEdited = (state: IEditableObjectState, original?: ISavingObject) =>
	original?.image ? original.image.id !== state.image?.id : !!state.image?.id;

const areCustomFieldsEdited = (state: IEditableObjectState, original?: ISavingObject) => {
	if (original?.customFields && state.customFields) {
		return !isEqual(state.customFields, original?.customFields);
	}
	return state.customFields ? !!Object.keys(state.customFields).length : false;
};

export const isEdited = (state: IEditableObjectState, original?: ISavingObject): boolean =>
	isEntityEdited(
		state,
		original,
		isNameEdited,
		isCompanyIdEdited,
		isCityEdited,
		isAddressEdited,
		isGeneralContractorEdited,
		isConstructionManagerEdited,
		isProjectManagerEdited,
		isStagesEdited,
		isImageEdited,
		areCustomFieldsEdited
	);

export const errorsFns = {
	name: (state: IEditableObjectState) => !state.name,
	companyId: (state: IEditableObjectState) => !state.companyId,
	city: (state: IEditableObjectState) => !state.city,
	generalContractor: (state: IEditableObjectState) => !state.generalContractor,
	constructionManager: (state: IEditableObjectState) => !state.constructionManager,
	projectManager: (state: IEditableObjectState) => !state.projectManager,
	stages: (state: IEditableObjectState) => !state.stages,
	image: (state: IEditableObjectState) => !state.image,
	customFields: (state: IEditableObjectState, key?: string) => {
		if (!key) {
			return false;
		}

		const value = state.customFields?.[key];
		if (Array.isArray(value)) {
			return !value.length;
		}

		return !value;
	}
};

export const hasImageError = (
	state: IEditableObjectState,
	settings: {[k: string]: IObjectFieldSetting}
) => settings.image?.isRequired && errorsFns.image(state);

export const convertToSave = (
	state: IEditableObjectState,
	original?: ISavingObject,
	onlyEdited?: boolean
): ISavingObject => {
	if (!onlyEdited) {
		return {
			name: state.name,
			companyId: state.companyId,
			city: state.city,
			address: state.address,
			generalContractor: state.generalContractor,
			constructionManager: state.constructionManager,
			projectManager: state.projectManager,
			image: state.image,
			customFields: state.customFields
		};
	}

	const object: ISavingObject = {};

	if (isNameEdited(state, original)) {
		object.name = state.name;
	}
	if (isCompanyIdEdited(state, original)) {
		object.companyId = state.companyId;
	}
	if (isCityEdited(state, original)) {
		object.city = state.city;
	}
	if (isAddressEdited(state, original)) {
		object.address = state.address;
	}
	if (isGeneralContractorEdited(state, original)) {
		object.generalContractor = state.generalContractor;
	}
	if (isConstructionManagerEdited(state, original)) {
		object.constructionManager = state.constructionManager;
	}
	if (isProjectManagerEdited(state, original)) {
		object.projectManager = state.projectManager;
	}
	if (isStagesEdited(state, original)) {
		object.stages = state.stages;
	}
	if (isImageEdited(state, original)) {
		object.newImage = state.image;
	} else if (!state.image && original?.image) {
		object.newImage = null;
	}
	if (areCustomFieldsEdited(state, original)) {
		object.customFields = state.customFields;
	}
	return object;
};
