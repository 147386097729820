import {queryClient} from '@src/api/QueryClient';
import {authorizedPersonsQueryKeys} from '@src/api/cache/authorizedPersons/keys';
import {bankAccountsQueryKeys} from '@src/api/cache/bankAccounts/keys';
import {categoriesQueryKeys} from '@src/api/cache/categories/keys';
import {categoriesSetsQueryKeys} from '@src/api/cache/categoriesSets/keys';
import {checkItemsQueryKeys} from '@src/api/cache/checkItems/keys';
import {checkListsQueryKeys} from '@src/api/cache/checkLists/keys';
import {legalsQueryKeys} from '@src/api/cache/legals/keys';
import {meterConsumptionsQueryKeys} from '@src/api/cache/meterConsumptions/keys';
import {metersQueryKeys} from '@src/api/cache/meters/keys';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {regulatoryStandardsQueryKeys} from '@src/api/cache/regulatoryStandards/keys';
import {responsibilityRulesQueryKeys} from '@src/api/cache/responsibilityRules/keys';
import {restDictionariesQueryKeys} from '@src/api/cache/restDictionaries/keys';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import {workingGroupsQueryKeys} from '@src/api/cache/workingGroups/keys';
import {spacesQueryKeys} from '../../../../../api/cache/spaces/keys';
import {structuresQueryKeys} from '@/entities/Structures/model/cache/keys';

export const invalidateDictionariesCache = async () => {
	const dictionaryQueryKeys = Object.values(restDictionariesQueryKeys).slice(1);
	for (const key of dictionaryQueryKeys) {
		await queryClient.invalidateQueries({queryKey: key()});
	}
	await queryClient.invalidateQueries({queryKey: objectsQueryKeys.list()});
	await queryClient.invalidateQueries({queryKey: regulatoryStandardsQueryKeys.list()});
	await queryClient.invalidateQueries({queryKey: categoriesQueryKeys.list()});
	await queryClient.invalidateQueries({queryKey: categoriesSetsQueryKeys.list()});
	await queryClient.invalidateQueries({queryKey: responsibilityRulesQueryKeys.list()});
	await queryClient.invalidateQueries({queryKey: workingGroupsQueryKeys.list()});
	await queryClient.invalidateQueries({queryKey: checkListsQueryKeys.list()});
	await queryClient.invalidateQueries({queryKey: checkItemsQueryKeys.list()});
	await queryClient.invalidateQueries({queryKey: spaceStatusesQueryKeys.list()});
	await queryClient.invalidateQueries({queryKey: tasksQueryKeys.lists()});
	await queryClient.invalidateQueries({queryKey: tasksQueryKeys.schedule()});
	await queryClient.invalidateQueries({queryKey: legalsQueryKeys.list()});
	await queryClient.invalidateQueries({queryKey: bankAccountsQueryKeys.list()});
	await queryClient.invalidateQueries({queryKey: authorizedPersonsQueryKeys.list()});
	await queryClient.invalidateQueries({queryKey: metersQueryKeys.lists()});
	await queryClient.invalidateQueries({queryKey: meterConsumptionsQueryKeys.lists()});
	await queryClient.invalidateQueries({queryKey: problemsQueryKeys.all()});
	await queryClient.invalidateQueries({queryKey: problemsQueryKeys.one()});
	await queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
	await queryClient.invalidateQueries({queryKey: spacesQueryKeys.listWithStats()});
	await queryClient.invalidateQueries({queryKey: structuresQueryKeys.one()});
	await queryClient.invalidateQueries({queryKey: structuresQueryKeys.problems()});
	await queryClient.invalidateQueries({queryKey: structuresQueryKeys.inspections()});
};
