import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IExportTemplatesFiltersState, IExportTemplatesPagesSettingsState} from './interfaces';

const initialState: IExportTemplatesPagesSettingsState = {
	filters: {},
	sort: {
		createdAt: false
	},
	pageSize: 20,
	offset: 0
};

export const exportTemplatesSlice = createSlice({
	name: 'exportTemplates',
	initialState,
	reducers: {
		changeFilters(state, action: PayloadAction<IExportTemplatesFiltersState>) {
			const filters = action.payload;
			state.filters = filters;
		},
		clearFilters(state) {
			state.filters = initialState.filters;
		},
		changePageSize(state, action: PayloadAction<{pageSize: number}>) {
			const {pageSize} = action.payload;
			state.pageSize = pageSize;
		},
		changeOffset(state, action: PayloadAction<number>) {
			const offset = action.payload;
			state.offset = offset;
		}
	}
});

export const {actions: exportTemplatesActions, reducer: exportTemplatesReducer} =
	exportTemplatesSlice;
