import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';

import {useCallback} from 'react';
import {ExportTemplatesPage} from './ExportTemplatesPage';
import {extractExportTemplatesPageSettings} from '@src/store/modules/settings/pages/exportTemlates/selectors';
import {IExportTemplatesFiltersState} from '@src/store/modules/settings/pages/exportTemlates/interfaces';
import {exportTemplatesActions} from '@src/store/modules/settings/pages/exportTemlates/slice';

export const ExportTemplatesPageWrap = () => {
	const dispatch = useAppDispatch();

	const {filters} = useAppSelector(extractExportTemplatesPageSettings);

	const {changeFilters, changeOffset, clearFilters} = exportTemplatesActions;

	const applyFilters = useCallback((value: IExportTemplatesFiltersState) => {
		dispatch(changeFilters(value));
		dispatch(changeOffset(0));
	}, []);

	const onClear = useCallback(() => {
		dispatch(clearFilters());
	}, []);

	return (
		<EntitiesFiltersProvider
			filters={filters}
			onApply={applyFilters}
			objectId="all"
			onClear={onClear}
		>
			<ExportTemplatesPage />
		</EntitiesFiltersProvider>
	);
};
