import {useMutation} from '@tanstack/react-query';
import {
	IAddMultipleOwnerAcptResponse,
	makeOwnerAcceptancesAddRequest
} from '@src/api/backend/ownerAcceptances/add';
import {ISavingOwnerAcceptance} from '@src/interfaces/saving/ISavingOwnerAcceptance';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {OwnerAcceptanceResolutionId} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptanceResolution';

export interface IAddMultipleOwnerAcceptancesParams {
	objectId: string;
	spaceIds: string[];
	links: IOwnerAcceptance['links'];
	savingData: ISavingOwnerAcceptance;
	setOwnerAcceptanceView: (view: 'acceptance' | 'problem') => void;
	onClose: () => void;
}
/**
 * Хук для добавления нескольких передач собственнику
 */

export const useAddMultipleOwnerAcceptances = () => {
	const ownerAcceptancesMutation = useMutation<
		IAddMultipleOwnerAcptResponse,
		Error,
		IAddMultipleOwnerAcceptancesParams
	>({
		mutationFn: async ({
			objectId,
			spaceIds,
			links,
			savingData
		}: IAddMultipleOwnerAcceptancesParams) =>
			makeOwnerAcceptancesAddRequest(objectId, spaceIds, links, savingData),
		onSuccess: (response, {setOwnerAcceptanceView, savingData, onClose}) => {
			if (
				response.ownerAcceptances.length &&
				savingData.resolution !== OwnerAcceptanceResolutionId.ACCEPTED
			) {
				setOwnerAcceptanceView('problem');
			} else if (savingData.resolution === OwnerAcceptanceResolutionId.ACCEPTED) {
				onClose();
			} else {
				console.error('Передачи собственнику не созданы!');
			}
		},
		onError: error => {
			console.error('Ошибка при создании передач собственнику:', error);
		}
	});

	return async ({
		objectId,
		spaceIds,
		links,
		savingData,
		setOwnerAcceptanceView,
		onClose
	}: IAddMultipleOwnerAcceptancesParams) =>
		ownerAcceptancesMutation.mutateAsync({
			objectId,
			spaceIds,
			links,
			savingData,
			setOwnerAcceptanceView,
			onClose
		});
};
