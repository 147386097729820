import {queryClient} from '@src/api/QueryClient';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import {ownerAcceptancesQueryKeys} from '@src/api/cache/ownerAcceptances/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {checksQueryKeys} from '../../../api/cache/checks/keys';
import {presaleChecksQueryKeys} from '@/entities/PresaleCheck';
import {structuresQueryKeys} from '@/entities/Structures/model/cache/keys';

export const invalidateLinkedProblemsQueries = async (
	objectId: string,
	links?: IProblem['links']
) => {
	if (links?.checkId) {
		await queryClient.invalidateQueries({
			queryKey: checksQueryKeys.checkProblems(objectId, links.checkId)
		});
	}
	if (links?.presaleCheckId) {
		await queryClient.invalidateQueries({
			queryKey: presaleChecksQueryKeys.presaleCheckProblems(objectId, links.presaleCheckId)
		});
	}
	if (links?.internalAcceptanceId) {
		await queryClient.invalidateQueries({
			queryKey: internalAcceptancesQueryKeys.internalAcceptanceProblems(
				objectId,
				links.internalAcceptanceId
			)
		});
	}
	if (links?.ownerAcceptanceId) {
		await queryClient.invalidateQueries({
			queryKey: ownerAcceptancesQueryKeys.acceptanceProblems(
				objectId,
				links.ownerAcceptanceId
			)
		});
	}
	if (links?.warrantyClaimId) {
		await queryClient.invalidateQueries({
			queryKey: warrantyClaimsQueryKeys.claimProblems(objectId, links.warrantyClaimId)
		});
	}
	if (links?.spaceId) {
		await queryClient.invalidateQueries({queryKey: [...spacesQueryKeys.problems(), objectId]});
		await queryClient.invalidateQueries({
			queryKey: [...spacesQueryKeys.listWithStats(), {objects: [objectId]}]
		});
	}
	if (links?.structureId) {
		await queryClient.invalidateQueries({
			queryKey: structuresQueryKeys.problems()
		});
	}
	if (!links) {
		await queryClient.invalidateQueries({queryKey: [...spacesQueryKeys.problems(), objectId]});
		await queryClient.invalidateQueries({queryKey: [...checksQueryKeys.problems(), objectId]});
		await queryClient.invalidateQueries({
			queryKey: [...presaleChecksQueryKeys.problems(), objectId]
		});
		await queryClient.invalidateQueries({
			queryKey: [...internalAcceptancesQueryKeys.problems(), objectId]
		});
		await queryClient.invalidateQueries({
			queryKey: [...ownerAcceptancesQueryKeys.problems(), objectId]
		});
		await queryClient.invalidateQueries({
			queryKey: [...warrantyClaimsQueryKeys.problems(), objectId]
		});
	}
	await queryClient.invalidateQueries({queryKey: structuresQueryKeys.problemsStatsAll()});
};
