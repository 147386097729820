import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {exportTemplatesQueryKeys, IExportTemplatesListQueryKey} from '../keys';
import {requestExportTemplates} from '@src/api/backend/exportTemplates/get';
import {requestExportTemplatesPaginate} from '@src/api/backend/exportTemplates/get-paginate';

export const useExportTemplatesQueryDefaults = () => {
	const queryClient = useQueryClient();
	queryClient.setQueryDefaults(exportTemplatesQueryKeys.list(), {
		queryFn: requestExportTemplates,
		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});

	queryClient.setQueryDefaults(exportTemplatesQueryKeys.listPaginate(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IExportTemplatesListQueryKey>) => {
			const [, , , filters, sort, offset, pageSize] = queryKey;
			return requestExportTemplatesPaginate(filters, sort, offset, pageSize);
		},

		staleTime: 1000 * 60 * 5, // 5 минут
		gcTime: 1000 * 60 * 60 * 24 // 1 День
	});
};
