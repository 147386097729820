import {
	IExportTemplatesFiltersState,
	IExportTemplatesSortState
} from '@src/store/modules/settings/pages/exportTemlates/interfaces';

export type IExportTemplatesListQueryKey = [] & {
	0: string;
	1: string;
	2: string;
	3: IExportTemplatesFiltersState;
	4: IExportTemplatesSortState;
	5: number;
	6: number;
};

export const exportTemplatesQueryKeys = {
	all: () => ['exports-templates'],
	one: () => ['exports-templates'],
	list: () => [...exportTemplatesQueryKeys.all(), 'list'],
	listPaginate: () => [...exportTemplatesQueryKeys.list(), 'paginate'],
	add: () => [...exportTemplatesQueryKeys.one(), 'add'],
	edit: () => [...exportTemplatesQueryKeys.one(), 'edit'],
	delete: () => [...exportTemplatesQueryKeys.one(), 'delete'],
	details: () => [...exportTemplatesQueryKeys.one(), 'detail'],
	detail: (templateId: string) => [...exportTemplatesQueryKeys.one(), 'detail', templateId]
};
