import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {ISavingOwnerAcceptance} from '@src/interfaces/saving/ISavingOwnerAcceptance';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {addTempFiles} from '@src/api/cache/utils/addTempFiles';

export interface IAddMultipleOwnerAcptResponse {
	ownerAcceptances: IOwnerAcceptance[];
	failedCreations: string[];
}

/**
 * Добавляет передачи собственнику
 *
 * @param objectId id объекта
 * @param links ссылки на другие сущности
 * @param fields данные передачи
 */
export const makeOwnerAcceptancesAddRequest = async (
	objectId: string,
	spaceIds: string[],
	links: IOwnerAcceptance['links'] | undefined,
	fields: ISavingOwnerAcceptance,
	localId?: string
) => {
	fields.newAttachments = await addTempFiles(fields.newAttachments);
	const response = await httpRequests.withToken.post<IAddMultipleOwnerAcptResponse>(
		'/owner-acceptances/add-list',
		{
			objectId,
			spaceIds,
			links,
			localId,
			...fields
		}
	);
	return response.data;
};
