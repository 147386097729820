import {createSelector} from 'reselect';
import {IState} from '@src/store/modules';
import {getInitialStateForPage} from './reducers/byPage';

/**
 * Возвращает настройки страницы для конкретного объекта
 */
export const extractSpacesPageSettings = createSelector(
	(state: IState) => state.settings.pages.spaces.byPage,
	(state: IState, objectId: string | undefined) => objectId || 'all',
	(rootData, objectId) => rootData[objectId] || getInitialStateForPage()
);

/**
 * Возвращает видимость чекбоксов для множественного выбора помещений
 */
export const extractSpacesSelectionVisibility = createSelector(
	(state: IState) => state.settings.pages.spaces.shared,
	shared => shared.selectionSpacesVisible
);

/**
 * Возвращает набор данных для обработки логики множественного выбора помещений
 */

export const extractSelectedSpacesForObjectIds = createSelector(
	[
		(state: IState) => state.settings.pages.spaces.byPage,
		(state: IState, parentObjectId: string) => parentObjectId,
		(state: IState, _parentObjectId: string, targetObjects: string[]) => targetObjects
	],
	(byPage, parentObjectId, targetObjects) => {
		const selectedSubObjects = byPage[parentObjectId]?.selectedSubObjects || {};

		if (!targetObjects || targetObjects.length === 0) {
			return {
				selectedSpaces: [],
				nonEmptySelectedCount: 0,
				selectedByObject: {},
				singleObjectId: undefined,
				typeCounts: 0,
				spaceCountsByType: {},
				selectedByType: {}
			};
		}

		let selectedSpaces: string[] = [];
		let nonEmptySelectedCount = 0;
		const selectedByObject: Record<string, string[]> = {};
		const typeSet = new Set<string>(); // Подсчёт уникальных типов помещений
		const spaceCountsByType: Record<string, number> = {}; // Количество помещений по типам
		const selectedByType: Record<string, string[]> = {}; // Группировка помещений по типам

		// Фильтруем только те ключи, где массив не пустой
		const nonEmptyKeys = Object.keys(selectedSubObjects).filter(
			key => selectedSubObjects[key]?.length > 0
		);
		const singleObjectId = nonEmptyKeys.length === 1 ? nonEmptyKeys[0] : null;

		targetObjects.forEach(objectId => {
			const selectedForObject = selectedSubObjects[objectId] || [];

			if (selectedForObject.length > 0) {
				nonEmptySelectedCount++;
			}

			const ids = selectedForObject.map(item => item.id);
			selectedSpaces = selectedSpaces.concat(ids);

			// Подсчёт по типам и группировка
			selectedForObject.forEach(item => {
				typeSet.add(item.type);

				// Подсчёт количества помещений по типам
				spaceCountsByType[item.type] = (spaceCountsByType[item.type] || 0) + 1;

				// Группировка выбранных помещений по типу
				if (!selectedByType[item.type]) {
					selectedByType[item.type] = [];
				}
				selectedByType[item.type].push(item.id);
			});

			selectedByObject[objectId] = ids;
		});

		return {
			selectedSpaces, // Общий список выбранных ID
			nonEmptySelectedCount, // Количество объектов с непустым массивом
			selectedByObject, // Объект с ключами objectId и массивами выбранных ID
			singleObjectId, // Единственный непустой объект
			typeCounts: typeSet.size, // Количество уникальных типов
			spaceCountsByType, // Количество помещений по типам
			selectedByType // Группировка выбранных помещений по типам
		};
	}
);
