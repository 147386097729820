import {useCallback, ReactNode} from 'react';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {ExportDialog} from '@src/components/ExportDialog';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractInspectionsPageSettings} from '@src/store/modules/settings/pages/inspections/selectors';
import useToggle from 'react-use/lib/useToggle';
import {useExportInspections} from '@src/core/hooks/mutations/inspections/useExportInspections';

const availableDestinations = [ExportTemplatesDestinationId.INSPECTIONS];

export function useInspectionsExport(objectId = 'all'): [ReactNode, () => void] {
	const {selectedRows} = useAppSelector(s => extractInspectionsPageSettings(s, objectId));
	const {filters, sort} = useAppSelector(s => extractInspectionsPageSettings(s, objectId));
	const [isOpen, toggleOpen] = useToggle(false);
	const {mutateAsync: exportInspections} = useExportInspections(objectId);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			await exportInspections({
				templateId: template.id,
				createDocument,
				email,
				objectId,
				filters,
				sort,
				selected: selectedRows
			});
		},
		[objectId, filters, sort, selectedRows, exportInspections, toggleOpen]
	);

	const dialog = (
		<ExportDialog
			objectId={objectId}
			pageKey="inspections"
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={toggleOpen}
		/>
	);

	return [dialog, toggleOpen];
}
