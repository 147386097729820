import {Suspense, memo, useCallback, useState, Dispatch, SetStateAction} from 'react';
import {
	convertToSave,
	isEdited,
	useEditableResponsibilityRuleState
} from '@src/core/hooks/states/useEditableResponsibilityRuleState';
import Text from '@src/components/editableFields/Text';
import {PlansSelect} from '../editableFields/PlansSelect';
import './EditableResponsibilityRule.less';
import {BooleanSelect} from '@src/components/editableFields/BooleanSelect';
import {Button} from '@tehzor/ui-components';
import {IExtendedSavingResponsibilityRule} from '@tehzor/tools/interfaces/responsibilityRules/IExtendedSavingResponsibilityRule';
import {ObjectsSelect} from '../editableFields/ObjectsSelect';
import {IEditableWorkingGroupState} from '@src/core/hooks/states/useEditableWorkingGroupState';
import {useAsync, useUpdateEffect} from 'react-use';
import CategoriesSet from '../editableFields/CategoriesSet';
import classNames from 'classnames';
import {IResponsibilityRulesPermissions} from '@src/core/hooks/permissions/useResponsibilityRulesPermissions';
import isOneObject from './utils/isOneObject';
import getLastChild from './utils/getLastChild';
import arrayToTree from 'array-to-tree';
import {WorkingGroupTypeId} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroupType';
import {useObjectsAsArrayByIds} from '@src/core/hooks/queries/objects/hooks';
import {usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';
import {StructureTreeSelect} from '../editableFields/StructureTreeSelect';
import {SpaceTreeSelect} from '../editableFields/SpaceTreeSelect/SpaceTreeSelect';
import {WorkAcceptanceFrontTypeId} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceFrontType';
import {useAddResponsibilityRules} from '@src/core/hooks/mutations/responsibilityRules/useAddResponsibilityRules';
import {useEditResponsibilityRules} from '@src/core/hooks/mutations/responsibilityRules/useEditResponsibilityRules';
import {useDeleteResponsibilityRules} from '@src/core/hooks/mutations/responsibilityRules/useDeleteResponsibilityRules';
import {useExtractCommonCategoriesSetsAsArray} from '@src/core/hooks/queries/categorySets/hook';
import {queryClient} from '../../api/QueryClient';
import {spacesQueryKeys} from '../../api/cache/spaces/keys';

interface IEditableResponsibilityRuleProps {
	rule: IExtendedSavingResponsibilityRule;
	groupState?: IEditableWorkingGroupState;
	permissions: IResponsibilityRulesPermissions;
	setHasDraft: Dispatch<SetStateAction<boolean>>;
}

export const EditableResponsibilityRule = memo(
	({rule, groupState, permissions, setHasDraft}: IEditableResponsibilityRuleProps) => {
		const [isBlocking, setIsBlocking] = useState(false);
		const [editingState, editingDispatch] = useEditableResponsibilityRuleState(rule);
		const objectsArray = useObjectsAsArrayByIds(editingState.objects) || [];
		const objectsTree = arrayToTree(objectsArray, {parentProperty: 'parentId', customID: 'id'});
		const {mutate: addResponsibilityRules} = useAddResponsibilityRules();
		const {mutate: editResponsibilityRules} = useEditResponsibilityRules();
		const {mutateAsync: deleteResponsibilityRules} = useDeleteResponsibilityRules();

		const objectId = getLastChild(objectsTree[0]).id;

		const {data: plans} = usePlansAsArray(editingState.objects ? objectId : undefined);

		const {data: commonSets} = useExtractCommonCategoriesSetsAsArray(editingState.objects);
		// сбрасываем поля при изменении объекта
		useUpdateEffect(() => {
			editingDispatch({type: 'update', field: 'plans', value: null});
			editingDispatch({type: 'update', field: 'categories', value: null});
			editingDispatch({type: 'update', field: 'structureIds', value: []});
		}, [editingState.objects]);

		useAsync(async () => {
			const isSingleEditableObject =
				editingState.objects &&
				(editingState.objects.length === 1 || isOneObject(objectsTree));

			if (isSingleEditableObject) {
				if (
					groupState?.type === WorkingGroupTypeId.ACCEPTORS ||
					groupState?.type === WorkingGroupTypeId.SUBMITTERS
				) {
					await queryClient.invalidateQueries({
						queryKey: [...spacesQueryKeys.listWithStats(), {objects: objectId}]
					});
				}
			}
		}, [editingState.objects]);

		const handleSave = () => {
			addResponsibilityRules({
				groupId: editingState.groupId,
				rule: convertToSave(editingState)
			});
			setHasDraft(false);
		};

		const handleEdit = () => {
			editResponsibilityRules({
				ruleId: editingState.id,
				groupId: editingState.groupId,
				rule: convertToSave(editingState, undefined, true)
			});
		};

		const handleDelete = async () => {
			if (!rule?.id || !editingState.groupId) return;

			if (!rule.isDraft) {
				await deleteResponsibilityRules({ruleId: editingState.id});
			}
		};

		const save = () => {
			setIsBlocking(true);
			if (rule?.isDraft) {
				handleSave();
			} else {
				handleEdit();
			}
			setIsBlocking(false);
		};

		const reset = useCallback(() => {
			editingDispatch({
				type: 'reset',
				entity: rule
			});
		}, [rule]);

		const isSaveVisible = () =>
			(rule?.isDraft && permissions.canAdd) ||
			(!rule?.isDraft && permissions.canEdit && isEdited(editingState, rule));

		return (
			<div
				className={classNames('editable-responsibility-rule', {
					'editable-responsibility-rule_draft': rule?.isDraft
				})}
			>
				<div className="editable-responsibility-rule__grid">
					<Text
						field="scope"
						className="editable-responsibility-rule__item"
						label="Область"
						value={editingState.scope}
						editingDispatch={editingDispatch}
						required={false}
						hasError={editingState.errors.scope}
					/>

					{groupState?.objects && (
						<ObjectsSelect
							field="objects"
							className="editable-responsibility-rule__item"
							label="Объекты"
							value={editingState.objects}
							availableObjects={groupState?.objects}
							editingDispatch={editingDispatch}
							hasError={editingState.errors.objects}
							disabled={!permissions.canEdit}
						/>
					)}

					{editingState.objects &&
					(editingState.objects.length === 1 ||
						isOneObject(objectsTree) ||
						(commonSets && commonSets.length > 0)) ? (
						<CategoriesSet
							className="editable-responsibility-rule__item"
							value={editingState.categories}
							objectIds={editingState.objects}
							editingDispatch={editingDispatch}
							stages={groupState?.stages}
							required={false}
							hasError={editingState.errors.categories}
							disabled={!permissions.canEdit}
							treeCheckOnlyChildren
						/>
					) : null}

					{editingState.objects &&
					(editingState.objects.length === 1 || isOneObject(objectsTree)) &&
					groupState?.type === WorkingGroupTypeId.PERFORMERS &&
					plans ? (
						<PlansSelect
							field="plans"
							className="editable-responsibility-rule__item"
							data={plans}
							value={editingState.plans}
							editingDispatch={editingDispatch}
							required={false}
							hasError={editingState.errors.plans}
							disabled={!permissions.canEdit}
						/>
					) : null}

					{editingState.objects &&
					(editingState.objects.length === 1 || isOneObject(objectsTree)) &&
					(groupState?.type === WorkingGroupTypeId.ACCEPTORS ||
						groupState?.type === WorkingGroupTypeId.SUBMITTERS) &&
					groupState?.workAcceptanceFrontType === WorkAcceptanceFrontTypeId.STRUCTURES ? (
						<StructureTreeSelect
							objectId={objectId}
							className="editable-responsibility-rule__item"
							field="structureIds"
							label="Структуры"
							value={editingState.structureIds || []}
							editingDispatch={editingDispatch}
							required={false}
							hasError={editingState.errors.structureIds}
						/>
					) : null}

					{editingState.objects &&
						(editingState.objects.length === 1 || isOneObject(objectsTree)) &&
						(groupState?.type === WorkingGroupTypeId.ACCEPTORS ||
							groupState?.type === WorkingGroupTypeId.SUBMITTERS) &&
						groupState?.workAcceptanceFrontType ===
							WorkAcceptanceFrontTypeId.SPACES && (
							<Suspense>
								<SpaceTreeSelect
									objectId={objectId}
									className="editable-responsibility-rule__item"
									field="spaceIds"
									label="Помещения"
									value={editingState.spaceIds || []}
									editingDispatch={editingDispatch}
									required={false}
									hasError={editingState.errors.spaceIds}
								/>
							</Suspense>
						)}

					<BooleanSelect
						field="autoSelect"
						className="editable-responsibility-rule__item"
						label="Автомат. выбор"
						value={editingState.autoSelect}
						editingDispatch={editingDispatch}
						required={false}
						hasError={editingState.errors.autoSelect}
						disabled={!permissions.canEdit}
					/>

					<div className="editable-responsibility-rule__buttons">
						{isSaveVisible() && (
							<Button
								label="Cохранить"
								disabled={isBlocking}
								type="accent-blue"
								onClick={save}
							/>
						)}

						{isEdited(editingState, rule) ? (
							<Button
								label="Отменить"
								type="cancel"
								onClick={reset}
							/>
						) : (
							permissions.canDelete && (
								<Button
									label="Удалить"
									type="cancel"
									onClick={handleDelete}
								/>
							)
						)}
					</div>
				</div>
			</div>
		);
	}
);

EditableResponsibilityRule.displayName = 'EditableResponsibilityRule';
