//* Функция для формирования сообщения в случае неправильного выбора помещений на шахматке
export const getSelectionMessage = (
	totalSelectedByCurrentType: number,
	nonEmptySelectedCount: number,
	typeCounts: number
): string | null => {
	if (totalSelectedByCurrentType === 0) {
		return 'Выберите помещения';
	}

	if (nonEmptySelectedCount !== 1 && typeCounts !== 1) {
		return 'Выберите помещения одного объекта и одинакового типа';
	}

	if (typeCounts !== 1) {
		return 'Необходимо выбрать помещения одного типа';
	}

	if (nonEmptySelectedCount !== 1) {
		return 'Необходимо выбрать помещения одного объекта';
	}

	return null;
};
