import {WarrantyClaimsExportDesktop} from './WarrantyClaimsExportDesktop';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';

interface WarrantyClaimsActionsDesktopProps {
	objectId?: string;
}

export const WarrantyClaimsActionsDesktop = ({objectId}: WarrantyClaimsActionsDesktopProps) => {
	const {canExport} = useWarrantyClaimsPermissions(objectId);

	if (!canExport) return null;

	return <WarrantyClaimsExportDesktop objectId={objectId} />;
};
