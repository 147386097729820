import {makeWarrantyClaimsExportRequest} from '@src/api/backend/warrantyClaims';
import {useMutation} from '@tanstack/react-query';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';
import {IDefferedExportResponse} from '@tehzor/tools/interfaces/export';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';

interface IExportWarrantyClaimsParams {
	templateId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	objectId?: string;
	filters?: Record<string, unknown>;
	sort?: Record<string, boolean>;
	selected?: string[];
}

export const useExportWarrantyClaims = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	return useMutation<IDefferedExportResponse, Error, IExportWarrantyClaimsParams>({
		mutationKey: warrantyClaimsQueryKeys.export(),
		mutationFn: ({sort, email, objectId, selected, templateId, filters, createDocument}) =>
			makeWarrantyClaimsExportRequest(
				templateId,
				createDocument,
				email,
				objectId,
				filters,
				sort,
				selected
			),
		onSuccess: (response, params) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!params.email}));
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
