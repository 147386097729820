import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@src/api/QueryClient';
import {makeSpaceEditRequest} from '@src/api/backend/space';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {ISavingSpace} from '@src/interfaces/saving/ISavingSpace';
import IError from '@tehzor/tools/interfaces/IError';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useEditSpace = () => {
	const {t} = useTranslation();

	return useMutation<ISpace, IError, {objectId: string; spaceId: string; fields: ISavingSpace}>({
		mutationKey: spacesQueryKeys.edit(),
		mutationFn: ({
			objectId,
			spaceId,
			fields
		}: {
			objectId: string;
			spaceId: string;
			fields: ISavingSpace;
		}) => makeSpaceEditRequest(objectId, spaceId, fields),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.listWithStats()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.details()});
			addSuccessToast({title: t('toast.editSpace.success.title')});
		},
		onError: () => addErrorToast({title: t('toast.editSpace.error.title')})
	});
};
