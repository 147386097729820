import * as types from './constants';
import {ISpacesFiltersState, ISpacesSortState} from './reducers/byPage';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IChangeFloorSortPayload} from '@src/store/interfaces/IChangeFloorSortPayload';
import {IChangeSchemaPageSizePayload} from '@src/store/interfaces/IChangeSchemaPageSizePayload';
import {IChangeSchemaOffsetPayload} from '@src/store/interfaces/IChangeSchemaOffsetPayload';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';

export interface IChangeSpacesDisplayModePayload {
	objectId: string;
	displayMode: SpacesDisplayMode;
}

/**
 * Изменяет способ отображения страницы списка помещений
 *
 * @param objectId id объекта
 * @param displayMode способ отображения
 */
export const changeDisplayMode = (objectId: string, displayMode: SpacesDisplayMode) => ({
	type: types.CHANGE_DISPLAY_MODE,
	payload: {
		objectId,
		displayMode
	} as IChangeSpacesDisplayModePayload
});

/**
 * Изменяет вариант отображения шахматке на странице списка помещений
 *
 * @param objectId id объекта
 * @param schemaView вариант отображения шахматки
 */
export const changeSchemaView = (objectId: string, schemaView: SpacesSchemaVariants) => ({
	type: types.CHANGE_SCHEMA_VIEW,
	payload: {objectId, schemaView}
});

/**
 * Изменяет процесс, для которого отображаются данные на странице списка помещений
 *
 * @param objectId id объекта
 * @param processId процесс
 */
export const changeProcess = (objectId: string, processId: ProcessIds | 'all') => ({
	type: types.CHANGE_PROCESS,
	payload: {objectId, processId}
});

/**
 * Изменяет стадию, для которой отображаются данные на странице списка помещений
 *
 * @param objectId id объекта
 * @param stage стадия
 */
export const changeStage = (objectId: string, stage: ObjectStageIds | 'all') => ({
	type: types.CHANGE_STAGE,
	payload: {objectId, stage}
});

export const changeType = (objectId: string, type: string) => ({
	type: types.CHANGE_TYPE,
	payload: {objectId, type}
});

/**
 * Изменяет фильтр
 *
 * @param objectId id объекта
 * @param filters свойства фильтра
 */
export const changeFilters = (objectId: string, filters: ISpacesFiltersState) => ({
	type: types.CHANGE_FILTERS,
	payload: {
		objectId,
		filters
	} as IChangeFiltersPayload<ISpacesFiltersState>
});

/**
 * Очищает фильтр
 *
 * @param objectId id объекта
 */
export const clearFilters = (objectId: string) => ({
	type: types.CLEAR_FILTERS,
	payload: {objectId} as IClearFiltersPayload
});

/**
 * Изменяет сортировку
 *
 * @param objectId id объекта
 * @param sort параметры сортировки
 */
export const changeSort = (objectId: string, sort: ISpacesSortState) => ({
	type: types.CHANGE_SORT,
	payload: {
		objectId,
		sort
	} as IChangeSortPayload<ISpacesSortState>
});

/**
 * Изменяет количество элементов на странице
 *
 * @param objectId id объекта
 * @param pageSize количество элементов на странице
 */
export const changePageSize = (objectId: string, pageSize: number) => ({
	type: types.CHANGE_PAGE_SIZE,
	payload: {
		objectId,
		pageSize
	} as IChangePageSizePayload
});

/**
 * Изменяет количество элементов на странице шахматки
 *
 * @param objectId id объекта
 * @param schemaPageSize количество элементов на странице шахматки
 */
export const changeSchemaPageSize = (objectId: string, schemaPageSize: number) => ({
	type: types.CHANGE_SCHEMA_PAGE_SIZE,
	payload: {
		objectId,
		schemaPageSize
	} as IChangeSchemaPageSizePayload
});

/**
 * Изменяет смещение элементов на странице шахматки
 *
 * @param objectId id объекта
 * @param schemaOffset смещение элементов на странице шахматки
 */
export const changeSchemaOffset = (objectId: string, schemaOffset: number) => ({
	type: types.CHANGE_SCHEMA_OFFSET,
	payload: {
		objectId,
		schemaOffset
	} as IChangeSchemaOffsetPayload
});

/**
 * Включает/выключает отображение альтернативных имён вместо обычных
 *
 * @param visibility видимость
 */
export const toggleAltNamesVisibility = (visibility?: boolean) => ({
	type: types.TOGGLE_ALT_NAMES_VISIBILITY,
	payload: visibility
});

/**
 * Включает/выключает отображение альтернативных имён вместо обычных
 *
 * @param objectId id объекта
 */
export const changeFloorSort = (objectId: string) => ({
	type: types.CHANGE_FLOOR_SORT,
	payload: {
		objectId
	} as IChangeFloorSortPayload
});

/**
 * Изменияет состояние loader во время пересчета шахматки
 *
 * @param pending видимость
 */
export const togglePending = (pending?: boolean) => ({
	type: types.TOGGLE_PENDING,
	payload: pending
});

/**
 * Изменяет смещение списка
 *
 * @param objectId id объекта
 * @param offset смещение
 */
export const changeListOffset = (objectId: string, listOffset: number) => ({
	type: types.CHANGE_LIST_OFFSET,
	payload: {
		objectId,
		listOffset
	}
});

/**
 * Присваивает значение стейту с массивом id выбранных помещений
 *
 * @param objectId id объекта
 * @param value массив id выбранных строк
 * @param valueForSubObjects объект с дочерними объектами, где ключи — id объектов, а значения — массивы с id и type
 */
export const selectSpaces = (
	objectId: string,
	value: string[],
	valueForSubObjects: Record<string, Array<Record<string, string>>>
) => ({
	type: types.SELECT_SPACES_BY_VALUE,
	payload: {
		objectId,
		value,
		valueForSubObjects
	}
});

/**
 * Добавляет/удаляет помещения по-одному
 *
 * @param objectId id объекта
 * @param value массив id выбранных строк
 */
export const selectSpaceInSchema = (
	objectId: string,
	subObjectId: string,
	spaceId: string,
	spaceType: string
) => ({
	type: types.SELECT_SPACES_BY_ONE,
	payload: {
		objectId,
		subObjectId,
		spaceId,
		spaceType
	}
});

/**
 * Выбирает/убирает все помещения у дочернего объекта
 *
 * @param objectId id объекта
 * @param spacesObjectId id дочернего объекта
 * @param spaces массив id помещений
 */
export const selectSpacesMultiple = (
	objectId: string,
	subObjectId: string,
	spaces: string[],
	spacesType: string
) => ({
	type: types.SELECT_SPACES_MULTIPLE,
	payload: {objectId, subObjectId, spaces, spacesType}
});

/**
 * Выбирает/убирает все помещения у объекта (всех типов и все дочерние)
 *
 * @param objectId id объекта
 * @param subObjectsWithSpaces данные по всем помещениям
 */
export const selectAllSpaces = (
	objectId: string,
	subObjectsWithSpaces: Record<string, IListSpace[]>
) => ({
	type: types.SELECT_SPACES_ALL,
	payload: {objectId, subObjectsWithSpaces}
});

/**
 * Выбирает/добавляет/убирает все помещения у видимых объектов определенного типа
 *
 * @param objectId id объекта
 * @param subObjectsWithSpaces данные по всем помещениям
 */
export const selectAllVisibleSpaces = (
	objectId: string,
	subObjectsWithSpaces: Record<string, IConvertedSpace[]>
) => ({
	type: types.SELECT_SPACES_ALL_VISIBLE,
	payload: {objectId, subObjectsWithSpaces}
});

/**
 * Сбрасывает выбор всех помещений для объекта и дочених объектов
 *
 * @param objectId id объекта для страницы
 */
export const deselectAllSpaces = (objectId: string) => ({
	type: types.DESELECT_SPACES_ALL,
	payload: {objectId}
});

/**
 * Изменяет текущий отображаемый объект в мобильной шахматке
 *
 * @param objectId id объекта для страницы
 * @param id id отображаемого объекта
 */
export const changeCurrentObject = (objectId: string, id: string) => ({
	type: types.CHANGE_CURRENT_OBJECT,
	payload: {objectId, id}
});

/**
 * Изменяет видимость чекбоксов для множественного выбора помещений
 *
 * @param visibility видимость чекбоксов
 */
export const toggleSelectionSpacesVisibility = (visibility: boolean) => ({
	type: types.TOGGLE_SELECTION_SPACES_VISIBILITY,
	payload: {visibility}
});
