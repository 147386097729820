import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';

export const convertTableValueToSpaces = (
	value: string[],
	spaces: Record<string, IListSpace>
): Record<string, Array<{id: string; type: string}>> => {
	const result: Record<string, Array<{id: string; type: string}>> = {};
	value.forEach(spaceId => {
		const space = spaces[spaceId];
		if (!space) {
			return;
		}

		const {objectId, id, type} = space;
		if (!result[objectId]) {
			result[objectId] = [];
		}

		result[objectId].push({id, type});
	});

	return result;
};
