import {
	IAddInspectionParams,
	IEditInspectionParams
} from '@src/api/cache/inspections/defaults/useInspectionsMutationDefaults';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {useEditInspectionMutation} from '@src/api/cache/inspections/mutations';
import {useQueryClient} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {ILinkedInspection} from '@tehzor/tools/interfaces/inspections/ILinkedInspection';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {useCallback} from 'react';
import useAppDispatch from '../../useAppDispatch';
import {ISavingInspectionQuery} from './useAddInspection';
import {toListInspection} from './utils/convertToLocalSave';
import {addWarningToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {EDIT_SUCCESS} from '@src/store/modules/entities/inspection/constants';

export const useEditInspection = (object?: IObject) => {
	const {t} = useTranslation();
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();
	const mutation = useEditInspectionMutation();

	const editInspection = useCallback(
		(params: IEditInspectionParams) => {
			const {inspectionId, fields, objectId} = params;
			const payloadToSave = queryClient.getQueryData(
				inspectionsQueryKeys.savingData(inspectionId)
			);
			if (payloadToSave) {
				const newPayload = queryClient.setQueryData<ISavingInspectionQuery>(
					inspectionsQueryKeys.savingData(inspectionId),
					data => {
						if (data) {
							if (fields.attachments) {
								data.fields.newAttachments = data.fields.newAttachments?.filter(
									att => fields.attachments?.some(item => item.id === att.key)
								);
							}
							if (fields.newAttachments) {
								if (
									!data.fields.newAttachments ||
									!data.fields.newAttachments.length
								) {
									data.fields.newAttachments = [];
								}
								data.fields.newAttachments = [
									...data.fields.newAttachments,
									...fields.newAttachments
								];
							}

							return {
								...data,
								fields: {
									...data.fields,
									...fields,
									newAttachments: data.fields.newAttachments
								}
							};
						}
						return data;
					}
				);
				const cachedInspection = queryClient.setQueryData<IInspection>(
					[...inspectionsQueryKeys.detail(inspectionId), objectId],
					(data: IInspection) => {
						if (data && object) {
							return {
								...data,
								...toListInspection(
									object,
									data.links,
									data.stage,
									data.processId,
									fields,
									inspectionId
								)
							};
						}
						return data;
					}
				);
				void queryClient.refetchQueries({
					queryKey: inspectionsQueryKeys.localList()
				});
				const mutationCache = queryClient.getMutationCache();
				const currentMutation = mutationCache.find<
					IInspection,
					IError,
					IAddInspectionParams,
					unknown
				>({
					predicate: mut => {
						const key = (mut.state.variables as IAddInspectionParams).key;
						return inspectionId === key;
					}
				});

				if (currentMutation && newPayload && currentMutation.state.variables) {
					currentMutation.state.isPaused = true;
					currentMutation.state.status = 'pending';
					currentMutation.state.failureCount = 0;
					currentMutation.state.error = null;
					currentMutation.state.failureReason = null;
					currentMutation.state.variables = {
						...currentMutation.state.variables,
						fields: newPayload.fields
					};
					void currentMutation.execute(currentMutation.state.variables);
				}
				dispatch({type: EDIT_SUCCESS, payload: cachedInspection as ILinkedInspection});
				addWarningToast({title: t('toast.editProblemLocal.warning.title')});
			}
			// Секция изменения нормального нарушения
			if (!payloadToSave) {
				mutation.mutate({
					...params
				});
			}
		},
		[object]
	);
	return [editInspection];
};
