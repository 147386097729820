import {memo, useCallback, useRef, useState} from 'react';
import styles from './AddingPresaleCheckDialog.module.less';
import {ModalDialog} from '@tehzor/ui-components';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useEntityPresaleCheckType} from '../../hooks/useEntityPresaleCheckType';
import {useAvailablePresaleCheckTypes} from '../../hooks/useAvailablePresaleCheckTypes';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import EntitySelectContainer from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/EntitySelectContainer';
import {usePresaleCheckMaps} from '../../hooks/usePresaleCheckMaps';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {useTranslation} from 'react-i18next';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {useSpace} from '@src/core/hooks/queries/space';
import {
	useEntitySelectContainerSubTitle,
	useEntitySelectContainerSubSubTitle
} from '@src/core/hooks/getEntitySelectContainerSubTitle';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {IPresaleCheckAddingEntityType} from '../../model/slice/presaleCheckAdding/interfaces';
import {savePresaleCheck} from '../../utils/savePresaleCheck';
import {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import {useLocalPresaleChecks, presaleChecksQueryKeys} from '@/entities/PresaleCheck';
import {useAddPresaleCheck} from '@/features/PresaleCheck';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractFastAdding} from '@src/store/modules/settings/modalDialog/selectors';
import {useQueryClient} from '@tanstack/react-query';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {modalDialogActions} from '@src/store/modules/settings/modalDialog/slice';
import {ModalDialogButtons} from '@src/components/ModalDialogButtons';

interface IAddingPresaleCheckDialogProps {
	objectId: string;
	presaleCheckId?: string;
	links?: IPresaleCheck['links'];
	types?: IPresaleCheckAddingEntityType[];
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	isOpen: boolean;
	problemToCopyId?: string;
	createdBy?: string;
	onClose: () => void;
	onSuccess?: () => void | Promise<void>;
	title: string;
}

const addingPresaleCheckDialogClassNames = {
	root: styles.addingPresaleCheckDialog,
	header: styles.dialogHeader,
	body: styles.dialogBody,
	title: styles.dialogTitle
};

const AddingPresaleCheckDialogComponent = (props: IAddingPresaleCheckDialogProps) => {
	const {
		objectId,
		presaleCheckId,
		links,
		types,
		isOpen,
		problemToCopyId,
		createdBy,
		onSuccess,
		onClose,
		defaultProblemData,
		defaultInspectionData,
		title
	} = props;
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {resetFastAddingMode} = modalDialogActions;
	const online = useAppSelector(s => s.offlineMode.networkStatus);

	const {data: object} = useObject(objectId);
	const {data: space} = useSpace(links?.spaceId, objectId);

	const [selectedPresaleCheckId, setSelectedPresaleCheckId] = useState<string | undefined>(
		presaleCheckId
	);
	const queryClient = useQueryClient();

	const addingEntityRef = useRef<IAddingProblemRefProps | IAddingInspectionRefProps>(null);
	const {data: localPresaleChecks} = useLocalPresaleChecks();
	const localPresaleChecksIds = localPresaleChecks?.map(presaleCheck => presaleCheck.id) || [];

	const availableTypes = useAvailablePresaleCheckTypes(objectId, types, {createdBy});
	const type = useEntityPresaleCheckType(objectId, availableTypes);

	const [saving, setSaving] = useState(false);
	const success = useRef<boolean>(false);

	const stage = ObjectStageIds.ACCEPTANCE;
	const processId = ProcessIds.PRESALE_CONTROL;
	const stages = useTranslatedObjectStagesArray();
	const getSubTitle = useEntitySelectContainerSubTitle();
	const getSubSubTitle = useEntitySelectContainerSubSubTitle();
	const [contentMap, entitiesSelectMapProps] = usePresaleCheckMaps({
		objectId,
		presaleCheckId,
		stage,
		processId,
		links,
		availableTypes,
		type,
		addingEntityRef,
		problemToCopyId,
		saving,
		setSaving,
		selectedPresaleCheckId,
		setSelectedPresaleCheckId,
		defaultProblemData,
		defaultInspectionData,
		onClose
	});
	const addPresaleCheck = useAddPresaleCheck(object);
	const isFastAddingActive = useAppSelector(extractFastAdding);

	const handleSave = useCallback(async () => {
		const addNewPresaleCheck = () =>
			addPresaleCheck({
				objectId,
				links
			});

		const updateLatest = async () => {
			if (
				online &&
				selectedPresaleCheckId &&
				!localPresaleChecksIds.includes(selectedPresaleCheckId)
			) {
				await queryClient.invalidateQueries({
					queryKey: [...presaleChecksQueryKeys.detail(selectedPresaleCheckId), objectId]
				});
			}
		};
		await savePresaleCheck({
			addingEntityRef,
			selectedPresaleCheckId,
			localPresaleChecksIds,
			updateLatest,
			online,
			addNewPresaleCheck,
			setSaving,
			success,
			onClose,
			isFastAddingActive
		});
		if (success.current && onSuccess) {
			success.current = false;
			void onSuccess();
		}
	}, [
		addPresaleCheck,
		isFastAddingActive,
		links,
		localPresaleChecksIds,
		objectId,
		onClose,
		onSuccess,
		online,
		queryClient,
		selectedPresaleCheckId
	]);

	const handleCancel = useCallback(() => {
		if (addingEntityRef.current) {
			addingEntityRef.current.cancel();
		} else {
			onClose();
		}
		dispatch(resetFastAddingMode());
	}, [dispatch, onClose, resetFastAddingMode]);

	return (
		<ModalDialog
			dataTestId="AddingPresaleCheckDialog"
			className={addingPresaleCheckDialogClassNames}
			open={isOpen}
			title={title}
			footer={
				<ModalDialogButtons
					handleSave={handleSave}
					saving={saving}
					handleCancel={handleCancel}
					isProblemType={type === 'problem'}
				/>
			}
			fullScreenOnTablet
			onRequestClose={handleCancel}
		>
			{stages && (
				<EntitySelectContainer
					contentMap={contentMap}
					entitiesSelectMapProps={entitiesSelectMapProps}
					stagesSelectDialogTitle={t(
						'globalAddingEntityDialog.entitySelectContainer.processSelectDialogTitle'
					)}
					stageTitle={t(
						'globalAddingEntityDialog.entitySelectContainer.processSelectDialogStageTitle'
					)}
					stagesSelectDialogSaveLabel={t(
						'addingCheckDialog.stagesSelectDialog.saveLabel'
					)}
					stagesSelectDialogCancelLabel={t(
						'addingCheckDialog.stagesSelectDialog.cancelLabel'
					)}
					stages={stages}
					selectedStage={stage}
					selectedProcess={processId}
					getEntitySelectContainerSubTitle={getSubTitle}
					getEntitySelectContainerSubSubTitle={getSubSubTitle}
					objectName={object?.name}
					spaceTypeId={space?.type}
					spaceName={space?.name}
				/>
			)}
		</ModalDialog>
	);
};

export const AddingPresaleCheckDialog = memo(AddingPresaleCheckDialogComponent);
