import {Dispatch, useCallback} from 'react';
import * as React from 'react';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {
	EditableFieldLabel,
	Select2,
	SelectPopup,
	SelectOption,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import {useMainCompaniesAsArray, useCompaniesById} from '@src/core/hooks/queries/companies/hooks';

interface ICompanySelectProps<S, E> {
	field: keyof S;
	value?: string;
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	errorMessage?: string;
	isUUID?: boolean;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
}

const arrowIcon = <i className="tz-simple-arrow-20" />;

const CompanySelect = <S extends {companyId: string}, E>({
	className,
	style,
	field,
	label = 'Компания',
	errorMessage = 'Выберите компанию',
	value,
	isUUID = false,
	editingDispatch,
	required,
	disabled,
	hasError
}: ICompanySelectProps<S, E>) => {
	const {data: companyMap} = useCompaniesById();
	const {data: mainAndSubCompaniesArray} = useMainCompaniesAsArray();

	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field});
			}
		},
		[field, required]
	);

	if (!mainAndSubCompaniesArray || !companyMap) {
		return null;
	}

	const title = isUUID
		? Object.values(companyMap).find(el => el?.uuid === value)?.name
		: value && companyMap[value]?.name;

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>
			<SelectPopup
				noHeader
				trigger={
					<TextFieldWithForwardedRef
						elementType="div"
						value={title}
						icon={arrowIcon}
						error={required && hasError ? errorMessage : undefined}
						disabled={disabled}
					/>
				}
			>
				<Select2
					value={value}
					onChange={handleChange}
				>
					{mainAndSubCompaniesArray?.map(company => {
						const uuid = companyMap[company.id]?.uuid;
						const itemKey = isUUID && uuid ? uuid : company.id;

						return (
							<SelectOption
								key={itemKey}
								itemKey={itemKey}
								content={company.name}
								inputType="radio"
							/>
						);
					})}
				</Select2>
			</SelectPopup>
		</div>
	);
};

export default CompanySelect;
