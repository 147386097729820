import {useCallback, useEffect, useState} from 'react';
import * as React from 'react';
import {
	convertToLocalSave,
	convertToSave as convertFilesToSave,
	isEdited as isFilesExist,
	someFilesHaveError,
	useUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {hasErrors} from '@tehzor/tools/core/states/editableEntityState';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';
import {ISavingOwnerAcceptance} from '@src/interfaces/saving/ISavingOwnerAcceptance';
import EditableOwnerAcceptance from '../EditableOwnerAcceptance';
import {
	useEditableOwnerAcceptanceState,
	convertToSave,
	hasAttachmentsError,
	isEdited,
	errorsFns
} from '@src/core/hooks/states/useEditableOwnerAcceptanceState';
import {useNavigationPrevent} from '@src/core/hooks/useNavigationPrevent';
import {useIsFetching} from '@tanstack/react-query';

/**
 * Логика редактирования и сохранения передачи собственнику (нового или существующего)
 */
export const useEditableOwnerAcceptance = (
	spaceId: string | undefined,
	acceptance: IOwnerAcceptance | undefined,
	saving: boolean,
	spaceIds: string[] | undefined,
	isMultiple: boolean | undefined
): [
	React.ReactNode,
	(useLocalFiles?: boolean) => Promise<ISavingOwnerAcceptance | undefined>,
	() => void,
	boolean
] => {
	// TODO Определение типа настроек
	// const fieldsSettings = useFieldsSettings(objectId, 'buildingProblem');
	const fieldsSettings = {};

	const [editingState, editingDispatch] = useEditableOwnerAcceptanceState(acceptance);
	const [uploadingFilesState, uploadingFilesDispatch, waitUploading] = useUploadingFilesState();
	const [isBlocking, setIsBlocking] = useState(false);

	useNavigationPrevent(isBlocking);

	useEffect(() => {
		setIsBlocking(
			isEdited(editingState, acceptance) || isFilesExist(uploadingFilesState.value)
		);
	}, [editingState, uploadingFilesState.value, acceptance]);

	const getSavingData = useCallback(
		async (useLocalFiles?: boolean) => {
			const files = await waitUploading();
			// Проверка наличия ошибок в состояниях
			if (
				hasErrors(editingState, errorsFns, fieldsSettings) ||
				(hasAttachmentsError(editingState, fieldsSettings) && !isFilesExist(files))
			) {
				editingDispatch({type: 'update-errors'});
				uploadingFilesDispatch({type: 'update-error'});
				return undefined;
			}
			// Проверка, были ли отредактированы поля
			if (
				!isEdited(editingState, acceptance) &&
				(!isFilesExist(files) || someFilesHaveError(files))
			) {
				return undefined;
			}
			const savingData = convertToSave(editingState, acceptance, true);
			savingData.newAttachments = useLocalFiles
				? convertToLocalSave(files)
				: convertFilesToSave(files);
			return savingData;
		},
		[editingState, acceptance, fieldsSettings]
	);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: acceptance
		});
		uploadingFilesDispatch({type: 'reset'});
	}, [acceptance]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [acceptance]);

	// TO-DO: Избавиться от этого безобразия, после переработки форм
	const isAllLoading = !!useIsFetching({});

	const fields = (
		<EditableOwnerAcceptance
			spaceId={spaceId}
			spaceIds={spaceIds}
			editingState={editingState}
			editingDispatch={editingDispatch}
			uploadingFilesState={uploadingFilesState}
			uploadingFilesDispatch={uploadingFilesDispatch}
			saving={saving || isAllLoading}
			isMultiple={isMultiple}
		/>
	);

	return [fields, getSavingData, reset, isBlocking];
};
