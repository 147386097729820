import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Boards, Pagination, PaginationAndSize, Schema} from '@tehzor/ui-components';
import {Tabs} from '@/widgets/StructuresSchema/ui/Tabs/Tabs';

import {ObjectBoard} from '@/widgets/StructuresSchema/ui/ObjectBoard/ObjectBoard';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';
import {useTargetObjectIdsHaveStructures} from '@src/core/hooks/queries/objects/hooks';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {structuresActions} from '@/entities/Structures';
import {getFilteredByObjectsTargetObjectIds} from '../../model/utils/getFilteredByObjectsTargetObjectIds';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';

interface ISchemaProps {
	objectId: string;
}

const pageSizes = [5, 10, 20];

export const StructuresSchemaDesktop = memo(({objectId}: ISchemaProps) => {
	const {filters, pageSize, offset, schemaStructureTypeId} = useAppSelector(s =>
		extractStructuresSchemaPageSettings(s, objectId)
	);

	const {data: targetObjects} = useTargetObjectIdsHaveStructures({
		objectId,
		filter: schemaStructureTypeId ? {type: schemaStructureTypeId} : undefined
	});

	const [objectsIds, setObjectIds] = useState(targetObjects);

	const dispatch = useAppDispatch();

	const schemaPageSize = pageSize ?? 5;
	const schemaOffset = offset ?? 0;
	const pagesCount = Math.ceil(objectsIds.length / schemaPageSize);
	const currentPage = Math.floor(schemaOffset / schemaPageSize);
	const isSingleSchema = objectsIds.length === 1;
	const {changeOffset, changePageSize} = structuresActions;

	const filteredObjects = useMemo(
		() => getFilteredByObjectsTargetObjectIds(targetObjects, filters),
		[targetObjects, filters]
	);
	useEffect(() => {
		if (filteredObjects) {
			setObjectIds(filteredObjects);
		} else {
			setObjectIds(targetObjects);
		}
	}, [filteredObjects, targetObjects]);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const newOffset = selected * pageSize;
			if (schemaOffset !== newOffset) {
				dispatch(changeOffset({objectId, offset: newOffset}));
			}
		},
		[objectId, pageSize, dispatch, changeOffset, schemaOffset]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize({objectId, pageSize: value}));
			const newOffset = Math.floor((schemaOffset ?? 0) / value);
			dispatch(changeOffset({objectId, offset: newOffset}));
		},
		[dispatch, objectId, schemaOffset, changePageSize, changeOffset]
	);

	const slicedObjects = objectsIds.slice(schemaOffset, schemaOffset + schemaPageSize);

	return (
		<>
			<Schema>
				<Tabs
					objectId={objectId}
					isSingleSchema={isSingleSchema}
				/>
				<Boards>
					{slicedObjects.map(slicedObjectId => (
						<ObjectBoard
							objectId={slicedObjectId}
							isMultipleObjects={slicedObjects.length > 1}
						/>
					))}
				</Boards>
			</Schema>
			{!isSingleSchema && (
				<PaginationAndSize
					pagination={
						<Pagination
							pageCount={pagesCount}
							forcePage={currentPage}
							pageRangeDisplayed={3}
							marginPagesDisplayed={1}
							onPageChange={handlePageChange}
						/>
					}
					pageSize={
						<TranslatedPaginationPageSize
							pageSize={pageSize}
							pageSizeOptions={pageSizes}
							onPageSizeChange={handlePageSizeChange}
						/>
					}
				/>
			)}
		</>
	);
});
