import {useCallback} from 'react';
import {MobilePagination, Plate} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import Table from './Table';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useMobileColumns} from '@src/pages/SpacesPage/hooks/useMobileColumns';
import {changeListOffset} from '@src/store/modules/settings/pages/spaces/actions';
import {useSpacesList, useSpacesUnlimitedList} from '@src/core/hooks/queries/spaces/hooks';
import {useTargetObjects} from '@src/core/hooks/queries/objects/hooks';

interface IMobileTableProps {
	objectId: string;
}

const MobileTable = ({objectId}: IMobileTableProps) => {
	const {pageSize, listOffset} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const {data: targetObjects} = useTargetObjects(objectId);
	const {total} = useSpacesList(objectId, {objects: targetObjects});
	const dispatch = useAppDispatch();

	//! Выборка без лимита (pageSize) синхронизации помещений с шахматкой
	const {byId: allSpacesByTargetObjects} = useSpacesUnlimitedList(objectId, {
		objects: targetObjects
	});

	const handlePageChange = useCallback(
		(value: number) => {
			dispatch(changeListOffset(objectId, value * pageSize));
		},
		[objectId, pageSize]
	);

	const pageCount = Math.ceil(total / pageSize);
	const currentPage = Math.floor(listOffset / pageSize);

	const columns = useMobileColumns();

	return (
		<Plate
			withoutPadding
			className="spaces-page__plate"
		>
			<MobilePagination
				className="spaces-page__m-pagination"
				page={currentPage}
				pageCount={pageCount}
				onPageChange={handlePageChange}
			/>
			<Table
				objectId={objectId}
				columns={columns}
				hideHead
				noRowBorder
				allSpacesById={allSpacesByTargetObjects || {}}
			/>
		</Plate>
	);
};

export default MobileTable;
