import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStructuresSchemaTypes} from '@src/core/hooks/queries/structureTypes/hooks';
import {memo, useCallback, useMemo} from 'react';
import {SchemaTabs, TabButton} from '@tehzor/ui-components';
import {extractStructuresSchemaPageSettings} from '@/entities/Structures/model/slice/selectors';
import {structuresActions} from '@/entities/Structures';
import {useActiveSchemaStructuresType} from '@/widgets/StructuresSchema/model/hooks/useActiveSchemaStructuresType';
import {Skeleton} from './Skeleton/Skeleton';
import {useTargetObjectIdsHaveStructures} from '@src/core/hooks/queries/objects/hooks';
import {getFilteredByObjectsTargetObjectIds} from '../../model/utils/getFilteredByObjectsTargetObjectIds';

interface ITabsProps {
	objectId: string;
	isSingleSchema: boolean;
}

export const Tabs = memo(({objectId, isSingleSchema}: ITabsProps) => {
	const {data: allTargetObjects} = useTargetObjectIdsHaveStructures({
		objectId,
		includingParentObjectId: true
	});
	useActiveSchemaStructuresType(objectId, allTargetObjects, isSingleSchema);
	const dispatch = useAppDispatch();
	const {changeSchemaStructureType} = structuresActions;
	const {schemaStructureTypeId, filters} = useAppSelector(s =>
		extractStructuresSchemaPageSettings(s, objectId)
	);
	const filteredObjects = useMemo(
		() => getFilteredByObjectsTargetObjectIds(allTargetObjects, filters),
		[allTargetObjects, filters]
	);
	const modifiedIds = filteredObjects ?? allTargetObjects;

	const {data: schemaStructuresTypes, isLoading} = useStructuresSchemaTypes(
		objectId,
		modifiedIds
	);

	const changeViewType = useCallback(
		(value: string) => {
			dispatch(
				changeSchemaStructureType({
					structureTypeId: value,
					objectIds: allTargetObjects
				})
			);
		},
		[dispatch, changeSchemaStructureType, allTargetObjects]
	);

	return (
		<SchemaTabs>
			{isLoading ? (
				<Skeleton />
			) : (
				schemaStructuresTypes &&
				schemaStructuresTypes.map(type => (
					<TabButton
						label={`${type.name} ${type.structuresQuantity}`}
						value={type.id}
						onClick={changeViewType}
						key={type.id}
						active={schemaStructureTypeId === type.id}
					/>
				))
			)}
		</SchemaTabs>
	);
});
