import {CSSProperties, memo, ReactNode, useMemo} from 'react';
import './DesktopHeader.less';
import classNames from 'classnames';
import {IconButton, LinkButton} from '../../buttons';
import {getEventIcon} from '../utils/getEventIcon';

interface IDesktopHeaderProps {
	className?: string;
	style?: CSSProperties;
	logoUrl?: string;
	logoTitle?: string;
	title?: string;
	sectionsMenu?: ReactNode;
	indicators?: ReactNode;
	userMenu?: ReactNode;

	onLogoClick?(): void;

	onBackBtnClick?(): void;
}

const DesktopHeader = (props: IDesktopHeaderProps) => {
	const {
		className,
		style,
		logoUrl = '/',
		logoTitle,
		title,
		sectionsMenu,
		indicators,
		userMenu,
		onLogoClick,
		onBackBtnClick
	} = props;

	const leftIcon = useMemo(() => getEventIcon(), []);

	return (
		<div
			className={classNames(
				'd-header',
				{'d-header_with-sections-menu': !!sectionsMenu},
				className
			)}
			style={style}
		>
			<div className="d-header__links">
				<LinkButton
					className="d-header__logo-link"
					leftIcon={leftIcon}
					url={logoUrl}
					title={logoTitle}
					onClick={onLogoClick}
				/>

				{onBackBtnClick && (
					<IconButton className="d-header__back-btn" onClick={onBackBtnClick}>
						<i className="tz-long-arrow-24" />
					</IconButton>
				)}
			</div>

			<div className="d-header__title" title={title}>
				{title}
			</div>

			{sectionsMenu && <div className="d-header__sections-menu">{sectionsMenu}</div>}
			<div className="d-header__indicators">{indicators}</div>
			<div className="d-header__user-menu">{userMenu}</div>
		</div>
	);
};

export default memo(DesktopHeader);
