import {useCallback} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesSelectionVisibility} from '@src/store/modules/settings/pages/spaces/selectors';
import {
	deselectAllSpaces,
	toggleSelectionSpacesVisibility
} from '@src/store/modules/settings/pages/spaces/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';

interface IUseSpaceSelectionReturn {
	isChoosingVisible: boolean;
	toggleChoosing: (state: boolean) => void;
	handleDeselectAllAndClose: () => void;
}

export const useSpacesSelection = (objectId: string): IUseSpaceSelectionReturn => {
	const dispatch = useAppDispatch();

	// Состояние видимости режима выбора
	const isChoosingVisible = useAppSelector(extractSpacesSelectionVisibility);

	// Включение/выключение режима выбора
	const toggleChoosing = useCallback(
		(state: boolean) => {
			dispatch(toggleSelectionSpacesVisibility(state));
		},
		[dispatch]
	);

	// Снятие всех выборов и выход из режима выбора
	const handleDeselectAllAndClose = useCallback(() => {
		dispatch(toggleSelectionSpacesVisibility(false));
		dispatch(deselectAllSpaces(objectId));
	}, [dispatch, objectId]);

	return {
		isChoosingVisible: !!isChoosingVisible,
		toggleChoosing,
		handleDeselectAllAndClose
	};
};
