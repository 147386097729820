import {combineReducers} from 'redux';
import check from './check/reducers';
import problem from './problem/reducers';
import spaces from './spaces/reducers';
import {pageSpaceReducer} from './space/slice';
import warrantyClaims from './warrantyClaims/slices';
import statistics from './statistics/reducers';
import manage from './manage';
import {tasks} from './tasks/slices';
import {internalAcceptance} from './internalAcceptance/reducers';
import {structure} from './structure/reducers';
import {objects} from './objects/reducers';
import {workAcceptances} from './workAcceptances/reducers';
import offlineJournal from './offlineJournal/reducers';
import workAcceptance from './workAcceptance/reducers';
import {contracts} from './contracts/reducers';
import {documents} from './documents/reducers';
import {legals} from './legals/reducers';
import {checksReducer} from './checks/slice';
import {checkListsReducer} from './checkLists/slice';
import {ownerAcceptancesPageSettings} from './ownerAcceptances/slice';
import {internalAcceptancesReducer} from './internalAcceptances/slice';
import {problemsReducer} from './problems/slice';
import {inspectionsReducer} from './inspections/slice';
import {structuresReducer} from '@/entities/Structures';
import {presaleCheckReducer} from '@/entities/PresaleCheck';
import {exportTemplatesReducer} from './exportTemlates/slice';

export default combineReducers({
	check,
	checks: checksReducer,
	checkLists: checkListsReducer,
	contracts,
	problems: problemsReducer,
	problem,
	tasks,
	inspections: inspectionsReducer,
	spaces,
	space: pageSpaceReducer,
	warrantyClaims,
	statistics,
	manage,
	internalAcceptance,
	internalAcceptances: internalAcceptancesReducer,
	structures: structuresReducer,
	structure,
	objects,
	workAcceptance,
	workAcceptances,
	offlineJournal,
	documents,
	legals,
	ownerAcceptancesPageSettings,
	presaleCheck: presaleCheckReducer,
	exportTemplates: exportTemplatesReducer
});
