import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {DesktopWorkAcceptanceAddBtn} from './WorkAcceptanceAddBtn.desktop';
import {DesktopWorkAcceptancesExport} from '../WorkAcceptancesExport.desktop';

interface IDesktopActionsProps {
	objectId?: string;
}

export const DesktopActions = ({objectId}: IDesktopActionsProps) => {
	const {canAdd, canExport} = useWorkAcceptancesPermissions(objectId);

	return (
		<>
			{canAdd && objectId ? <DesktopWorkAcceptanceAddBtn objectId={objectId} /> : null}
			{canExport ? <DesktopWorkAcceptancesExport objectId={objectId} /> : null}
		</>
	);
};
