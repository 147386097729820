import {useCallback, useMemo} from 'react';
import {
	EntitiesTable,
	LoadingPanel,
	Pagination,
	PaginationAndSize,
	Plate
} from '@tehzor/ui-components';
import {desktopColumns} from './columns.desktop';
import {MenuActionCtxProvider} from '../DispatchActionCtx';
import {IPreparedExportTemplate} from '../../interfaces/IPreparedExportTemplate';
import {convertExportTemplates} from '../../utils/convertExportTemplates';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useExportTemplatesPaginateDataWithoutPDF} from '@src/core/hooks/queries/exportTemplates/hooks';
import {exportTemplatesActions} from '@src/store/modules/settings/pages/exportTemlates/slice';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractExportTemplatesPageSettings} from '@src/store/modules/settings/pages/exportTemlates/selectors';
import {ExportTemplatesFilters} from '../ExportTemplatesFilters';
import {ExportTemplatesPageHeader} from '../ExportTemplatesPageHeader';

const pageSizes = [10, 20, 50, 100];

interface ITableProps {
	loading?: boolean;
}

export const Table = ({loading}: ITableProps) => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();

	const {data: templatesData} = useExportTemplatesPaginateDataWithoutPDF();

	const preparedData = useMemo(
		() => (templatesData?.data ? convertExportTemplates(templatesData.data) : null),
		[templatesData]
	);
	const {changePageSize, changeOffset} = exportTemplatesActions;
	const pageSettings = useAppSelector(s => extractExportTemplatesPageSettings(s));

	const pagesCount = templatesData?.total
		? Math.ceil(templatesData.total / pageSettings.pageSize)
		: 0;
	const currentPage = templatesData?.offset
		? Math.floor(templatesData.offset / pageSettings.pageSize)
		: 0;

	const handlePageSizeChange = useCallback(
		(value: number) => {
			if (templatesData) {
				dispatch(changePageSize({pageSize: value}));
				dispatch(changeOffset(Math.floor(templatesData.offset / value)));
			}
		},
		[templatesData, dispatch, changePageSize, changeOffset]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (templatesData?.offset !== offset) {
				dispatch(changeOffset(offset));
			}
		},
		[pageSettings.pageSize, templatesData?.offset, dispatch, changeOffset]
	);

	const handleRowClick = useCallback(
		(item: IPreparedExportTemplate) => {
			pushPath(`/manage/export-templates/${item.id}`);
		},
		[pushPath]
	);

	if (!preparedData) return null;
	return (
		<>
			<ExportTemplatesFilters />
			<ExportTemplatesPageHeader total={templatesData?.total ?? 0} />
			<Plate withoutPadding>
				<MenuActionCtxProvider>
					<LoadingPanel active={loading}>
						<EntitiesTable
							columns={desktopColumns}
							data={preparedData}
							onRowClick={handleRowClick}
						/>
					</LoadingPanel>
				</MenuActionCtxProvider>
			</Plate>
			<PaginationAndSize
				pagination={
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
		</>
	);
};
