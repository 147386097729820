import {
	IEditableEntityAction,
	IEditableEntityState
} from '@tehzor/tools/core/states/editableEntityState';
import {ICustomField, IFieldSetting} from '@tehzor/tools/interfaces/fields';
import {
	ActionButtons,
	Button,
	Dialog,
	EditableFieldLabel,
	Select2 as Select,
	SelectOption,
	SelectPopup,
	TextFieldWithForwardedRef
} from '@tehzor/ui-components';
import SelectSearch, {
	flatFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {Dispatch, memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {ICustomState} from '../CustomFields';
import {useTranslation} from 'react-i18next';

interface ICustomSelectFieldProps<S, E> {
	field: ICustomField;
	setting: IFieldSetting;
	isMobile?: boolean;
	hasError?: boolean;
	exception?: boolean;
	disabled?: boolean;

	editingState: IEditableEntityState<S>;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
}

const getValue = (value: unknown): string | undefined => {
	if (typeof value === 'string') {
		return value;
	}
	return undefined;
};

const getIcon = (icon?: string) =>
	icon ? <i className={icon} /> : <i className="tz-simple-arrow-20" />;

const CusomSelectFieldComponent = <S extends ICustomState, E>({
	field,
	setting,
	isMobile,
	hasError,
	exception,
	disabled,
	editingState,
	editingDispatch
}: ICustomSelectFieldProps<S, E>) => {
	const value = getValue(editingState.customFields?.[field.key]);

	const {t} = useTranslation();
	const [checked, setChecked] = useState<string | undefined | null>(value);
	const [isOpen, setOpen] = useState(false);

	const [search, setSearch] = useState('');

	const clearSearch = useCallback(() => setSearch(''), []);

	useUpdateEffect(() => setChecked(value), [value]);

	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);

	const items = useMemo(() => field.values?.map(val => ({id: val})), [field]);
	const isSearchShown = (items?.length || 0) >= 8;

	const filteredItems = useMemo(() => flatFilter(items || [], 'id', search), [items, search]);

	useEffect(() => {
		editingDispatch({type: 'create-custom-error', field: field.key});
	}, [field.key]);

	// убираем обязательность поля при отсутствии вариантов для выбора
	useEffect(() => {
		if (setting.required && !exception) {
			const isException = !field.values || field.values.length === 0;

			editingDispatch({
				type: 'update-custom-exception',
				field: field.key,
				value: isException
			});
		}
	}, [field, exception, setting.required]);

	const onConfirm = useCallback(
		(val?: string | null) => {
			editingDispatch({
				field: 'customFields',
				type: 'update',
				value: {...editingState.customFields, [field.key]: val}
			});

			if (setting.required) {
				editingDispatch({type: 'update-custom-error', field: field.key});
			}
		},
		[editingState.customFields, field, setting.required]
	);

	const handleCancel = useCallback(() => {
		setChecked(value);
		if (isMobile) {
			close();
		}
		clearSearch();
	}, [value, isMobile]);

	const handleConfirm = useCallback(() => {
		onConfirm(checked);
		if (isMobile) {
			close();
		}

		clearSearch();
	}, [checked, onConfirm, isMobile]);

	const handleClearButton = useCallback(() => {
		onConfirm(null);
		clearSearch();

		if (setting.required) {
			editingDispatch({type: 'update-custom-error', field: field.key});
		}
	}, [onConfirm, setting.required, field.key]);

	const trigger = (
		<TextFieldWithForwardedRef
			elementType="div"
			value={value}
			icon={getIcon(field.icon)}
			disabled={!items?.length || disabled}
			onClearClick={handleClearButton}
			onClick={isMobile ? open : undefined}
			cleanable={!!value}
			error={
				setting.required && hasError ? t('components.editableFields.text.error') : undefined
			}
		/>
	);

	const content = (
		<Select
			value={checked || undefined}
			onChange={setChecked}
		>
			{filteredItems?.map(item => (
				<SelectOption
					key={item.id}
					itemKey={item.id}
					content={item.id}
					inputType="radio"
				/>
			))}
		</Select>
	);

	const selectSearch = isSearchShown ? (
		<SelectSearch
			value={search}
			onChange={setSearch}
			onClear={clearSearch}
		/>
	) : null;

	const footer = (
		<div>
			<ActionButtons>
				<Button
					type="cancel"
					label="Отменить"
					onClick={handleCancel}
				/>
				<Button
					type="accent-blue"
					label="Сохранить"
					onClick={handleConfirm}
				/>
			</ActionButtons>
		</div>
	);

	return (
		<div>
			<EditableFieldLabel>
				{setting.required ? `${field.name} *` : field.name}
			</EditableFieldLabel>

			{!isMobile ? (
				<SelectPopup
					dataTestId={`SelectField${field.id}SelectPopup`}
					noHeader={!isSearchShown}
					search={selectSearch}
					footer
					onCancel={handleCancel}
					onApply={handleConfirm}
					trigger={trigger}
				>
					{content}
				</SelectPopup>
			) : (
				trigger
			)}
			{isMobile && (
				<Dialog
					dataTestId={`SelectField${field.id}Dialog`}
					isOpen={isOpen}
					fullScreenOnMobile
					title={setting.required ? `${field.name} *` : field.name}
					onRequestClose={close}
					footer={footer}
				>
					<div>{selectSearch}</div>
					{content}
				</Dialog>
			)}
		</div>
	);
};

export const CustomSelectField = memo(
	CusomSelectFieldComponent
) as typeof CusomSelectFieldComponent;
