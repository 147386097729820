import {getSelectionMessage} from '../../utils/getSelectionMessage';

export const SelectionMessage = ({
	totalSelectedByCurrentType,
	nonEmptySelectedCount,
	typeCounts
}: {
	totalSelectedByCurrentType: number;
	nonEmptySelectedCount: number;
	typeCounts: number;
}) => {
	const message = getSelectionMessage(
		totalSelectedByCurrentType,
		nonEmptySelectedCount,
		typeCounts
	);

	return message ? <p>{message}</p> : null;
};
