import {checkRecordsQueryKeys} from '@src/api/cache/checkRecords/keys';
import {plansQueryKeys} from '@src/api/cache/plans/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {queryClient} from '@src/api/QueryClient';

export const prefetchCache = async (queryKeys: string[][], objects?: string[]) => {
	const plansQuerySettings = queryClient.getQueryDefaults(plansQueryKeys.lists());
	const spacesQuerySettings = queryClient.getQueryDefaults(spacesQueryKeys.typesCounts());
	const spacesQueryTypesCountSettings = queryClient.getQueryDefaults(spacesQueryKeys.list());
	const checkRecordsQuerySettings = queryClient.getQueryDefaults(checkRecordsQueryKeys.list());
	if (!objects || !objects.length) return;
	const promises = [];
	if (queryKeys.length) {
		for (const queryKey of queryKeys) {
			if (queryKey.join() === plansQueryKeys.lists().join()) {
				await queryClient.prefetchQuery({
					networkMode: 'offlineFirst',
					queryKey: plansQueryKeys.list(objects),
					staleTime: plansQuerySettings?.staleTime,
					gcTime: plansQuerySettings?.gcTime
				});
				continue;
			}
			const querySettings = queryClient.getQueryDefaults(queryKey);
			promises.push(async () =>
				queryClient.prefetchQuery({
					queryKey,
					networkMode: querySettings?.networkMode,
					staleTime: querySettings?.staleTime,
					gcTime: querySettings?.gcTime
				})
			);
		}
	}

	await Promise.allSettled(promises.map(async query => query()));
	// Кеширование помещений
	await queryClient.prefetchQuery({
		networkMode: 'offlineFirst',
		queryKey: [...spacesQueryKeys.list(), {objects}, null, null, 100000],
		staleTime: spacesQuerySettings?.staleTime,
		gcTime: spacesQuerySettings?.gcTime
	});
	// Кеширование помещений для шахматки
	await queryClient.prefetchQuery({
		networkMode: 'offlineFirst',
		queryKey: [...spacesQueryKeys.listWithStats(), {objects}, null, null, 100000],
		staleTime: spacesQuerySettings?.staleTime,
		gcTime: spacesQuerySettings?.gcTime
	});
	// Кеширование счетчиков помещений
	await queryClient.prefetchQuery({
		queryKey: spacesQueryKeys.typesCount(objects),
		networkMode: 'offlineFirst',
		staleTime: spacesQueryTypesCountSettings?.staleTime,
		gcTime: spacesQueryTypesCountSettings?.gcTime
	});
	// Кеширование записей чек листов
	await queryClient.prefetchQuery({
		queryKey: [...checkRecordsQueryKeys.list(), undefined, objects],
		networkMode: 'offlineFirst',
		staleTime: checkRecordsQuerySettings?.staleTime,
		gcTime: checkRecordsQuerySettings?.gcTime
	});
};
