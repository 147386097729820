import {useTranslation} from 'react-i18next';
import {useUpdateEffect} from 'react-use';
import {addSuccessToast} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractPendingDefferedExportTasks} from '@src/store/modules/defferedTasks/export/selectors';
import {useRef} from 'react';

export const useExportPending = () => {
	const {t} = useTranslation();
	const pendingTasks = useAppSelector(extractPendingDefferedExportTasks);
	const pendingTasksIds = Object.keys(pendingTasks)
	const prevPendingTasksRef = useRef(pendingTasksIds);

	useUpdateEffect(() => {
		const currentPendingTaskIds = pendingTasksIds;
		const isNewTaskAdded = currentPendingTaskIds.some(
			taskId => !prevPendingTasksRef.current.includes(taskId)
		);
		if (isNewTaskAdded) {
			addSuccessToast({title: t('toast.processing.startExport.title')});
		}
		prevPendingTasksRef.current = currentPendingTaskIds;
	}, [pendingTasks]);
};
