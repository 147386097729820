import {Dispatch} from 'react';
import * as React from 'react';
import {
	IEditableObjectAction,
	IEditableObjectState
} from '@src/core/hooks/states/useEditableObjectState';
import {NameField} from './components/NameField';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import './EditableObject.less';
import {ManagerFields} from './components/ManagerFields';
import {ImageField} from './components/ImageField/ImageField';
import {AttachmentFileDestination} from '@tehzor/tools/enums/AttachmentFileDestination';
import {
	IUploadingFilesAction,
	IUploadingFilesState
} from '@src/core/hooks/states/useUploadingFilesState';
import {StageFields} from './components/StageFields';
import CompanySelect from '../editableFields/CompanySelect';
import {useTranslation} from 'react-i18next';
import {CustomField} from '@src/components/editableFields/CustomField';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {ICustomFieldSetting} from '@tehzor/tools/interfaces/fields';
import classNames from 'classnames';

interface IEditableObjectProps {
	objectId?: string;
	companyId?: string;

	editingState: IEditableObjectState;
	editingDispatch: React.Dispatch<IEditableObjectAction>;

	uploadingFilesState: IUploadingFilesState;
	uploadingFilesDispatch: Dispatch<IUploadingFilesAction>;

	saving: boolean;

	customFieldsSettings: Record<string, ICustomFieldSetting>;
}

export const fieldsSettings: {
	[key: string]: IObjectFieldSetting;
} = {
	name: {fieldId: 'name', isRequired: true},
	companyId: {fieldId: 'companyId', isRequired: true},
	city: {fieldId: 'city', isRequired: false},
	address: {fieldId: 'address', isRequired: false},
	generalContractor: {fieldId: 'generalContractor', isRequired: false},
	constructionManager: {fieldId: 'constructionManager', isRequired: false},
	projectManager: {fieldId: 'projectManager', isRequired: false},
	stages: {fieldId: 'stages', isRequired: false},
	image: {fieldId: 'image', isRequired: false}
};

export const EditableObject = ({
	objectId,
	companyId,
	editingState,
	editingDispatch,
	uploadingFilesState,
	uploadingFilesDispatch,
	saving,
	customFieldsSettings
}: IEditableObjectProps) => {
	const {t} = useTranslation();
	const isDesktop = useIsDesktop();
	const customFields = Object.values(customFieldsSettings);
	const hasCustomFields = !!customFields.length;
	return (
		<div className="editable-object">
			<div>
				<div className="editable-object__info-grid">
					<div className="editable-object__container">
						{fieldsSettings.name && (
							<NameField
								label={t('editableObject.nameField.label')}
								className="editable-object__container-name"
								value={editingState.name}
								editingDispatch={editingDispatch}
								required={fieldsSettings.name.isRequired}
								disabled={saving}
								hasError={editingState.errors.name}
								errorMessage={t('editableObject.nameField.error')}
							/>
						)}

						{fieldsSettings.companyId && !companyId && (
							<CompanySelect
								className="editable-object__container-company"
								field="companyId"
								label={t('editableObject.companySelect.label')}
								value={editingState.companyId}
								editingDispatch={editingDispatch}
								required={fieldsSettings.companyId.isRequired}
								disabled={saving}
								hasError={editingState.errors.companyId}
								errorMessage={t('editableObject.companySelect.error')}
							/>
						)}
					</div>

					{fieldsSettings.city && (
						<NameField
							className="editable-object__city"
							value={editingState.city}
							field="city"
							editingDispatch={editingDispatch}
							required={fieldsSettings.city.isRequired}
							disabled={saving}
							label={t('editableObject.nameField.city.label')}
							hasError={editingState.errors.city}
							errorMessage={t('editableObject.nameField.city.error')}
						/>
					)}

					{fieldsSettings.generalContractor && (
						<NameField
							className="editable-object__general-contractor"
							value={editingState.generalContractor}
							field="generalContractor"
							editingDispatch={editingDispatch}
							required={fieldsSettings.generalContractor.isRequired}
							disabled={saving}
							hasError={editingState.errors.generalContractor}
							label={t('editableObject.nameField.generalContractor.label')}
							errorMessage={t('editableObject.nameField.generalContractor.error')}
						/>
					)}

					<div>
						{fieldsSettings.address && (
							<NameField
								className="editable-object__address"
								value={editingState.address}
								field="address"
								editingDispatch={editingDispatch}
								required={fieldsSettings.address.isRequired}
								disabled={saving}
								label={t('editableObject.nameField.address.label')}
								hasError={editingState.errors.address}
								errorMessage={t('editableObject.nameField.address.error')}
							/>
						)}
					</div>
				</div>

				{fieldsSettings.projectManager && (
					<ManagerFields
						field="projectManager"
						title={t('editableObject.managerFields.projectManager.title')}
						value={editingState.projectManager}
						required={fieldsSettings.projectManager.isRequired}
						disabled={saving}
						className={{
							root: 'editable-object__project-manager',
							title: 'editable-object__project-manager-title',
							wrap: 'editable-object__project-manager-wrap',
							fullName: 'editable-object__project-manager-wrap__fullName',
							phone: 'editable-object__project-manager-wrap__phone'
						}}
						errorMessage={{
							fullName: t(
								'editableObject.managerFields.projectManager.fullName.error'
							),
							phone: t('editableObject.managerFields.projectManager.phone.error')
						}}
						hasError={editingState.errors.projectManager}
						editingDispatch={editingDispatch}
					/>
				)}

				{fieldsSettings.constructionManager && (
					<ManagerFields
						field="constructionManager"
						title={t('editableObject.managerFields.constructionManager.title')}
						value={editingState.constructionManager}
						required={fieldsSettings.constructionManager.isRequired}
						disabled={saving}
						className={{
							root: 'editable-object__construction-manager',
							title: 'editable-object__construction-manager-title',
							wrap: 'editable-object__construction-manager-wrap',
							fullName: 'editable-object__construction-manager-wrap__fullName',
							phone: 'editable-object__construction-manager-wrap__phone'
						}}
						errorMessage={{
							fullName: t(
								'editableObject.managerFields.constructionManager.fullName.error'
							),
							phone: t('editableObject.managerFields.constructionManager.phone.error')
						}}
						hasError={editingState.errors.constructionManager}
						editingDispatch={editingDispatch}
					/>
				)}

				{hasCustomFields && (
					<div
						className={classNames('editable-object__custom-fields', {
							'editable-object__custom-fields-wrap': customFields.length !== 1
						})}
					>
						{customFields.map(customSetting => (
							<CustomField
								key={customSetting.id}
								custom={customSetting}
								isMobile={!isDesktop}
								editingDispatch={editingDispatch}
								editingState={editingState}
							/>
						))}
					</div>
				)}

				{fieldsSettings.stages && (
					<StageFields
						title={t('editableObject.stageFields.title')}
						value={editingState.stages}
						editingDispatch={editingDispatch}
						required={fieldsSettings.stages.isRequired}
						disabled={saving}
						className={{
							root: 'editable-object__stages',
							title: 'editable-object__stages-title',
							wrap: 'editable-object__stages-wrap',
							stage: 'editable-object__stages-wrap__Stage'
						}}
						hasError={editingState.errors.stages}
						errorMessage={t('editableObject.stageFields.error')}
					/>
				)}
			</div>

			{fieldsSettings.image && (
				<ImageField
					className={{
						root: 'editable-object__image-field',
						scrollArea: 'editable-object__image-field-scroll-area',
						attachBtn: 'editable-object__image-field-attach-btn'
					}}
					imageTitle={t('editableObject.imageField.imageTitle')}
					label={t('editableObject.imageField.label')}
					imageDestination={AttachmentFileDestination.OBJECT}
					entityId={objectId}
					image={editingState.image}
					editingDispatch={editingDispatch}
					uploadingFile={uploadingFilesState.value[0]}
					uploadingFilesDispatch={uploadingFilesDispatch}
					required={fieldsSettings.image.isRequired}
					disabled={saving}
					hasError={editingState.errors.image && uploadingFilesState.error}
					errorMessage={t('editableObject.imageField.error')}
					sizeErrorMessage={t('editableObject.imageField.sizeError')}
					showAttachBtn
				/>
			)}
		</div>
	);
};
