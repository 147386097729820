import {memo} from 'react';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useEditableWorkAcceptanceDialog} from '@src/components/EditableWorkAcceptanceDialog/hooks/useEditableWorkAcceptanceDialog';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useTranslation} from 'react-i18next';
import {useWorkAcceptancesExport} from '@src/core/hooks/export/useWorkAcceptancesExport';

interface IActionsMobileProps {
	canAdd?: boolean;
	canExport?: boolean;
	objectId: string;
}
const plusIcon = <i className="tz-plus-24" />;
const exportIcon = <i className="tz-export-24" />;

export const ActionsMobile = memo((props: IActionsMobileProps) => {
	const {canAdd, canExport, objectId} = props;
	const {t} = useTranslation();

	const [addingDialog, openAddingDialog] = useEditableWorkAcceptanceDialog(
		objectId,
		ObjectStageIds.BUILDING
	);

	const [exportDialog, toggleOpen] = useWorkAcceptancesExport(objectId);

	const updateData = useAppUpdateMenuItem();
	const items = [updateData];

	if (canAdd) {
		items.push(
			<MenuItem
				key="add"
				icon={plusIcon}
				onClick={openAddingDialog}
			>
				{t('workAcceptancePage.actionsMobile.add')}
			</MenuItem>
		);
	}

	if (canExport) {
		items.push(
			<MenuItem
				key="export"
				icon={exportIcon}
				onClick={toggleOpen}
			>
				{t('exportButton.label')}
			</MenuItem>
		);
	}

	return (
		<>
			<IconMenu>{items}</IconMenu>
			{canAdd && addingDialog}
			{canExport && exportDialog}
		</>
	);
});
