import {createMutation} from 'react-query-kit';
import {ownerAcceptancesQueryKeys} from '../keys';
import {
	IAddOwnerAcceptanceParams,
	IEditOwnerAcceptanceParams,
	IEditOwnerAcceptanceStatusParams
} from '../defaults/useOwnerAcceptancesMutationDefaults';
import {IOwnerAcceptance} from '@tehzor/tools/interfaces/ownerAcceptances/IOwnerAcceptance';

export const useAddOwnerAcceptanceMutation = createMutation<
	IOwnerAcceptance,
	IAddOwnerAcceptanceParams
>({
	mutationKey: ownerAcceptancesQueryKeys.add(),
	scope: {id: 'ownerAcceptances'}
});

export const useEditOwnerAcceptanceMutation = createMutation<
	IOwnerAcceptance,
	IEditOwnerAcceptanceParams
>({
	mutationKey: ownerAcceptancesQueryKeys.edit()
});

export const useEditOwnerAcceptanceStatusMutation = createMutation<
	IOwnerAcceptance,
	IEditOwnerAcceptanceStatusParams
>({
	mutationKey: ownerAcceptancesQueryKeys.editStatus()
});
