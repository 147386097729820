import React from 'react';
import {Button} from '@tehzor/ui-components';

interface SpaceChoosingToolbarTableProps {
	isVisible: boolean | undefined;
	disabled: boolean;
	selectedCount: number;
	openStageSelectionDialog: () => void;
}

export const SpaceChoosingToolbarTable: React.FC<SpaceChoosingToolbarTableProps> = ({
	isVisible,
	disabled,
	selectedCount,
	openStageSelectionDialog
}) => {
	if (!isVisible) return null;
	return (
		<>
			<div>{selectedCount}</div>
			<Button
				type="accent-blue"
				leftIcon={<i className="tz-plus-20" />}
				disabled={disabled}
				label="Добавить"
				onClick={openStageSelectionDialog}
			/>
		</>
	);
};
