import {useMutation} from '@tanstack/react-query';
import {Dispatch, SetStateAction} from 'react';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {
	IAddInternalAcceptancesResponse,
	makeInternalAcceptancesAddRequest
} from '@src/api/backend/internalAcceptances/add';
import {IAddInspectionsResponse, IAddProblemsResponse} from '@src/api/backend/checks';

export interface IAddMultipleInternalAcceptancesWithEntitiesParams {
	objectId: string;
	spaceIds: string[];
	links: IInternalAcceptance['links'];
	savingData: ISavingProblem | ISavingInspection;
	acceptanceType: IInternalAcceptanceAddingEntityType;
	setSaving: Dispatch<SetStateAction<boolean>>;
	onClose: () => void;
}

export const useAddMultipleInternalAcceptancesWithEntities = () => {
	const mutation = useMutation<
		Record<
			string,
			| IAddInternalAcceptancesResponse
			| IAddProblemsResponse
			| IAddInspectionsResponse
			| string[]
		>,
		Error,
		IAddMultipleInternalAcceptancesWithEntitiesParams
	>({
		mutationFn: async ({objectId, spaceIds, links, acceptanceType, savingData}) =>
			makeInternalAcceptancesAddRequest(
				objectId,
				spaceIds,
				links,
				acceptanceType,
				savingData
			),
		onSuccess: (response, {setSaving, onClose}) => {
			setSaving(false);
			onClose();
		},
		onError: error => {
			console.error('Ошибка при создании предпродажных проверок:', error);
		}
	});

	return async ({
		objectId,
		spaceIds,
		links,
		savingData,
		acceptanceType,
		setSaving,
		onClose
	}: IAddMultipleInternalAcceptancesWithEntitiesParams) => {
		await mutation.mutateAsync({
			objectId,
			spaceIds,
			links,
			acceptanceType,
			savingData,
			setSaving,
			onClose
		});
	};
};
