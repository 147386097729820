import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {findPermission} from '@tehzor/tools/utils/findPermission';
import {useMemo} from 'react';
import useAppSelector from '../useAppSelector';
import {useStrictParams} from '../useStrictParams';

export const useMultipleEntitiesAddingPermissions = () => {
	const user = useAppSelector(s => s.auth.profile);

	const {objectId} = useStrictParams<{objectId: string}>();

	const addMultiplePermissionAll = useMemo(
		() => findPermission(user.roles || [], 'checksAdd', UserRoleScopes.ALL),
		[user.roles]
	);

	const addMultiplePermissionObject = useMemo(
		() => findPermission(user.roles || [], 'checksAdd', UserRoleScopes.OBJECT, objectId),
		[user.roles, objectId]
	);

	const canAddMultipleEntitiesGlobal = useMemo(
		() =>
			addMultiplePermissionAll
				? !(addMultiplePermissionAll.restrictions?.['checks-add-from-space'] === true)
				: false,
		[addMultiplePermissionAll]
	);

	const canAddMultipleEntitiesObject = useMemo(
		() =>
			addMultiplePermissionObject
				? !(addMultiplePermissionObject.restrictions?.['checks-add-from-space'] === true)
				: false,
		[addMultiplePermissionObject]
	);

	const canAddMultipleEntities = useMemo(
		() => canAddMultipleEntitiesGlobal || canAddMultipleEntitiesObject,
		[canAddMultipleEntitiesGlobal, canAddMultipleEntitiesObject]
	);

	return {
		canAddMultipleEntities
	};
};
