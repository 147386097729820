import {combineReducers} from 'redux';
import check from './check/reducers';
import problemsLastReplies from './problemsLastReplies/reducers';
import workAcceptancesLastReplies from './workAcceptancesLastReplies/reducers';
import {internalAcceptance} from './internalAcceptance/reducers';
import devices from './devices/reducers';
import {workAcceptance} from './workAcceptance/reducers';

export const entities = combineReducers({
	check,
	devices,
	problemsLastReplies,
	internalAcceptance,
	workAcceptance,
	workAcceptancesLastReplies
});
