import {ModalDialog} from '@tehzor/ui-components/src/components/dialogs';
import {IUnitIndicatorsSetWithIndicators} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicatorsSet';
import {SpaceIndicatorsSet, Tag} from '@tehzor/ui-components/src/components/tags';
import classNames from 'classnames';
import './IndicatorsDialog.less';
import {IDialogProps} from '@tehzor/ui-components/src/components/dialogs/Dialog';
import {convertClassNames} from '@tehzor/ui-components/src/utils/convertClassNames';

interface IIndicatorsDialogProps {
	isModalOpen: boolean;
	closeModal: () => void;
	indicatorsSets: IUnitIndicatorsSetWithIndicators[];
	mobile?: boolean;
	className?: IDialogProps['className'];
	title?: string;
}

export const IndicatorsDialog = ({
	isModalOpen,
	closeModal,
	indicatorsSets,
	mobile = false,
	className,
	title = 'Индикаторы'
}: IIndicatorsDialogProps) => {
	const classes = convertClassNames(className);
	classes.root = classNames('indicators-dialog', classes.root);
	classes.layer = classNames('indicators-dialog__layer', classes.layer);
	classes.body = classNames('indicators-dialog__body', classes.body);
	classes.content = classNames('indicators-dialog__content', classes.content);

	return (
		<ModalDialog
			open={isModalOpen}
			title={title}
			onRequestClose={closeModal}
			className={classes}
		>
			<div className="indicators-dialog__sets-info">
				{indicatorsSets.map(set => (
					<div
						key={set.id}
						className="indicators-dialog__set-wrapper"
					>
						<SpaceIndicatorsSet
							set={set}
							className={mobile ? 'indicators-dialog-mobile' : 'indicators-dialog'}
						>
							<div className="indicators-dialog__indicators-wrapper">
								{set.indicators.map(indicator => (
									<div
										key={indicator.id}
										className="indicators-dialog__indicator-wrapper"
									>
										<Tag
											color={`${indicator.color}33`}
											label={indicator.name}
											icon={
												mobile ? (
													<div
														className="space-indicator-tag__icon"
														style={{backgroundColor: indicator.color}}
													/>
												) : null
											}
										/>
									</div>
								))}
							</div>
						</SpaceIndicatorsSet>
					</div>
				))}
			</div>
		</ModalDialog>
	);
};
