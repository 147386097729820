import {offlineJournalActionType} from '../enums/actionsTypes';
import {IOfflineJournalEntity} from '../interfaces/IOfflineJournalEntity';
import {useFindMutation} from './useFindMutation';
import {ISavingProblemQuery} from '@src/core/hooks/mutations/problem/useAddProblem';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {ISavingInspectionQuery} from '@src/core/hooks/mutations/inspection/useAddInspection';
import {OfflineDataTransferStatus} from '@tehzor/tools/contracts/dataTransferWebWorker/interfaces/IOfflineDataTransferStatuses';
import {useMemo} from 'react';
import {findParentCache} from '../utils/findParentCache';
import {findParentMutation} from '../utils/findParentMutation';
import {findEntityLinks} from '../utils/findEntityLinks';
import {useEntityTransferState} from '@src/core/hooks/useEntityTransferState';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';

export const useNeedTransferButton = (entity: IOfflineJournalEntity['entity']) => {
	const {entityId} = entity;
	const currentMutation = useFindMutation(entityId);
	const status = useEntityTransferState(entityId);
	const networkStatus = useOnlineManager();

	return useMemo(() => {
		let hasParentMutation = false;
		let hasParentCache = false;
		let parentMutation;

		switch (entity.entityType) {
			case offlineJournalActionType.PROBLEM_ADD:
			case offlineJournalActionType.PROBLEM_CHECKLIST_ADD:
			case offlineJournalActionType.PROBLEM_SPACE_ADD:
				parentMutation = findParentMutation(
					findEntityLinks<ISavingProblemQuery>(problemsQueryKeys.savingData, entityId)
				);
				hasParentMutation = !!parentMutation;

				hasParentCache = findParentCache(
					findEntityLinks<ISavingProblemQuery>(problemsQueryKeys.savingData, entityId)
				);
				break;
			case offlineJournalActionType.INSPECTION_ADD:
			case offlineJournalActionType.INSPECTION_CHECKLIST_ADD:
			case offlineJournalActionType.INSPECTION_SPACE_ADD:
				parentMutation = findParentMutation(
					findEntityLinks<ISavingInspectionQuery>(
						inspectionsQueryKeys.savingData,
						entityId
					)
				);
				hasParentMutation = !!parentMutation;

				hasParentCache = findParentCache(
					findEntityLinks<ISavingInspectionQuery>(
						inspectionsQueryKeys.savingData,
						entityId
					)
				);
				break;
		}
		if (status === OfflineDataTransferStatus.TRANSFER_ERROR) {
			return true;
		}
		if (!currentMutation && !hasParentMutation && !hasParentCache) {
			return true;
		}
		if (
			status === OfflineDataTransferStatus.SAVED_LOCAL &&
			parentMutation?.state.status !== 'success' &&
			parentMutation?.state.status !== 'pending' &&
			parentMutation?.state.status !== 'error' &&
			networkStatus
		) {
			return true;
		}
		return false;
	}, [entity.entityType, status, currentMutation, entityId, networkStatus]);
};
