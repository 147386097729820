import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IListProblem} from '@tehzor/tools/interfaces/problems/IListProblem';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetPaginateProblemsResponse extends INormalizedData<IListProblem> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает список нарушений
 */
export const requestPaginateProblems = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number,
	objectId?: string
) => {
	const response = await httpRequests.withToken.post<IGetPaginateProblemsResponse>(
		'problems/get-paginate-problems',
		{
			filters,
			sort: convertSortParam(sort),
			offset,
			limit,
			objectId
		}
	);
	return response.data;
};
