import {queryClient} from '@src/api/QueryClient';
import SocketConnector from '@src/api/SocketConnector';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {presaleChecksQueryKeys} from '@src/fsd/src/entities/PresaleCheck';
import {
	deselectAllSpaces,
	selectSpaces,
	toggleSelectionSpacesVisibility
} from '@src/store/modules/settings/pages/spaces/actions';
import {IMultiplePresaleChecksCreationFailed} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/presaleChecks/IMultiplePresaleChecksCreationFailed';
import {IMultiplePresaleChecksCreationFinished} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/presaleChecks/IMultiplePresaleChecksCreationFinished';
import declination from '@tehzor/tools/utils/declination';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useCallback, useEffect} from 'react';

export const useMultiplePresaleChecksCreationEventHandler = () => {
	const socketConnector = SocketConnector.instance;
	const dispatch = useAppDispatch();
	const {objectId: parentObjectId} = useStrictParams();

	const handleFinishedPresaleChecksCreation = useCallback(
		(message: IMultiplePresaleChecksCreationFinished) => {
			dispatch(deselectAllSpaces(parentObjectId as string));
			dispatch(toggleSelectionSpacesVisibility(false));

			addSuccessToast({
				title: `${declination(message.data?.length, [
					'Добавлена',
					'Добавлено',
					'Добавлено'
				])} ${message.data?.length} ${declination(message.data?.length, [
					'предпродажная проверка',
					'предпродажные проверки',
					'предпродажных проверок'
				])}`
			});
			if (message?.data) {
				void queryClient.invalidateQueries({
					queryKey: presaleChecksQueryKeys.list()
				});
				void queryClient.invalidateQueries({queryKey: spacesQueryKeys.presaleChecks()});
			}
		},
		[dispatch, parentObjectId]
	);
	const handleFailedPresaleChecksCreation = useCallback(
		(message: IMultiplePresaleChecksCreationFailed) => {
			const {objectId, data, spacesType} = message;
			const convertedData = data.map(spaceId => ({id: spaceId, type: spacesType}));
			dispatch(
				selectSpaces(parentObjectId as string, [...data], {[objectId]: convertedData})
			);
			addErrorToast({title: 'Ошибка при создании предпродажных проверок'});
		},
		[dispatch]
	);

	useEffect(() => {
		socketConnector.subscribeOnMultiplePresaleChecksCreationEvents(
			handleFinishedPresaleChecksCreation,
			handleFailedPresaleChecksCreation
		);
		return () => socketConnector.unsubscribeOnMultiplePresaleChecksCreationEvents();
	}, [handleFinishedPresaleChecksCreation, handleFailedPresaleChecksCreation, socketConnector]);
};
