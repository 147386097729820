import {
	requestGetListUnitIndicatorsSets,
	requestGetPaginateUnitIndicatorsSets
} from '@src/api/backend/unitIndicatorsSets';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {
	IGetListUnitIndicatorsSetsQueryKey,
	IGetPaginateUnitIndicatorsSetsQueryKey,
	IGetUnitIndicatorsSetQueryKey,
	unitIndicatorsSetsQueryKeys
} from '../keys';
import {requestGetUnitIndicatorsSet} from '@src/api/backend/unitIndicatorsSet';

export const useUnitIndicatorsSetsQueryDefaults = () => {
	const queryClient = useQueryClient();
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);

	queryClient.setQueryDefaults(unitIndicatorsSetsQueryKeys.paginates(), {
		queryFn: async ({
			queryKey
		}: QueryFunctionContext<IGetPaginateUnitIndicatorsSetsQueryKey>) => {
			const [, , filters, sort, offset, limit] = queryKey;
			return requestGetPaginateUnitIndicatorsSets(filters, sort, offset, limit);
		},
		staleTime: 1000 * 60 * 5,
		gcTime: 1000 * 60 * 60 * 24,
		enabled: !isOfflineModeAvailable
	});

	queryClient.setQueryDefaults(unitIndicatorsSetsQueryKeys.lists(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IGetListUnitIndicatorsSetsQueryKey>) => {
			const [, , filters] = queryKey;
			return requestGetListUnitIndicatorsSets(filters);
		},
		staleTime: 1000 * 60 * 5,
		gcTime: 1000 * 60 * 60 * 24,
		enabled: !isOfflineModeAvailable
	});

	queryClient.setQueryDefaults(unitIndicatorsSetsQueryKeys.details(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IGetUnitIndicatorsSetQueryKey>) => {
			const [, , unitIndicatorsSetId] = queryKey;
			return requestGetUnitIndicatorsSet(unitIndicatorsSetId);
		},
		staleTime: 1000 * 60 * 5,
		gcTime: 1000 * 60 * 60 * 24,
		enabled: !isOfflineModeAvailable
	});
};
