import {ReactNode} from 'react';
import {LogoNewYear} from '../Icons/LogoNewYear';

interface IEvent {
	name: string;
	startDate: (year: number) => Date;
	endDate: (year: number) => Date;
	endOnNextYear?: boolean;
	icon?: string;
	component?: ReactNode;
}

export const events: IEvent[] = [
	{
		name: 'new year',
		startDate: (year: number) => new Date(year, 11, 16),
		endDate: (year: number) => new Date(year, 0, 11),
		endOnNextYear: true,
		component: <LogoNewYear />
	}
];
