import {IGetExportTemplatesResponse} from '@src/api/backend/exportTemplates/get';
import IExportTemplate from '@tehzor/tools/interfaces/IExportTemplate';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

export const extractExportTemplatesData = (data: IGetExportTemplatesResponse) => data;

export const extractExportTemplatesAsArray = (data: IGetExportTemplatesResponse) =>
	data.allIds.map((id: string) => data.byId[id]);

export const extractExportTemplatesDataWithDocsAsArray = (data: IGetExportTemplatesResponse) => ({
	data: data.allIds.map((id: string) => data.byId[id]),
	limit: data.limit,
	offset: data.offset,
	total: data.total
});

export const extractExportTemplatesFromGeneralRegisters = (
	data: IGetExportTemplatesResponse,
	userCompanies: string[] = []
): IExportTemplate[] =>
	extractExportTemplatesAsArray(data).filter(
		item =>
			(item.companyId && userCompanies.includes(item.companyId) && !item.objects?.length) ||
			!item.companyId
	);

export const extractExportTemplatesFromTargetObject = (
	data: IGetExportTemplatesResponse,
	object: IObject
): IExportTemplate[] =>
	extractExportTemplatesAsArray(data).filter(item => {
		if (item.objects?.length) {
			return item.objects.includes(object.id) || !item.companyId;
		}
		return (item.companyId && item.companyId === object.companyId) || !item.companyId;
	});

export const extractExportTemplateById = (
	data: IGetExportTemplatesResponse,
	exportTemplateId: string | undefined
) => {
	if (exportTemplateId) {
		return data.byId[exportTemplateId];
	}
	return undefined;
};
