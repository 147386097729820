import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';

export interface ISpacesPagesSharedSettingsState {
	altNamesVisible?: boolean;
	pending?: boolean;
	selectionSpacesVisible?: boolean;
}

export const getInitialState = (): ISpacesPagesSharedSettingsState => ({
	altNamesVisible: false,
	pending: false,
	selectionSpacesVisible: false
});

export default createReducer<ISpacesPagesSharedSettingsState>(getInitialState(), {
	[types.TOGGLE_ALT_NAMES_VISIBILITY]: (state, {payload}: {payload?: boolean}) => {
		state.altNamesVisible = payload === undefined ? !state.altNamesVisible : payload;
	},

	[types.TOGGLE_PENDING]: (state, {payload}: {payload?: boolean}) => {
		state.pending = payload === undefined ? !state.pending : payload;
	},

	[types.TOGGLE_SELECTION_SPACES_VISIBILITY]: (
		state,
		{payload}: {payload: {visibility: boolean}}
	) => {
		state.selectionSpacesVisible = payload.visibility;
	}
});
