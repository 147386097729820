import {IGetSpaceStatusesResponse} from '@src/api/backend/spaceStatuses';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISpaceStatus';

/**
 * Извлекает все статусы помещений в виде массива
 */
export const extractSpaceStatusesAsArray = (data: IGetSpaceStatusesResponse) =>
	data.allIds.map(id => data.byId[id]);

/**
 * Извелекает сгруппированные статусы помещений по id набора
 */
export const extractAllSpaceStatuses = (data: IGetSpaceStatusesResponse) => {
	const statuses = extractSpaceStatusesAsArray(data);
	const allStatuses: {[setId: string]: INormalizedData<ISpaceStatus>} = {};

	for (const status of statuses) {
		if (!allStatuses[status.spaceStatusesSetId]?.allIds.length) {
			allStatuses[status.spaceStatusesSetId] = {
				allIds: [],
				byId: {}
			};
		}

		allStatuses[status.spaceStatusesSetId].allIds.push(status.id);
		allStatuses[status.spaceStatusesSetId].byId[status.id] = status;
	}

	return allStatuses;
};

/**
 * Извелекает сгруппированные статусы помещений по id набора в виде массива
 */
export const extractAllSpaceStatusesAsArray = (data: IGetSpaceStatusesResponse) => {
	const statuses = extractSpaceStatusesAsArray(data);
	const allStatuses: {[setId: string]: ISpaceStatus[]} = {};

	for (const status of statuses) {
		if (!allStatuses[status.spaceStatusesSetId]?.length) {
			allStatuses[status.spaceStatusesSetId] = [];
		}

		allStatuses[status.spaceStatusesSetId].push(status);
	}

	return allStatuses;
};

/**
 * Извлекает статусы помещений для конкретного набора
 */
export const extractSpaceStatusesBySetId = (
	data: IGetSpaceStatusesResponse,
	spaceStatusesSetId: string
) => {
	const allStatuses = extractAllSpaceStatuses(data);
	let allIds: string[] = [];
	let byId: {[id: string]: ISpaceStatus} = {};

	if (allStatuses[spaceStatusesSetId]) {
		allIds = allStatuses[spaceStatusesSetId].allIds;
		byId = allStatuses[spaceStatusesSetId].byId;
	}

	const total = allIds.length;
	return {allIds, byId, total};
};

/**
 * Извлекает статусы помещений для конкретного набора в виде массива
 */
export const extractSpaceStatusesAsArrayBySetId = (
	data: IGetSpaceStatusesResponse,
	spaceStatusesSetId: string,
	stagesMap?: Record<string, IObjectStage>
) => {
	const {allIds, byId} = extractSpaceStatusesBySetId(data, spaceStatusesSetId);
	const statuses = allIds.map(id => byId[id]);
	return statuses.sort((prev, next) => {
		const prevStage = stagesMap?.[prev.stage];
		const nextStage = stagesMap?.[next.stage];
		if (prevStage && nextStage) {
			if (prevStage?.name < nextStage?.name) {
				return 1;
			}
			if (prevStage?.name > nextStage?.name) {
				return -1;
			}
		}
		return 0;
	});
};

export const extractSpaceStatusById = (data: IGetSpaceStatusesResponse, status: string) =>
	data.byId[status];
