import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IUnitIndicator} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicator';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetPaginateUnitIndicatorsResponse extends INormalizedData<IUnitIndicator> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Получает пагинированный список индикаторов помещений
 * 	@param filters  Фильтры запроса
 *  @param sort Сортировка
 *  @param offset Оффсет
 *  @param  limit Ограничение
 */
export const requestGetPaginateUnitIndicators = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) => {
	const params = {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	};

	const response = await httpRequests.withToken.post<IGetPaginateUnitIndicatorsResponse>(
		'unit-indicators/get-paginate',
		params
	);

	return response.data;
};
