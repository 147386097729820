import {memo, useCallback, useState} from 'react';
import {useAsyncFn} from 'react-use';
import {Button, Dialog, Toggle} from '@tehzor/ui-components';
import {MultiplePlansSelect} from './MultiplePlansSelect';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {usePlansAsArray} from '@src/core/hooks/queries/plans/hooks';
import {plansQueryKeys} from '@src/api/cache/plans/keys';
import {useTranslation} from 'react-i18next';
import {useGenerateSpaces} from '@src/core/hooks/mutations/spaces/useGenerateSpaces';

interface IGenerateSpacesDialogProps {
	objectId: string;
	onClose: () => void;
	isOpen: boolean;
}
export const GenerateSpacesDialog = memo(
	({objectId, onClose, isOpen}: IGenerateSpacesDialogProps) => {
		const {t} = useTranslation();
		const [selectedPlans, setSelectedPlans] = useState<string[]>([]);
		const [withSpacesPlans, setWithSpacesPlans] = useState(true);
		const {data: plans} = usePlansAsArray(objectId);

		const {mutateAsync: generateSpaces} = useGenerateSpaces(objectId);

		const handleSelectionChange = useCallback((value: string[]) => {
			setSelectedPlans(value);
		}, []);

		const handleDialogClose = () => {
			setSelectedPlans([]);
		};

		const [generationState, generate] = useAsyncFn(async () => {
			await generateSpaces({objectId, withSpacesPlans, plans: selectedPlans});
			await queryClient.invalidateQueries({queryKey: spacesQueryKeys.typesCount([objectId])});
			await queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
			await queryClient.invalidateQueries({queryKey: spacesQueryKeys.listWithStats()});
			await queryClient.invalidateQueries({queryKey: plansQueryKeys.list([objectId])});
			onClose();
			setSelectedPlans([]);
			setWithSpacesPlans(true);
		}, [selectedPlans, objectId, withSpacesPlans]);

		return (
			<Dialog
				className="object-page__generate-spaces-dialog"
				isOpen={isOpen}
				title={t('objectPage.generateSpacesDialog.title')}
				disableScrollBlock
				onRequestClose={onClose}
				onBeforeClose={handleDialogClose}
				fullScreenOnMobile
				footer={
					<div className="object-page__generate-spaces-dialog-button_container">
						<Button
							disabled={generationState.loading || selectedPlans.length === 0}
							type="accent-blue"
							label={t('objectPage.generateSpacesDialog.planSelect.generate')}
							onClick={generate}
							className="object-page__generate-spaces-dialog-submit_button"
						/>
					</div>
				}
			>
				<MultiplePlansSelect
					label={t('objectPage.generateSpacesDialog.planSelect.title')}
					optionsIds={selectedPlans}
					options={plans}
					disabled={generationState.loading}
					onChangeFilter={handleSelectionChange}
					className="object-page__generate-spaces-dialog-select"
					popupClassName="object-page__generate-spaces-dialog-select-popup"
				/>
				{selectedPlans.length ? (
					<div className="object-page__generate-spaces-dialog-toggle_container">
						<div className="object-page__generate-spaces-dialog-toggle">
							<Toggle
								checked={withSpacesPlans}
								onChange={() => {
									setWithSpacesPlans(!withSpacesPlans);
								}}
							/>
						</div>
						<p className="object-page__generate-spaces-dialog-toggle-description">
							{t('objectPage.generateSpacesDialog.planSelect.clippingPlans')}
						</p>
					</div>
				) : null}
			</Dialog>
		);
	}
);
