import {useState, useCallback, useMemo, memo} from 'react';
import {InlineButton, SpaceIndicatorTag, SpaceIndicatorsSet} from '@tehzor/ui-components';
import {IUnitIndicator} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicator';
import {IUnitIndicatorsSetWithIndicators} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicatorsSet';
import {useTranslation} from 'react-i18next';
import './IndicatorsSets.less';
import {IndicatorsDialog} from '../IndicatorsDialog';

interface IIndicatorsSetsProps {
	className?: string;
	indicatorsSets: IUnitIndicatorsSetWithIndicators[];
	value: string[];
	mobile?: boolean;
}

const SETS_COUNT = 2;
const MOBILE_SETS_COUNT = 1;
const INDICATORS_COUNT = 3;

export const IndicatorsSets = memo((props: IIndicatorsSetsProps) => {
	const {className, indicatorsSets, value, mobile = false} = props;
	const {t} = useTranslation();
	const [isModalOpen, setModalOpen] = useState(false);
	const [expandedSets, setExpandedSets] = useState<Record<string, boolean>>({});

	const openModal = useCallback(() => setModalOpen(true), []);
	const closeModal = useCallback(() => setModalOpen(false), []);

	const toggleSetExpansion = useCallback((setId: string) => {
		setExpandedSets(prev => ({
			...prev,
			[setId]: !prev[setId]
		}));
	}, []);

	const filteredIndicatorsSets = useMemo(
		() =>
			indicatorsSets
				.map(set => ({
					...set,
					indicators: set.indicators.filter(indicator => value.includes(indicator.id))
				}))
				.filter(set => set.indicators.length > 0),
		[indicatorsSets, value]
	);

	const totalCount = filteredIndicatorsSets.length;

	const showButton = useMemo(
		() => totalCount > (mobile ? MOBILE_SETS_COUNT : SETS_COUNT),
		[totalCount, mobile]
	);

	const showTagsCountLeft = useCallback((indicatorsInSetArray: IUnitIndicator[]) => {
		const spaceIndicatorsInThisSetCount = indicatorsInSetArray.length;
		if (spaceIndicatorsInThisSetCount > INDICATORS_COUNT) {
			return `+${spaceIndicatorsInThisSetCount - INDICATORS_COUNT}`;
		}
		return null;
	}, []);

	if (!filteredIndicatorsSets.length) return null;

	return (
		<>
			{filteredIndicatorsSets.slice(0, mobile ? MOBILE_SETS_COUNT : SETS_COUNT).map(set => (
				<SpaceIndicatorsSet
					key={set.id}
					set={set}
					className="indicators-grid-item"
				>
					{set.indicators
						.slice(0, expandedSets[set.id] ? set.indicators.length : INDICATORS_COUNT)
						.map(indicator => (
							<SpaceIndicatorTag
								key={indicator.id}
								color={indicator.color}
								label={indicator.name}
							/>
						))}
					{set.indicators.length > INDICATORS_COUNT && (
						<InlineButton
							type={mobile ? 'filled' : 'accent'}
							className={
								mobile ? 'indicator-set__inline-button_filled--narrow' : undefined
							}
							label={
								expandedSets[set.id]
									? t('spacePage.entitiesTabs.all.hide')
									: showTagsCountLeft(set.indicators)
							}
							onClick={() => toggleSetExpansion(set.id)}
						/>
					)}
				</SpaceIndicatorsSet>
			))}

			{showButton && (
				<InlineButton
					type="accent"
					label={`${t('spacePage.entitiesTabs.all.title')} (${totalCount})`}
					onClick={openModal}
				/>
			)}

			{isModalOpen && (
				<IndicatorsDialog
					isModalOpen={isModalOpen}
					closeModal={closeModal}
					indicatorsSets={filteredIndicatorsSets}
					mobile={mobile}
					className={className}
					title={
						mobile
							? t('spacePage.info.indicators')
							: t('spacePage.info.indicatorsSelect.title')
					}
				/>
			)}
		</>
	);
});
