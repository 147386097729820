import {createReducer} from '@reduxjs/toolkit';
import * as types from '../constants';
import {IChangeFiltersPayload} from '@src/store/interfaces/IChangeFiltersPayload';
import {IClearFiltersPayload} from '@src/store/interfaces/IClearFiltersPayload';
import {IChangeSortPayload} from '@src/store/interfaces/IChangeSortPayload';
import {IChangePageSizePayload} from '@src/store/interfaces/IChangePageSizePayload';
import {IChangeFloorSortPayload} from '@src/store/interfaces/IChangeFloorSortPayload';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import {IChangeSpacesDisplayModePayload} from '../actions';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IChangeSchemaOffsetPayload} from '@src/store/interfaces/IChangeSchemaOffsetPayload';
import {IChangeSchemaPageSizePayload} from '@src/store/interfaces/IChangeSchemaPageSizePayload';
import {IChangeListOffsetPayload} from '@src/store/interfaces/IChangeListOffsetPayload';
import * as spaceTypes from '@src/store/modules/entities/space/constants';
import * as problemTypes from '@src/store/modules/pages/problem/constants';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';

export interface ISpacesFiltersState extends Record<string, unknown> {
	ids?: string[];
	objects?: string[];
	statuses?: string[];
	problemStatuses?: string[];
	workAcceptanceStatuses?: string[];
	indicators?: string[];
	types?: string[];
	stages?: string[];
	checkListIds?: string[];
	typeDecoration?: string[];
	checkListStatuses?: string[];
	checkListCategoryStatuses?: string[];
	checkListCategory?: string[];
}

export type ISpacesSortState = Record<string, boolean>;

export interface ISpacesPageSettingsState {
	// Способ отображения помещений
	displayMode: SpacesDisplayMode;
	// Вариант шахматки (нарушения, чек-листы)
	schemaView: SpacesSchemaVariants;
	// Стадия
	stage?: ObjectStageIds;
	// Процесс
	processId?: ProcessIds;
	// Тип помещения для шахматки
	type?: string;
	// Фильтры
	filters: ISpacesFiltersState;
	// Сортировка
	sort: ISpacesSortState;
	// Количество отображаемых элементов в таблице
	pageSize: number;
	// Сортировка этажей на шахматке
	reverseFloorSort: boolean;
	// Количество отображаемых объектов на странице шахматки помещений
	schemaPageSize: number;
	// Смещение отображаемых объектов на странице шахматки помещений
	schemaOffset: number;
	// Смещение отображаемых помещений на странице списка помещений
	listOffset: number;
	// Список id всех выбранных помещений
	selected: string[];
	// Дочерние объекты выбранных помещений c выбранными объектами
	selectedSubObjects: Record<string, Array<Record<string, string>>>;
	// количество всех помещений в списке
	total: number;
	// Отображаемый объект в пагинации мобильной версии
	currentObject?: string;
	// Отображение режима для множественного выбора помещений
	chooseSpacesVisible?: boolean;
}

export type ISpacesPagesSettingsState = Record<string, ISpacesPageSettingsState>;

export const getInitialStateForPage = (): ISpacesPageSettingsState => ({
	displayMode: SpacesDisplayMode.SCHEMA,
	schemaView: SpacesSchemaVariants.PROBLEMS,
	type: undefined,
	filters: {},
	sort: {name: true},
	pageSize: 20,
	reverseFloorSort: false,
	schemaPageSize: 5,
	schemaOffset: 0,
	listOffset: 0,
	selected: [],
	selectedSubObjects: {},
	total: 0
});

const checkPageExistence = (state: ISpacesPagesSettingsState, objectId: string) => {
	if (!state.hasOwnProperty(objectId)) {
		state[objectId] = getInitialStateForPage();
	}
};

const clearPage = (state: ISpacesPagesSettingsState, {payload}: {payload: {objectId: string}}) => {
	if (state[payload.objectId]) {
		state[payload.objectId].listOffset = 0;
		state[payload.objectId].total = 0;
	}
	if (state.all) {
		state.all.listOffset = 0;
		state.all.total = 0;
	}
};

export default createReducer<ISpacesPagesSettingsState>(
	{},
	{
		[types.GET_SCHEMA_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			checkPageExistence(state, payload.objectId);
		},
		[types.GET_LIST_REQUEST]: (state, {payload}: {payload: {objectId: string}}) => {
			checkPageExistence(state, payload.objectId);
		},
		[types.CHANGE_DISPLAY_MODE]: (
			state,
			{payload}: {payload: IChangeSpacesDisplayModePayload}
		) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].displayMode = payload.displayMode;
		},
		[types.CHANGE_SCHEMA_VIEW]: (
			state,
			{payload}: {payload: {objectId: string; schemaView: SpacesSchemaVariants}}
		) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].schemaView = payload.schemaView;
		},
		[types.CHANGE_STAGE]: (
			state,
			{payload}: {payload: {objectId: string; stage: ObjectStageIds | 'all'}}
		) => {
			checkPageExistence(state, payload.objectId);
			if (payload.stage === 'all') {
				state[payload.objectId].stage = undefined;
			} else {
				state[payload.objectId].stage = payload.stage;
			}
		},
		[types.CHANGE_PROCESS]: (
			state,
			{payload}: {payload: {objectId: string; processId: ProcessIds | 'all'}}
		) => {
			checkPageExistence(state, payload.objectId);
			if (payload.processId === 'all') {
				state[payload.objectId].processId = undefined;
			} else {
				state[payload.objectId].processId = payload.processId;
			}
		},
		[types.CHANGE_TYPE]: (state, {payload}: {payload: {objectId: string; type: string}}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].schemaOffset = 0;
			state[payload.objectId].type = payload.type;
		},
		[types.CHANGE_FILTERS]: (
			state,
			{payload}: {payload: IChangeFiltersPayload<ISpacesFiltersState>}
		) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].filters = payload.filters;
			state[payload.objectId].listOffset = 0;
			state[payload.objectId].total = 0;

			const page = state[payload.objectId];
			if (page) {
				if (
					payload.filters.objects?.length &&
					payload.filters.objects.every(id => id !== page.currentObject)
				) {
					page.currentObject = payload.filters.objects[0];
				}
			}
		},
		[types.CLEAR_FILTERS]: (state, {payload}: {payload: IClearFiltersPayload}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].filters = {} as ISpacesFiltersState;
			state[payload.objectId].listOffset = 0;
			state[payload.objectId].total = 0;
		},
		[types.CHANGE_SORT]: (
			state,
			{payload}: {payload: IChangeSortPayload<ISpacesSortState>}
		) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].sort = payload.sort;
		},
		[types.CHANGE_PAGE_SIZE]: (state, {payload}: {payload: IChangePageSizePayload}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].pageSize = payload.pageSize;
		},
		[types.CHANGE_FLOOR_SORT]: (state, {payload}: {payload: IChangeFloorSortPayload}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].reverseFloorSort = !state[payload.objectId].reverseFloorSort;
		},
		[types.CHANGE_SCHEMA_PAGE_SIZE]: (
			state,
			{payload}: {payload: IChangeSchemaPageSizePayload}
		) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].schemaPageSize = payload.schemaPageSize;
		},
		[types.CHANGE_SCHEMA_OFFSET]: (state, {payload}: {payload: IChangeSchemaOffsetPayload}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].schemaOffset = payload.schemaOffset;
		},
		[types.CHANGE_LIST_OFFSET]: (state, {payload}: {payload: IChangeListOffsetPayload}) => {
			checkPageExistence(state, payload.objectId);
			state[payload.objectId].listOffset = payload.listOffset;
		},

		// * Выбор помещений (присваивание value с определенным количеством (с списка))
		[types.SELECT_SPACES_BY_VALUE]: (
			state,
			{
				payload
			}: {
				payload: {
					objectId: string;
					value: string[];
					valueForSubObjects: Record<string, Array<Record<string, string>>>;
				};
			}
		) => {
			checkPageExistence(state, payload.objectId);

			state[payload.objectId].selected = payload.value;
			state[payload.objectId].selectedSubObjects = payload.valueForSubObjects;
		},

		// * Одиночный выбор помещений
		[types.SELECT_SPACES_BY_ONE]: (
			state,
			{
				payload
			}: {
				payload: {
					objectId: string;
					subObjectId: string;
					spaceId: string;
					spaceType: string;
				};
			}
		) => {
			checkPageExistence(state, payload.objectId);

			// Работа с selected (обновление общего списка выбранных помещений)
			const selectedSpaces = state[payload.objectId].selected || [];
			const spaceIndex = selectedSpaces.indexOf(payload.spaceId);

			if (spaceIndex > -1) {
				// Если помещение уже выбрано, удаляем его
				selectedSpaces.splice(spaceIndex, 1);
			} else {
				// Если помещение не выбрано, добавляем его
				selectedSpaces.push(payload.spaceId);
			}
			state[payload.objectId].selected = [...selectedSpaces];

			// Работа с selectedSubObjects (обновление по subObjectId)
			const subObjectSpaces =
				state[payload.objectId].selectedSubObjects[payload.subObjectId] || [];
			const subObjectSpaceIndex = subObjectSpaces.findIndex(
				space => space.id === payload.spaceId
			);

			if (subObjectSpaceIndex > -1) {
				// Если помещение уже выбрано в subObject, удаляем его
				subObjectSpaces.splice(subObjectSpaceIndex, 1);

				// Если после удаления subObjectSpaces пустой, удаляем запись subObjectId
				if (subObjectSpaces.length === 0) {
					delete state[payload.objectId].selectedSubObjects[payload.subObjectId];
				} else {
					state[payload.objectId].selectedSubObjects[payload.subObjectId] = [
						...subObjectSpaces
					];
				}
			} else {
				// Если помещение не выбрано в subObject, добавляем его как объект { id, type }
				state[payload.objectId].selectedSubObjects[payload.subObjectId] = [
					...subObjectSpaces,
					{id: payload.spaceId, type: payload.spaceType}
				];
			}
		},

		// * Множественный выбор помещений с шахматки (при клике на чекбокс)
		[types.SELECT_SPACES_MULTIPLE]: (
			state,
			{
				payload
			}: {
				payload: {
					objectId: string;
					subObjectId: string;
					spaces: string[];
					spacesType: string;
				};
			}
		) => {
			checkPageExistence(state, payload.objectId);

			const pageSettings = state[payload.objectId];
			const currentSelected = pageSettings.selected || [];
			const spacesToToggle = payload.spaces;

			// Логика для обновления `selected`
			const allSelected = spacesToToggle.every(space => currentSelected.includes(space));
			if (allSelected) {
				// Убираем выбранные помещения из общего selected
				pageSettings.selected = currentSelected.filter(
					space => !spacesToToggle.includes(space)
				);
			} else {
				// Добавляем новые помещения в общий selected
				const newSelected = Array.from(new Set([...currentSelected, ...spacesToToggle]));
				pageSettings.selected = newSelected;
			}

			// Логика для обновления `selectedSubObjects`
			if (!pageSettings.selectedSubObjects) {
				pageSettings.selectedSubObjects = {};
			}

			// Обновляем подмассив в `selectedSubObjects` для конкретного subObjectId
			if (!pageSettings.selectedSubObjects[payload.subObjectId]) {
				pageSettings.selectedSubObjects[payload.subObjectId] = [];
			}

			const currentSubObjectSelected = pageSettings.selectedSubObjects[payload.subObjectId];

			if (allSelected) {
				// Убираем выбранные помещения из `selectedSubObjects` для данного subObjectId
				pageSettings.selectedSubObjects[payload.subObjectId] =
					currentSubObjectSelected.filter(space => !spacesToToggle.includes(space.id));
			} else {
				// Фильтруем `spacesToToggle`, чтобы исключить уже существующие помещения
				const newSpaces = spacesToToggle
					.filter(
						spaceId => !currentSubObjectSelected.some(space => space.id === spaceId)
					)
					.map(spaceId => ({id: spaceId, type: payload.spacesType}));

				// Добавляем новые помещения в `selectedSubObjects` для данного subObjectId
				pageSettings.selectedSubObjects[payload.subObjectId] = [
					...currentSubObjectSelected,
					...newSpaces
				];
			}
		},

		// Выбор всех помещений всех типов у объекта (включая дочерние)
		[types.SELECT_SPACES_ALL]: (
			state,
			{
				payload
			}: {
				payload: {
					objectId: string;
					subObjectsWithSpaces: Record<string, IListSpace[]>;
				};
			}
		) => {
			checkPageExistence(state, payload.objectId);

			const pageSettings = state[payload.objectId];
			const {selected, selectedSubObjects} = pageSettings;

			if (!pageSettings.selectedSubObjects) {
				pageSettings.selectedSubObjects = {};
			}

			let shouldUpdate = false;
			const newSelectedSubObjects: Record<string, Array<{id: string; type: string}>> = {};

			// Проверяем, нужно ли перезаписывать данные
			Object.entries(payload.subObjectsWithSpaces).forEach(([subObjectId, spaces]) => {
				const existingSpaces = selectedSubObjects[subObjectId] || [];
				if (existingSpaces.length !== spaces.length) {
					shouldUpdate = true;
				} else {
					const hasMismatch = spaces.some(
						space =>
							!existingSpaces.some(
								selectedSpace =>
									selectedSpace.id === space.id &&
									selectedSpace.type === space.type
							)
					);
					if (hasMismatch) {
						shouldUpdate = true;
					}
				}

				if (shouldUpdate) {
					newSelectedSubObjects[subObjectId] = spaces.map(space => ({
						id: space.id,
						type: space.type
					}));
				}
			});

			if (shouldUpdate) {
				// Перезаписываем `selectedSubObjects` и добавляем новые помещения в `selected`
				Object.assign(pageSettings.selectedSubObjects, newSelectedSubObjects);
				const allSpacesIds = Object.values(payload.subObjectsWithSpaces)
					.flat()
					.map(space => space.id);
				pageSettings.selected = Array.from(new Set([...selected, ...allSpacesIds]));
			} else {
				// Убираем все переданные `subObjectsWithSpaces` из `selectedSubObjects` и `selected`
				Object.keys(payload.subObjectsWithSpaces).forEach(subObjectId => {
					delete pageSettings.selectedSubObjects[subObjectId];
				});

				const allSpacesIdsToRemove = Object.values(payload.subObjectsWithSpaces)
					.flat()
					.map(space => space.id);

				pageSettings.selected = selected.filter(id => !allSpacesIdsToRemove.includes(id));
			}
		},

		// Выбор всех помещений всех типов у объекта (включая дочерние)
		[types.SELECT_SPACES_ALL_VISIBLE]: (
			state,
			{
				payload
			}: {
				payload: {
					objectId: string;
					subObjectsWithSpaces: Record<string, IConvertedSpace[]>; // Ключи - id объектов, значения - массивы помещений
				};
			}
		) => {
			checkPageExistence(state, payload.objectId);

			const pageSettings = state[payload.objectId];
			const {selected, selectedSubObjects} = pageSettings;

			if (!pageSettings.selectedSubObjects) {
				pageSettings.selectedSubObjects = {};
			}

			const allSpacesToRemove: string[] = []; // Список всех ID помещений, которые нужно удалить
			let shouldAdd = false; // Флаг для добавления недостающих помещений

			// Обновляем `selectedSubObjects`
			Object.entries(payload.subObjectsWithSpaces).forEach(([subObjectId, spaces]) => {
				const currentSelectedSpaces = selectedSubObjects[subObjectId] || [];

				// Фильтруем текущие помещения по переданному типу
				const currentSpacesOfType = currentSelectedSpaces.filter(
					selectedSpace => selectedSpace.type === spaces[0]?.type
				);

				// Сравниваем количество помещений с типом
				const spacesToAdd = spaces.filter(
					space =>
						!currentSpacesOfType.some(selectedSpace => selectedSpace.id === space.id)
				);

				if (currentSpacesOfType.length < spaces.length) {
					// Недостающие помещения – записываем их
					shouldAdd = true;
					if (!selectedSubObjects[subObjectId]) {
						selectedSubObjects[subObjectId] = [];
					}
					selectedSubObjects[subObjectId] = Array.from(
						new Set([
							...currentSelectedSpaces,
							...spacesToAdd.map(space => ({id: space.id, type: space.type}))
						])
					);
				} else if (currentSpacesOfType.length === spaces.length) {
					// Все совпадает – собираем ID для удаления
					allSpacesToRemove.push(...spaces.map(space => space.id));
				}
			});

			if (shouldAdd) {
				// Добавляем недостающие помещения в `selected`
				const newSpacesToAdd = Object.values(payload.subObjectsWithSpaces)
					.flat()
					.map(space => space.id);

				pageSettings.selected = Array.from(new Set([...selected, ...newSpacesToAdd]));
			} else {
				// Удаляем помещения из `selectedSubObjects` и `selected`
				Object.keys(payload.subObjectsWithSpaces).forEach(subObjectId => {
					const spacesToRemove = payload.subObjectsWithSpaces[subObjectId];
					selectedSubObjects[subObjectId] = selectedSubObjects[subObjectId]?.filter(
						space => !spacesToRemove.some(removeSpace => removeSpace.id === space.id)
					);

					// Если массив пустой – удаляем ключ
					if (!selectedSubObjects[subObjectId]?.length) {
						delete selectedSubObjects[subObjectId];
					}
				});

				pageSettings.selected = selected.filter(id => !allSpacesToRemove.includes(id));
			}
		},
		//* Сбрасывает выборку всех помещений
		[types.DESELECT_SPACES_ALL]: (state, {payload}: {payload: {objectId: string}}) => {
			checkPageExistence(state, payload.objectId);
			const pageSettings = state[payload.objectId];
			pageSettings.selected = [];
			pageSettings.selectedSubObjects = {};
		},

		[types.CHANGE_CURRENT_OBJECT]: (
			state,
			{payload}: {payload: {objectId: string; id: string}}
		) => {
			if (!state.hasOwnProperty(payload.objectId)) {
				state[payload.objectId] = getInitialStateForPage();
			}
			state[payload.objectId].currentObject = payload.id;
		},

		[problemTypes.ADD_SUCCESS]: clearPage,
		[problemTypes.EDIT_SUCCESS]: clearPage,
		[problemTypes.EDIT_STATUS_SUCCESS]: clearPage,
		[problemTypes.DELETE_SUCCESS]: clearPage,
		[spaceTypes.ADD_SUCCESS]: clearPage
	}
);
