import SocketConnector from '@src/api/SocketConnector';
import {useCallback, useEffect} from 'react';
import {queryClient} from '@src/api/QueryClient';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {IMultipleChecksCreationFailed} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/checks/IMultipleChecksCreationFailed';
import {IMultipleChecksCreationFinished} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/checks/IMultipleChecksCreationFinished';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import declination from '@tehzor/tools/utils/declination';
import {
	deselectAllSpaces,
	selectSpaces,
	toggleSelectionSpacesVisibility
} from '@src/store/modules/settings/pages/spaces/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export const useMultipleChecksCreationEventHandler = () => {
	const socketConnector = SocketConnector.instance;

	const dispatch = useAppDispatch();
	const {objectId: parentObjectId} = useStrictParams();

	const handleFinishedChecksCreation = useCallback(
		(message: IMultipleChecksCreationFinished) => {
			dispatch(deselectAllSpaces(parentObjectId as string));
			dispatch(toggleSelectionSpacesVisibility(false));

			addSuccessToast({
				title: `Добавлено ${message.data?.length} ${declination(message.data?.length, [
					'проверка',
					'проверки',
					'проверок'
				])}`
			});
			if (message?.data) {
				void queryClient.invalidateQueries({
					queryKey: checksQueryKeys.list()
				});
				void queryClient.invalidateQueries({queryKey: spacesQueryKeys.checks()});
			}
		},
		[dispatch, parentObjectId]
	);
	const handleFailedChecksCreation = useCallback(
		(message: IMultipleChecksCreationFailed) => {
			const {objectId, data, spacesType} = message;
			const convertedData = data.map(spaceId => ({id: spaceId, type: spacesType}));
			dispatch(
				selectSpaces(parentObjectId as string, [...data], {[objectId]: convertedData})
			);
			addErrorToast({
				title: 'Ошибка при создании проверок'
			});
		},
		[dispatch, parentObjectId]
	);

	useEffect(() => {
		socketConnector.subscribeOnMultipleChecksCreationEvents(
			handleFinishedChecksCreation,
			handleFailedChecksCreation
		);
		return () => socketConnector.unsubscribeOnMultipleChecksCreationEvents();
	}, [handleFinishedChecksCreation, handleFailedChecksCreation, socketConnector]);
};
