import {queryClient} from '@src/api/QueryClient';
import {requestAddSpaceStatus} from '@src/api/backend/spaceStatuses';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useMutation} from '@tanstack/react-query';
import {ISavingSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISavingSpaceStatus';

export const useAddSpaceStatus = () =>
	useMutation({
		mutationFn: (params: ISavingSpaceStatus) => requestAddSpaceStatus(params),
		mutationKey: spaceStatusesQueryKeys.add(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceStatusesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.listWithStats()});
		}
	});
