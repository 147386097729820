import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IUnitIndicator} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicator';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetListUnitIndicatorsResponse extends INormalizedData<IUnitIndicator> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Получает список индикаторов помещений
 * 	@param filters  Фильтры запроса
 */
export const requestGetListUnitIndicators = async (filters?: Record<string, unknown>) => {
	const params = {
		filters,
		sort: convertSortParam({order: true}),
		offset: 0,
		limit: 100000
	};

	const response = await httpRequests.withToken.post<IGetListUnitIndicatorsResponse>(
		'unit-indicators/get-paginate',
		params
	);

	return response.data;
};
