import {ReactNode, createContext} from 'react';
import {IHeaderSectionsMenuItem} from '@src/interfaces/IHeaderSectionsMenuItem';

export interface AppHeaderCtxOptions {
	title?: string;
	subTitle?: string;
	showBackBtn?: boolean;
	sectionsMenu?: IHeaderSectionsMenuItem[];
	mobileLeftButton?: ReactNode;
	mobileRightButtons?: ReactNode;
	titleButtons?: ReactNode;
}

export type AppHeaderCtxType = (options?: AppHeaderCtxOptions) => void;

export const AppHeaderCtx = createContext<AppHeaderCtxType>(() => null);
