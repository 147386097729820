import {forwardRef, Ref, Dispatch, SetStateAction} from 'react';
import './AddingInternalAcceptanceDialog.less';
import AddingProblem, {IAddingProblemRefProps} from '../AddingProblem';
import AddingInspection, {IAddingInspectionRefProps} from '../AddingInspection';
import {EntityTypeSelect} from './components/EntityTypeSelect';
import {IInternalAcceptance} from '@tehzor/tools/interfaces/internalAcceptances/IInternalAcceptance';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IInternalAcceptanceAddingEntityType} from '@src/store/modules/settings/internalAcceptanceAdding/reducers/entityType';
import {useEntityType} from './hooks/useEntityType';
import {useAvailableTypes} from './hooks/useAvailableTypes';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {useSpaceLocation} from '@src/components/AddingInternalAcceptanceDialog/hooks/useSpaceLocation';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

interface IAddingInternalAcceptanceDialogProps {
	objectId: string;
	links?: IInternalAcceptance['links'];
	stage: ObjectStageIds;
	processId: ProcessIds;
	scope?: string;
	types?: IInternalAcceptanceAddingEntityType[];
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	selectedInternalAcceptanceId?: string;

	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;

	onClose: () => void;
	isMultiple?: boolean;
}

const AddingInternalAcceptanceGlobal = (
	props: IAddingInternalAcceptanceDialogProps,
	ref?: Ref<IAddingProblemRefProps | IAddingInspectionRefProps>
) => {
	const {
		objectId,
		links,
		stage,
		processId,
		scope,
		types,
		selectedInternalAcceptanceId,
		saving,
		setSaving,
		onClose,
		isMultiple
	} = props;

	const availableTypes = useAvailableTypes(objectId, types);
	const type = useEntityType(objectId, availableTypes);
	const [defaultProblemData, defaultInspectionData] = useSpaceLocation(
		objectId,
		selectedInternalAcceptanceId,
		props.defaultProblemData,
		props.defaultInspectionData
	);

	return (
		<div>
			<div className="adding-internal-acceptance-dialog__header adding-internal-acceptance-dialog__header_global">
				{type !== undefined && (
					<EntityTypeSelect
						type={type}
						types={availableTypes}
						disabled={saving}
					/>
				)}
			</div>
			{type === 'problem' ? (
				<AddingProblem
					objectId={objectId}
					links={links}
					stage={stage}
					processId={processId}
					scope={scope}
					defaultData={defaultProblemData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={ref}
					isMultiple={isMultiple}
				/>
			) : type === 'inspection' ? (
				<AddingInspection
					objectId={objectId}
					links={links}
					stage={stage}
					processId={processId}
					defaultData={defaultInspectionData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={ref}
					isMultiple={isMultiple}
				/>
			) : null}
		</div>
	);
};

export default forwardRef<
	IAddingProblemRefProps | IAddingInspectionRefProps,
	IAddingInternalAcceptanceDialogProps
>(AddingInternalAcceptanceGlobal);
