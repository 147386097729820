import {PrivateRoute} from '@src/containers/PrivateRoute';
import {ExportTemplateEditingPage} from '@src/pages/manage/ExportTemplateEditingPage';
import {ExportTemplatesPageWrap} from '@src/pages/manage/ExportTemplatesPage/ExportTemplatesPageWrap';

export const manageExportTemplatesRoutes = [
	{
		index: true,
		element: <PrivateRoute element={<ExportTemplatesPageWrap />} />
	},
	{
		path: 'add',
		element: <PrivateRoute element={<ExportTemplateEditingPage />} />
	},
	{
		path: ':exportTemplateId',
		element: <PrivateRoute element={<ExportTemplateEditingPage />} />
	}
];
