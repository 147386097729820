/* eslint-disable class-methods-use-this */
/* eslint-disable no-shadow */
import BaseSocketConnector from '@tehzor/tools/api/BaseSocketConnector';
import {ExportTaskEvents} from '@tehzor/tools/interfaces/export/ExportTaskEvents';
import {IExportTasksFailed} from '@tehzor/tools/interfaces/export/IExportTasksFailed';
import {IExportTasksFinished} from '@tehzor/tools/interfaces/export/IExportTasksFinished';

// Множественное создание проверок
import {MultipleChecksCreationEvents} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/checks/MultipleChecksCreationEvents';
import {IMultipleChecksCreationFinished} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/checks/IMultipleChecksCreationFinished';
import {IMultipleChecksCreationFailed} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/checks/IMultipleChecksCreationFailed';

// Множественное создание внутренних приемок
import {MultipleInternalAcceptancesCreationEvents} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/internalAcceptances/MultipleInternalAcceptancesCreationEvents';
import {IMultipleInternalAcceptancesCreationFinished} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/internalAcceptances/IMultipleInternalAcceptancesCreationFinished';
import {IMultipleInternalAcceptancesCreationFailed} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/internalAcceptances/IMultipleInternalAcceptancesCreationFailed';

// Множественное создание предпродажных проверок
import {MultiplePresaleChecksCreationEvents} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/presaleChecks/MultiplePresaleChecksCreationEvents';
import {IMultiplePresaleChecksCreationFinished} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/presaleChecks/IMultiplePresaleChecksCreationFinished';
import {IMultiplePresaleChecksCreationFailed} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/presaleChecks/IMultiplePresaleChecksCreationFailed';

// Множественное создание передач собственникам
import {MultipleOwnerAcceptancesCreationEvents} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/ownerAcceptances/MultipleOwnerAcceptancesCreationEvents';
import {IMultipleOwnerAcceptancesCreationFinished} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/ownerAcceptances/IMultipleOwnerAcceptancesCreationFinished';
import {IMultipleOwnerAcceptancesCreationFailed} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/ownerAcceptances/IMultipleOwnerAcceptancesCreationFailed';

// Множественное создание гарантийных обращений
import {MultipleWarrantyClaimsCreationEvents} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/warrantyClaims/MultipleWarrantyClaimsCreationEvents';
import {IMultipleWarrantyClaimsCreationFinished} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/warrantyClaims/IMultipleWarrantyClaimsCreationFinished';
import {IMultipleWarrantyClaimsCreationFailed} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/warrantyClaims/IMultipleWarrantyClaimsCreationFailed';

const singleton: unique symbol = Symbol('singleton');
const singletonEnforcer: unique symbol = Symbol('singletonEnforcer');

/**
 * Singleton класс для взаимодействия с backend'ом
 */
class SocketConnector extends BaseSocketConnector {
	private static [singleton]: SocketConnector;

	constructor(enforcer: symbol) {
		super();
		if (enforcer !== singletonEnforcer) {
			throw new Error("Cannot construct 'SocketConnector' class");
		}
	}

	/**
	 * Возвращает единственный экземпляр класса SocketConnector
	 *
	 * @returns {SocketConnector}
	 */
	static get instance(): SocketConnector {
		if (!this[singleton]) {
			this[singleton] = new SocketConnector(singletonEnforcer);
		}
		return this[singleton];
	}

	// Метод для подписки на сообщения об экспорте
	subscribeOnExportEvents(
		onSuccessEvent: (event: IExportTasksFinished) => void,
		onErrorEvent: (event: IExportTasksFailed) => void
	) {
		this._subscribe(ExportTaskEvents.EXPORT_TASKS_FINISHED, onSuccessEvent, true);
		this._subscribe(ExportTaskEvents.EXPORT_TASKS_FAILED, onErrorEvent, true);
	}

	// Метод для отписки от сообщений об экспорте
	unsubscribeOnExportEvents() {
		this._unsubscribe(ExportTaskEvents.EXPORT_TASKS_FINISHED);
		this._unsubscribe(ExportTaskEvents.EXPORT_TASKS_FAILED);
	}

	//! Множественное создание проверок
	// Метод для подписки на сообщения об успешном/неуспешном множественном создании проверок
	subscribeOnMultipleChecksCreationEvents(
		onSuccessEvent: (event: IMultipleChecksCreationFinished) => void,
		onErrorEvent: (event: IMultipleChecksCreationFailed) => void
	) {
		this._subscribe(
			MultipleChecksCreationEvents.CREATE_MULTIPLE_CHECKS_FINISHED,
			onSuccessEvent,
			true
		);
		this._subscribe(
			MultipleChecksCreationEvents.CREATE_MULTIPLE_CHECKS_FAILED,
			onErrorEvent,
			true
		);
	}

	// Метод для отписки от сообщений об успешном/неуспешном множественном создании проверок
	unsubscribeOnMultipleChecksCreationEvents() {
		this._unsubscribe(MultipleChecksCreationEvents.CREATE_MULTIPLE_CHECKS_FINISHED);
		this._unsubscribe(MultipleChecksCreationEvents.CREATE_MULTIPLE_CHECKS_FAILED);
	}

	//! Множественное создание внутренних приемок
	// Метод для подписки на сообщения об успешном/неуспешном множественном создании внутренних приемок
	subscribeOnMultipleInternalAcceptancesCreationEvents(
		onSuccessEvent: (event: IMultipleInternalAcceptancesCreationFinished) => void,
		onErrorEvent: (event: IMultipleInternalAcceptancesCreationFailed) => void
	) {
		this._subscribe(
			MultipleInternalAcceptancesCreationEvents.CREATE_MULTIPLE_INTERNAL_ACCEPTANCES_FINISHED,
			onSuccessEvent,
			true
		);
		this._subscribe(
			MultipleInternalAcceptancesCreationEvents.CREATE_MULTIPLE_INTERNAL_ACCEPTANCES_FAILED,
			onErrorEvent,
			true
		);
	}

	// Метод для отписки от сообщений об успешном/неуспешном множественном создании внутренних приемок
	unsubscribeOnMultipleInternalAcceptancesCreationEvents() {
		this._unsubscribe(
			MultipleInternalAcceptancesCreationEvents.CREATE_MULTIPLE_INTERNAL_ACCEPTANCES_FINISHED
		);
		this._unsubscribe(
			MultipleInternalAcceptancesCreationEvents.CREATE_MULTIPLE_INTERNAL_ACCEPTANCES_FAILED
		);
	}

	//! Множественное создание предпродажных проверок
	// Метод для подписки на сообщения об успешном/неуспешном множественном создании предпродажных проверок
	subscribeOnMultiplePresaleChecksCreationEvents(
		onSuccessEvent: (event: IMultiplePresaleChecksCreationFinished) => void,
		onErrorEvent: (event: IMultiplePresaleChecksCreationFailed) => void
	) {
		this._subscribe(
			MultiplePresaleChecksCreationEvents.CREATE_MULTIPLE_PRESALE_CHECKS_FINISHED,
			onSuccessEvent,
			true
		);
		this._subscribe(
			MultiplePresaleChecksCreationEvents.CREATE_MULTIPLE_PRESALE_CHECKS_FAILED,
			onErrorEvent,
			true
		);
	}

	// Метод для отписки от сообщений об успешном/неуспешном множественном создании предпродажных проверок
	unsubscribeOnMultiplePresaleChecksCreationEvents() {
		this._unsubscribe(
			MultiplePresaleChecksCreationEvents.CREATE_MULTIPLE_PRESALE_CHECKS_FINISHED
		);
		this._unsubscribe(
			MultiplePresaleChecksCreationEvents.CREATE_MULTIPLE_PRESALE_CHECKS_FAILED
		);
	}

	//! Множественное создание передач собственникам
	// Метод для подписки на сообщения об успешном/неуспешном множественном создании передач собственникам
	subscribeOnMultipleOwnerAcceptancesCreationEvents(
		onSuccessEvent: (event: IMultipleOwnerAcceptancesCreationFinished) => void,
		onErrorEvent: (event: IMultipleOwnerAcceptancesCreationFailed) => void
	) {
		this._subscribe(
			MultipleOwnerAcceptancesCreationEvents.CREATE_MULTIPLE_OWNER_ACCEPTANCES_FINISHED,
			onSuccessEvent,
			true
		);
		this._subscribe(
			MultipleOwnerAcceptancesCreationEvents.CREATE_MULTIPLE_OWNER_ACCEPTANCES_FAILED,
			onErrorEvent,
			true
		);
	}

	// Метод для отписки от сообщений об успешном/неуспешном множественном создании передач собственникам
	unsubscribeOnMultipleOwnerAcceptancesCreationEvents() {
		this._unsubscribe(
			MultipleOwnerAcceptancesCreationEvents.CREATE_MULTIPLE_OWNER_ACCEPTANCES_FINISHED
		);
		this._unsubscribe(
			MultipleOwnerAcceptancesCreationEvents.CREATE_MULTIPLE_OWNER_ACCEPTANCES_FAILED
		);
	}

	//! Множественное создание гарантийных обращений
	// Метод для подписки на сообщения об успешном/неуспешном множественном создании гарантийных обращений
	subscribeOnMultipleWarrantyClaimsCreationEvents(
		onSuccessEvent: (event: IMultipleWarrantyClaimsCreationFinished) => void,
		onErrorEvent: (event: IMultipleWarrantyClaimsCreationFailed) => void
	) {
		this._subscribe(
			MultipleWarrantyClaimsCreationEvents.CREATE_MULTIPLE_WARRANTY_CLAIMS_FINISHED,
			onSuccessEvent,
			true
		);
		this._subscribe(
			MultipleWarrantyClaimsCreationEvents.CREATE_MULTIPLE_WARRANTY_CLAIMS_FAILED,
			onErrorEvent,
			true
		);
	}

	// Метод для отписки от сообщений об успешном/неуспешном множественном создании гарантийных обращений
	unsubscribeOnMultipleWarrantyClaimsCreationEvents() {
		this._unsubscribe(
			MultipleWarrantyClaimsCreationEvents.CREATE_MULTIPLE_WARRANTY_CLAIMS_FINISHED
		);
		this._unsubscribe(
			MultipleWarrantyClaimsCreationEvents.CREATE_MULTIPLE_WARRANTY_CLAIMS_FAILED
		);
	}
}

export default SocketConnector;
