import {events} from '../constants/events';

export const getEventIcon = () => {
	const date = new Date();
	const currentYear = date.getFullYear();

	const currentEvent = events.find(event => {
		if (!event.endOnNextYear) {
			return date >= event.startDate(currentYear) && date <= event.endDate(currentYear);
		}
		return date >= event.startDate(currentYear) || date <= event.endDate(currentYear);
	});

	if (currentEvent) {
		if (currentEvent.icon) {
			return <i className={currentEvent.icon} />;
		}
		if (currentEvent.component) {
			return currentEvent.component;
		}
	}

	return <i className="tz-logo" />;
};
