import {Button} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {toggleSelectionSpacesVisibility} from '@src/store/modules/settings/pages/spaces/actions';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';

const chooseIcon = <i className="tz-choose-20" />;

const DesktopSpacesChooseBtn = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const toggleVisibility = () => {
		dispatch(toggleSelectionSpacesVisibility(true));
	};
	const isOnline = useOnlineManager();

	if (!isOnline) {
		return null;
	}
	return (
		<Button
			type="accent-blue"
			leftIcon={chooseIcon}
			label={t('spacesPage.pageBreadcrumbs.desktopSpacesChooseBtn.button.label')}
			onClick={toggleVisibility}
		/>
	);
};

export default DesktopSpacesChooseBtn;
