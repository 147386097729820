import {Tag} from '@tehzor/ui-components';
import {ReactNode, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

interface ISpacesTypesAndNames {
	spaceTypeId: string;
	spaceName: string;
}
export const useEntitySelectContainerSubTitle = () => {
	const {t} = useTranslation();

	const getSubTitle = useCallback(
		(objectStageName: string, processName: string): ReactNode => (
			<div>
				<div>
					{t(
						'globalAddingEntityDialog.entitySelectContainer.getEntitySelectContainerSubTitle',
						{
							name: objectStageName
						}
					)}
				</div>
				<div>
					{t(
						'globalAddingEntityDialog.entitySelectContainer.getEntitySelectContainerProcessSubTitle',
						{
							name: processName
						}
					)}
				</div>
			</div>
		),
		[t]
	);

	return getSubTitle;
};

export const useEntitySelectContainerSubSubTitle = () => {
	const {t} = useTranslation();
	const getSubSubTitle = useCallback(
		(
			objectName?: string,
			spaceTypeId?: string,
			spaceName?: string,
			spacesTypesAndNames?: ISpacesTypesAndNames[],
			structureTypeId?: string,
			structureName?: string,
			isMultiple?: boolean
		): ReactNode => (
			<div className="entity-info__spaces">
				{objectName ? <div>{objectName}</div> : null}

				{isMultiple && spacesTypesAndNames && spacesTypesAndNames.length > 0 ? (
					spacesTypesAndNames
						.map(space => (
							<div key={`${space.spaceName}-${space.spaceTypeId}`}>
								{`${t(`spaceTypes.singular.${space.spaceTypeId}`)} №${
									space.spaceName
								},`}
							</div>
						))
						.slice(0, 3)
				) : spaceTypeId && spaceName ? (
					<div>{`${t(`spaceTypes.singular.${spaceTypeId}`)} №${spaceName}`}</div>
				) : null}
				{structureTypeId && structureName ? (
					<div>{`${t(`structureTypes.${structureTypeId}`)}: ${structureName}`}</div>
				) : null}
				{isMultiple && spacesTypesAndNames && spacesTypesAndNames.length > 3 && (
					<Tag
						className="entity-info__tag"
						label={`+${spacesTypesAndNames.length - 3}`}
					/>
				)}
			</div>
		),
		[t]
	);

	return getSubSubTitle;
};
