import SocketConnector from '@src/api/SocketConnector';
import {useCallback, useEffect} from 'react';
import {queryClient} from '@src/api/QueryClient';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {IMultipleInternalAcceptancesCreationFailed} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/internalAcceptances/IMultipleInternalAcceptancesCreationFailed';
import {IMultipleInternalAcceptancesCreationFinished} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/internalAcceptances/IMultipleInternalAcceptancesCreationFinished';
import {internalAcceptancesQueryKeys} from '@src/api/cache/internalAcceptances/keys';
import declination from '@tehzor/tools/utils/declination';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	deselectAllSpaces,
	selectSpaces,
	toggleSelectionSpacesVisibility
} from '@src/store/modules/settings/pages/spaces/actions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export const useMultipleInternalAcceptancesCreationEventHandler = () => {
	const socketConnector = SocketConnector.instance;

	const dispatch = useAppDispatch();
	const {objectId: parentObjectId} = useStrictParams();

	const handleFinishedInternalAcceptancesCreation = useCallback(
		(message: IMultipleInternalAcceptancesCreationFinished) => {
			dispatch(deselectAllSpaces(parentObjectId as string));
			dispatch(toggleSelectionSpacesVisibility(false));

			addSuccessToast({
				title: `${declination(message.data?.length, [
					'Добавлена',
					'Добавлено',
					'Добавлено'
				])} ${message.data?.length} ${declination(message.data?.length, [
					'внутренняя приемка',
					'внутренние приемки',
					'внутренних приемок'
				])}`
			});
			if (message?.data) {
				void queryClient.invalidateQueries({
					queryKey: internalAcceptancesQueryKeys.lists()
				});
				void queryClient.invalidateQueries({
					queryKey: spacesQueryKeys.internalAcceptances()
				});
			}
		},
		[dispatch, parentObjectId]
	);
	const handleFailedInternalAcceptancesCreation = useCallback(
		(message: IMultipleInternalAcceptancesCreationFailed) => {
			const {objectId, data, spacesType} = message;
			const convertedData = data.map(spaceId => ({id: spaceId, type: spacesType}));
			dispatch(
				selectSpaces(parentObjectId as string, [...data], {[objectId]: convertedData})
			);
			addErrorToast({title: 'Ошибка при создании внутренних приемок'});
		},
		[dispatch, parentObjectId]
	);

	useEffect(() => {
		socketConnector.subscribeOnMultipleInternalAcceptancesCreationEvents(
			handleFinishedInternalAcceptancesCreation,
			handleFailedInternalAcceptancesCreation
		);
		return () => socketConnector.unsubscribeOnMultipleInternalAcceptancesCreationEvents();
	}, [
		handleFinishedInternalAcceptancesCreation,
		handleFailedInternalAcceptancesCreation,
		socketConnector
	]);
};
