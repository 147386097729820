import {queryClient} from '@src/api/QueryClient';
import {requestEditSpaceStatus} from '@src/api/backend/spaceStatuses';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useMutation} from '@tanstack/react-query';
import {ISavingSpaceStatus} from '@tehzor/tools/interfaces/spaceStatuses/ISavingSpaceStatus';

interface IEditSpaceStatusParams {
	spaceStatusId: string;
	fields: ISavingSpaceStatus;
}

export const useEditSpaceStatus = () =>
	useMutation({
		mutationFn: (params: IEditSpaceStatusParams) => {
			const {spaceStatusId, fields} = params;
			return requestEditSpaceStatus(spaceStatusId, fields);
		},
		mutationKey: spaceStatusesQueryKeys.edit(),
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceStatusesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.listWithStats()});
		}
	});
