import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton} from '@tehzor/ui-components';
import {IWorkAcceptanceEntity} from '@src/interfaces/IWorkAcceptanceEntity';
import {queryClient} from '@src/api/QueryClient';
import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useTranslation} from 'react-i18next';
import {useCallback} from 'react';
import {useDeleteInspection} from '@src/core/hooks/mutations/inspection/useDeleteInspection';
import {getWorkAcceptanceInspections} from '@src/store/modules/entities/workAcceptance/actions';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

interface ISelectedEntitiesDeletionProps {
	selectedEntities: IWorkAcceptanceEntity[];
}

export const SelectedEntitiesDeletion = ({selectedEntities}: ISelectedEntitiesDeletionProps) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const deleteInspection = useDeleteInspection();
	const {workAcceptanceId} = useStrictParams<{workAcceptanceId: string}>();

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.problems.deleteTitle'),
		message: t('useConfirmDialog.problems.deleteMessage')
	});

	const handleClick = useCallback(async () => {
		if (await getDeleteConfirmation()) {
			const objectsIds: string[] = [];
			for (const {type, data} of selectedEntities) {
				switch (type) {
					case 'problem':
						await dispatch(deleteProblem(data.objectId, data.id));
						objectsIds.push(data.objectId);
						break;
					case 'inspection':
						await deleteInspection({objectId: data.objectId, inspectionId: data.id});
						await dispatch(
							getWorkAcceptanceInspections(data.objectId, workAcceptanceId)
						);
						break;
				}
			}
			if (objectsIds.length > 0) {
				await queryClient.refetchQueries({
					queryKey: [...objectsQueryKeys.stats(), objectsIds]
				});
			}
		}
	}, [selectedEntities, dispatch, deleteInspection, workAcceptanceId]);

	return (
		<>
			<IconButton onClick={handleClick}>
				<i className="tz-delete" />
			</IconButton>

			{deleteDialog}
		</>
	);
};
