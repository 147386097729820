import {requestAddSpaceStatusesSet} from '@src/api/backend/spaceStatusesSets/add';
import {useMutation} from '@tanstack/react-query';
import {spaceStatusesSetsQueryKeys} from '@src/api/cache/spaceStatusesSets/keys';
import {ISavingSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISavingSpaceStatusesSet';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {spaceStatusesQueryKeys} from '@src/api/cache/spaceStatuses/keys';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IUseAddSpaceStatusesSetsParams {
	spaceStatusesSet: ISavingSpaceStatusesSet;
}

export const useAddSpaceStatusesSets = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: spaceStatusesSetsQueryKeys.add(),
		mutationFn: (params: IUseAddSpaceStatusesSetsParams) => {
			const {spaceStatusesSet} = params;
			return requestAddSpaceStatusesSet(spaceStatusesSet);
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceStatusesSetsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spaceStatusesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.listWithStats()});
		},
		onError: () => {
			addErrorToast({
				title: t('toast.addSpaceStatusesSet.error.title')
			});
		}
	});
};
