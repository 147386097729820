import {ExportDialog} from '@src/components/ExportDialog';
import {ReactNode, useCallback} from 'react';
import useAppSelector from '../useAppSelector';
import {extractWorkAcceptancesPageSettings} from '@src/store/modules/settings/pages/workAcceptances/selectors';
import {useToggle} from 'react-use';
import {useExportWorkAcceptances} from '../mutations/workAcceptances/useExportWorkAcceptances';
import IExportTemplate, {ExportTemplatesDestinationId} from '@tehzor/tools/interfaces/IExportTemplate';

const availableDestinations = [ExportTemplatesDestinationId.WORK_ACCEPTANCES];

export const useWorkAcceptancesExport = (objectId = 'all'): [ReactNode, () => void] => {
	const {selectedRows, filters, sort} = useAppSelector(s => extractWorkAcceptancesPageSettings(s, objectId));

	const [isOpen, toggleOpen] = useToggle(false);
	const {mutateAsync: exportWorkAcceptances} = useExportWorkAcceptances();

	const handleExport = useCallback(async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
		await exportWorkAcceptances({
			templateId: template.id,
			createDocument,
			email,
			objectId,
			filters,
			sort,
			selected: selectedRows
		})
	}, [objectId, filters, sort, selectedRows, exportWorkAcceptances, toggleOpen]);

	const dialog = (
		<ExportDialog
			objectId={objectId}
			pageKey='workAcceptances'
			destinations={availableDestinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={toggleOpen}
		/>
	);

	return [dialog, toggleOpen];
};
