import {IGetChecksResponse} from '@src/api/backend/checks';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';

export const extractChecksAsArray = (data: IGetChecksResponse) =>
	data.allIds.map(id => data.byId[id]);

export const extractLocalSpaceChecks = (data: IListCheck[], spaceId: string) =>
	data.filter(check => check.links?.spaceId === spaceId);

export const extractChecksTotal = (data: IGetChecksResponse) =>
	data.total;