import EntitiesFilters, {ObjectsFilter} from '@src/components/EntitiesFilters';
import {CompaniesFilter} from '@src/components/EntitiesFilters/components/CompaniesFilter';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {IExportTemplatesFiltersState} from '@src/store/modules/settings/pages/exportTemlates/interfaces';
import {useTranslation} from 'react-i18next';
import {ExportTemplatesSearch} from './ExportTemplatesSearch';

export const ExportTemplatesFilters = () => {
	const {state} = useEntitiesFiltersCtx<IExportTemplatesFiltersState>();
	const {t} = useTranslation();

	return (
		<div className="manage-export-templates-page__filters">
			<ExportTemplatesSearch />

			<EntitiesFilters
				entity="exportTemplates"
				isDescendants={false}
			>
				<CompaniesFilter
					companies={state?.companies}
					label={t('entitiesFilters.companiesFilter.label')}
				/>

				<ObjectsFilter
					value={state?.objects}
					label={t('entitiesFilters.objectsFilter.label')}
				/>
			</EntitiesFilters>
		</div>
	);
};
