import {Suspense, useCallback} from 'react';
import {Button, ISelectionRowProps, LoadingPanel} from '@tehzor/ui-components';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import '../../SpacesPage.less';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useSpacesSelection} from '../../hooks/useSpacesSelection';
import {SpaceChoosingToolbarTable} from '../toolbar/SpaceChoosingToolbar.table';
import {selectSpaces} from '@src/store/modules/settings/pages/spaces/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useSpacesStageSelectionDialog} from '../SpacesStageSelectionDialog/useSpacesStageSelectionDialog';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSelectedSpacesForObjectIds} from '@src/store/modules/settings/pages/spaces/selectors';
import {useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';
import {useMultipleEntitiesAddingPermissions} from '@src/core/hooks/permissions/useMultipleEntitiesAddingPermissions';

type ICustomSelectionRowProps = ISelectionRowProps<IConvertedSpace>;

export const SelectionRow = (props: ICustomSelectionRowProps) => {
	const {toggleAllRowsSelected} = props;
	const {objectId} = useStrictParams<{objectId: string}>();
	const {data: targetObjects} = useTargetObjects(objectId);
	const isOnline = useOnlineManager();

	const {canAddMultipleEntities} = useMultipleEntitiesAddingPermissions();

	const dispatch = useAppDispatch();

	const {singleObjectId, selectedSpaces, nonEmptySelectedCount, typeCounts} = useAppSelector(
		state => extractSelectedSpacesForObjectIds(state, objectId, targetObjects || [])
	);
	const {spaceStageSelectionDialog, openStageSelectionDialog, globalDialog, openGlobalDialog} =
		useSpacesStageSelectionDialog({
			objectId: singleObjectId || objectId
		});
	const {isChoosingVisible} = useSpacesSelection(objectId);

	// Очистка выборки помещений
	const clearSelection = useCallback(() => {
		toggleAllRowsSelected(false);
		dispatch(selectSpaces(objectId, [], {}));
	}, [toggleAllRowsSelected]);

	// Открытие модального окна с выбором стадии для множественного добавления сущностей
	const handleOpenStageSelection = useCallback(() => {
		if (selectedSpaces.length > 1) {
			openStageSelectionDialog(true);
		} else if (selectedSpaces.length === 1) {
			openGlobalDialog();
		}
	}, [selectedSpaces.length, openStageSelectionDialog, openGlobalDialog]);

	return (
		<>
			<div className="spaces-page__table-selection-row">
				{isOnline && canAddMultipleEntities && (
					<SpaceChoosingToolbarTable
						isVisible={isChoosingVisible}
						disabled={
							!singleObjectId || nonEmptySelectedCount !== 1 || typeCounts !== 1
						}
						selectedCount={selectedSpaces.length}
						openStageSelectionDialog={handleOpenStageSelection}
					/>
				)}
				<Button
					label="Сбросить"
					type="cancel"
					onClick={clearSelection}
				/>
			</div>
			<Suspense fallback={<LoadingPanel />}>
				{singleObjectId && selectedSpaces.length > 1 && spaceStageSelectionDialog}
				{singleObjectId && selectedSpaces.length === 1 && globalDialog}
			</Suspense>
		</>
	);
};
