import {MobileHeader, MobileHeaderMenu} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IHeaderSectionsMenuItem} from '@src/interfaces/IHeaderSectionsMenuItem';
import {useLocation} from 'react-router-dom';
import MobileLeftButton from './MobileLeftButton';
import {Processing} from '@src/components/Processing/Processing';
import {ReactNode} from 'react';

interface IMobileAppHeaderProps {
	title?: string;
	subTitle?: string;
	backBtnVisible?: boolean;
	sectionsMenu?: IHeaderSectionsMenuItem[];
	mobileLeftButton?: ReactNode;
	mobileRightButtons?: ReactNode;
	titleButtons?: ReactNode;
}

const MobileAppHeader = (props: IMobileAppHeaderProps) => {
	const {
		title,
		subTitle,
		backBtnVisible,
		sectionsMenu,
		mobileLeftButton,
		mobileRightButtons,
		titleButtons
	} = props;

	const location = useLocation();
	const {replacePath} = useChangePath();

	return (
		<MobileHeader
			className="app-header"
			title={title}
			subTitle={subTitle}
			titleButtons={titleButtons}
			left={mobileLeftButton ?? <MobileLeftButton backBtnVisible={backBtnVisible} />}
			right={
				<div className="app-header__mobile-right-menu">
					<Processing />
					{mobileRightButtons}
				</div>
			}
			sectionsMenu={
				sectionsMenu && (
					<MobileHeaderMenu
						items={sectionsMenu}
						value={location.pathname}
						onChange={replacePath}
					/>
				)
			}
		/>
	);
};

export default MobileAppHeader;
