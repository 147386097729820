import {QueryFunctionContext, useQueryClient} from '@tanstack/react-query';
import {
	IGetListUnitIndicatorsQueryKey,
	IGetPaginateUnitIndicatorsQueryKey,
	IGetUnitIndicatorQueryKey,
	unitIndicatorsQueryKeys
} from '../keys';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {
	requestGetListUnitIndicators,
	requestGetPaginateUnitIndicators
} from '@src/api/backend/unitIndicators';
import {requestGetUnitIndicator} from '@src/api/backend/unitIndicator';

export const useUnitIndicatorsQueryDefaults = () => {
	const queryClient = useQueryClient();
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);

	queryClient.setQueryDefaults(unitIndicatorsQueryKeys.paginates(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IGetPaginateUnitIndicatorsQueryKey>) => {
			const [, , filters, sort, offset, limit] = queryKey;
			return requestGetPaginateUnitIndicators(filters, sort, offset, limit);
		},
		staleTime: 1000 * 60 * 5,
		gcTime: 1000 * 60 * 60 * 24,
		enabled: !isOfflineModeAvailable
	});

	queryClient.setQueryDefaults(unitIndicatorsQueryKeys.lists(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IGetListUnitIndicatorsQueryKey>) => {
			const [, , filters] = queryKey;
			return requestGetListUnitIndicators(filters);
		},
		staleTime: 1000 * 60 * 5,
		gcTime: 1000 * 60 * 60 * 24,
		enabled: !isOfflineModeAvailable
	});

	queryClient.setQueryDefaults(unitIndicatorsQueryKeys.details(), {
		queryFn: async ({queryKey}: QueryFunctionContext<IGetUnitIndicatorQueryKey>) => {
			const [, , unitIndicatorId] = queryKey;
			return requestGetUnitIndicator(unitIndicatorId);
		},
		staleTime: 1000 * 60 * 5,
		gcTime: 1000 * 60 * 60 * 24,
		enabled: !isOfflineModeAvailable
	});
};
