import {memo, useCallback, useEffect} from 'react';
import './SpacesPage.less';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';
import useAppSelector from '@src/core/hooks/useAppSelector';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {
	changeFilters,
	changeListOffset,
	clearFilters,
	deselectAllSpaces
} from '@src/store/modules/settings/pages/spaces/actions';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {ISpacesFiltersState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

const SpacesPage = memo(() => {
	useScrollRestoration();

	const {objectId} = useStrictParams<{objectId: string}>();
	const isDesktop = useIsDesktop();

	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));

	const dispatch = useAppDispatch();

	const SpacesComponent = isDesktop ? (
		<Desktop objectId={objectId} />
	) : (
		<Mobile objectId={objectId} />
	);

	const applyFilters = useCallback(
		(value: ISpacesFiltersState) => {
			// Сброс выбранных помещений при применении фильтра
			dispatch(deselectAllSpaces(objectId));
			dispatch(changeFilters(objectId, value));
			dispatch(changeListOffset(objectId, 0));
		},
		[dispatch, objectId]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters(objectId));
	}, [dispatch, objectId]);

	// Сброс пагинации при размонтировании компонента
	useEffect(() => {
		const resetListOffset = () => {
			dispatch(changeListOffset(objectId, 0));
		};

		return () => {
			resetListOffset();
		};
	}, [objectId, dispatch]);

	return (
		<EntitiesFiltersProvider
			objectId={objectId}
			onApply={applyFilters}
			filters={pageSettings.filters}
			onClear={onClear}
		>
			{SpacesComponent}
		</EntitiesFiltersProvider>
	);
});

export default SpacesPage;
