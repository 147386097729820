import {memo} from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useObjectBreadcrumbs} from '@src/core/hooks/breadcrumbs/useObjectBreadcrumbs';
import DesktopSpacesExportBtn from './actions/SpacesExportBtn.desktop';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {DesktopMenu} from './actions/Menu.desktop';
import {useCurrentTreeObject} from '@src/core/hooks/queries/objects/hooks';
import DesktopSpacesChooseBtn from './actions/SpacesChooseBtn.desktop';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractSpacesPageSettings,
	extractSpacesSelectionVisibility
} from '@src/store/modules/settings/pages/spaces/selectors';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';

interface IPageBreadcrumbsProps {
	objectId: string;
}

const PageBreadcrumbs = ({objectId}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useObjectBreadcrumbs(objectId);
	const permissions = useSpacesPermissions(objectId);
	const object = useCurrentTreeObject(objectId);
	const lastObject = object ? !object.children?.length : false;
	const isDesktop = useIsDesktop();
	const chooseSpacesVisible = useAppSelector(extractSpacesSelectionVisibility);
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));

	return (
		<AppBreadcrumbs
			className="spaces-page__breadcrumbs"
			items={breadcrumbs}
		>
			{isDesktop && (
				<>
					{!chooseSpacesVisible &&
						pageSettings.displayMode === SpacesDisplayMode.SCHEMA && (
							<DesktopSpacesChooseBtn />
						)}
					{permissions.canExport && <DesktopSpacesExportBtn objectId={objectId} />}
					<DesktopMenu
						objectId={objectId}
						canAddSpace={permissions.canAdd && lastObject}
					/>
				</>
			)}
		</AppBreadcrumbs>
	);
};

export default memo(PageBreadcrumbs);
