import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IUnitIndicatorsSet} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicatorsSet';

export type IGetUnitIndicatorsSetResponse = IUnitIndicatorsSet;

/**
 * Получает набор индикаторов помещения по id
 *
 * @param setId id набора помещений
 */
export const requestGetUnitIndicatorsSet = async (setId: string) => {
	const response = await httpRequests.withToken.get<IGetUnitIndicatorsSetResponse>(
		`unit-indicators-sets/${setId}`
	);

	return response.data;
};
