import { memo, ReactNode } from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useObjectBreadcrumbs} from '@src/core/hooks/breadcrumbs/useObjectBreadcrumbs';

interface IPageBreadcrumbsProps {
	objectId: string;
	children?: ReactNode;
}

export const ObjectPageBreadcrumbs = memo(({objectId, children}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useObjectBreadcrumbs(objectId);

	return (
		<AppBreadcrumbs
			className="inspections-page__breadcrumbs"
			items={breadcrumbs}
		>
			{children}
		</AppBreadcrumbs>
	);
});
