import {memo, useCallback, useMemo, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {FilterButton, TreeSelect} from '@tehzor/ui-components';
import {useEntitiesFiltersCtx} from '../utils/entitiesFiltersCtx';
import {treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {TranslatedSelectPopup} from '@src/components/TranslatedSelectPopup';
import {makeFilterLabel} from '../utils/makeFilterLabel';
import {IUnitIndicatorsSetWithIndicators} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicatorsSet';

export interface IIndicatorFilterProps {
	indicators: Array<{
		id: string;
		name: string;
		color: string;
		indicatorsSetId: string;
	}>;
	indicatorSets: IUnitIndicatorsSetWithIndicators[];
	label?: string;
	value?: string[];
	filterName?: string;
}

export const UnitIndicatorsFilter = memo((props: IIndicatorFilterProps) => {
	const {
		label = 'Индикаторы',
		value,
		filterName = 'indicators',
		indicators,
		indicatorSets
	} = props;
	const {dispatch} = useEntitiesFiltersCtx();

	const [selectedObjects, setSelectedObjects] = useState(value);
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);

	const treeData = useMemo(
		() =>
			indicatorSets
				.filter(set => set.indicators.length > 0)
				.map(set => ({
					id: set.id,
					content: set.name,
					children: indicators
						.filter(indicator => indicator.indicatorsSetId === set.id)
						.map(indicator => ({
							id: indicator.id,
							content: indicator.name,
							color: indicator.color
						}))
				})),
		[indicators, indicatorSets]
	);

	const {filteredData, expanded} = useMemo(() => treeFilter(treeData, 'content', ''), [treeData]);

	useUpdateEffect(() => {
		setExpandedObjects(expanded?.map(item => item.id));
	}, [expanded]);

	const handleApply = useCallback(() => {
		dispatch({[filterName]: selectedObjects});
	}, [dispatch, filterName, selectedObjects]);

	const handleExpand = useCallback((v?: string[]) => {
		setExpandedObjects(v);
	}, []);

	const handleClear = useCallback(() => {
		setSelectedObjects([]);
	}, []);

	const handleFullClear = useCallback(() => {
		dispatch({[filterName]: undefined});
		setSelectedObjects([]);
		setExpandedObjects([]);
	}, [dispatch, filterName]);

	const handleCancel = useCallback(() => {
		setSelectedObjects(value);
	}, [value]);

	useUpdateEffect(() => {
		setExpandedObjects([]);
		setSelectedObjects(value);
	}, [value]);

	return (
		<TranslatedSelectPopup
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedObjects?.length}
			count={selectedObjects?.length}
			footer
			trigger={
				<FilterButton
					className="entities-filters__item"
					label={makeFilterLabel(label, selectedObjects, indicators)}
					active={!!value?.length}
					onClear={handleFullClear}
				/>
			}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selectedObjects}
				onChange={setSelectedObjects}
				expandedValue={expandedObjects}
				onExpand={handleExpand}
				latestOnly
			/>
		</TranslatedSelectPopup>
	);
});
