import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IExportTemplate from '@tehzor/tools/interfaces/IExportTemplate';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetExportTemplatesResponse extends INormalizedData<IExportTemplate> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Возвращает пагинированный список шаблонов экспорта
 */
export const requestExportTemplatesPaginate = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) => {
	const response = await httpRequests.withToken.post<IGetExportTemplatesResponse>(
		'export-templates/get-paginate',
		{
			filters,
			sort: convertSortParam(sort),
			offset,
			limit
		}
	);
	return response.data;
};
