import {memo, PropsWithChildren, useMemo} from 'react';
import classNames from 'classnames';
import {LegendItem} from './LegendItem';
import {IUnitIndicatorsSetWithIndicators} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicatorsSet';
import {IUnitIndicator} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicator';
import {SpaceIndicatorsSet} from '@tehzor/ui-components';

interface ILegendGroupWithSubGroupsProps {
	heading: string;
	groups: IUnitIndicatorsSetWithIndicators[];
	className?: string;
}

export const LegendGroupWithSubGroups = memo(
	({heading, groups, className}: PropsWithChildren<ILegendGroupWithSubGroupsProps>) => {
		const renderSubGroups = useMemo(
			() =>
				groups?.map(group => (
					<SpaceIndicatorsSet
						key={group.id}
						set={group}
						className="indicators-legend"
					>
						{group.indicators?.map((indicator: IUnitIndicator) => (
							<LegendItem
								key={indicator.id}
								type="indicator"
								title={indicator.name}
								color={indicator.color}
							/>
						))}
					</SpaceIndicatorsSet>
				)),
			[groups]
		);

		return (
			<div className={classNames('spaces-page__legend-items-group', className)}>
				<div className="spaces-page__legend-item">
					<div className="spaces-page__legend-item-header">{heading}</div>
				</div>
				{renderSubGroups}
			</div>
		);
	}
);
