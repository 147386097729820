import {ReactNode, useCallback, useMemo, useRef, useState} from 'react';
import {useGlobalAddingEntityDialog} from '@src/components/GlobalAddingEntityDialog/hooks/useGlobalAddingEntityDialog';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useInternalAcceptancesPermissions} from '@src/core/hooks/permissions/useInternalAcceptancesPermissions';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {usePresaleChecksPermissions} from '@src/fsd/src/entities/PresaleCheck/model/hooks/usePresaleChecksPermissions';
import {extractSelectedSpacesForObjectIds} from '@src/store/modules/settings/pages/spaces/selectors';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {StagesSelectDialog} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/components/StagesSelectDialog/StagesSelectDialog';
import {useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {useTranslatedObjectStagesArray} from '@src/core/hooks/translations/useTranslatedObjectStagesArray';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {convertStages} from '@tehzor/ui-components/src/components/containers/EntitySelectContainer/utils/convertStages';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useSpace} from '@src/core/hooks/queries/space';
import {IGlobalAddingRefProps} from '@src/components/GlobalAddingEntityDialog/GlobalAddingEntityDialog';
import {AddingOwnerAcceptanceView} from '@src/components/AddingOwnerAcptDialog/AddingOwnerAcptDialog';
import {AddingWarrantyClaimView} from '@src/components/AddingWarrantyClaimDialog/AddingWarrantyClaimDialog';
import {useEntitiesMaps} from '@src/components/GlobalAddingEntityDialog/hooks/useEntitiesMaps';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';

const DEFAULT_TYPES = ['problem', 'inspection'];

interface UseSpacesStageSelectionDialogProps {
	onCancel?: () => void;
	title?: string;
	stageTitle?: string;
	saveLabel?: string;
	cancelLabel?: string;
	objectId: string;
}

interface IUseSpacesStageSelectionDialogReturn {
	spaceStageSelectionDialog: ReactNode;
	openStageSelectionDialog: (state: boolean) => void;
	globalDialog: ReactNode;
	openGlobalDialog: () => void;
}

export const useSpacesStageSelectionDialog = ({
	onCancel,
	title = 'Выбор процесса',
	stageTitle = 'Стадия',
	saveLabel = 'Сохранить',
	cancelLabel = 'Отменить',
	objectId
}: Partial<UseSpacesStageSelectionDialogProps>): IUseSpacesStageSelectionDialogReturn => {
	const {objectId: pageObjectId} = useStrictParams<{objectId: string}>();
	const [choosenProcessId, setChoosenProcessId] = useState<string>();
	const [choosenStageId, setChoosenStageId] = useState<string>();

	const {data: targetObjects} = useTargetObjects(pageObjectId);

	const {selectedSpaces, singleObjectId} = useAppSelector(state =>
		extractSelectedSpacesForObjectIds(state, pageObjectId, targetObjects || [])
	);

	const {data: space} = useSpace(selectedSpaces[0], singleObjectId as string);

	const checksPermissions = useChecksPermissions(objectId || '', 'space');
	const ownersAcceptancesPermissions = useOwnerAcceptancesPermissions(objectId || '', 'space');
	const warrantyClaimPermissions = useWarrantyClaimsPermissions(objectId || '', 'space');
	const internalAcceptancePermissions = useInternalAcceptancesPermissions(
		objectId || '',
		'space'
	);
	const presaleChecksPermissions = usePresaleChecksPermissions(objectId || '', 'space');

	const permissions = useMemo(
		() => ({
			checks: !!checksPermissions?.canAdd,
			acceptances: !!ownersAcceptancesPermissions?.canAdd,
			claims: !!warrantyClaimPermissions?.canAdd,
			internalAcceptances: !!internalAcceptancePermissions?.canAdd,
			presaleChecks: !!presaleChecksPermissions?.canAdd
		}),
		[
			checksPermissions,
			ownersAcceptancesPermissions,
			warrantyClaimPermissions,
			internalAcceptancePermissions,
			presaleChecksPermissions
		]
	);

	const [localDialogOpen, setLocalDialogOpen] = useState(false);

	const stages = useTranslatedObjectStagesArray();

	const addingEntityRef = useRef<IGlobalAddingRefProps>(null);

	const [selectedCheckId, setSelectedCheckId] = useState<string | undefined>();
	const [selectedPresaleCheckId, setSelectedPresaleCheckId] = useState<string | undefined>();

	const [selectedInternalAcceptanceId, setSelectedInternalAcceptanceId] = useState<
		string | undefined
	>();
	const [selectedOwnerAcceptanceId, setSelectedOwnerAcceptanceId] = useState<
		string | undefined
	>();
	const [selectedWarrantyClaimId, setSelectedWarrantyClaimId] = useState<string | undefined>();

	const [ownerAcceptanceView, setOwnerAcceptanceView] = useState<AddingOwnerAcceptanceView>();
	const [warrantyClaimView, setWarrantyClaimView] = useState<AddingWarrantyClaimView>();

	const [saving, setSaving] = useState(false);

	const generatedLinks = useMemo(() => {
		if (selectedSpaces.length === 1) {
			return {spaceId: selectedSpaces[0]};
		}
		return {};
	}, [selectedSpaces]);

	const openStageSelectionDialog = useCallback((state: boolean) => {
		setLocalDialogOpen(state);
	}, []);

	const closeDialog = useCallback(() => {
		setLocalDialogOpen(false);
	}, []);

	const [, entitiesSelectMapProps] = useEntitiesMaps({
		objectId: singleObjectId as string,
		spaceId: space?.id,
		spaceIds: selectedSpaces,
		links: generatedLinks,
		permissions,
		addingEntityRef,
		defaultProblemData: undefined,
		defaultInspectionData: undefined,
		types: DEFAULT_TYPES as ICheckAddingEntityType[],
		saving,
		setSaving,
		selectedCheckId,
		selectedPresaleCheckId,
		selectedInternalAcceptanceId,
		selectedOwnerAcceptanceId,
		selectedWarrantyClaimId,
		setSelectedCheckId,
		setSelectedInternalAcceptanceId,
		setSelectedOwnerAcceptanceId,
		setSelectedWarrantyClaimId,
		setSelectedPresaleCheckId,
		ownerAcceptanceView,
		setOwnerAcceptanceView,
		warrantyClaimView,
		setWarrantyClaimView,
		onClose: () => closeDialog(),
		isMultiple: selectedSpaces.length > 1
	});

	const [globalDialog, openGlobalDialog] = useGlobalAddingEntityDialog(
		singleObjectId as string,
		selectedSpaces.length > 1 ? undefined : space?.id,
		generatedLinks,
		choosenStageId as ObjectStageIds,
		choosenProcessId as ProcessIds,
		permissions,
		undefined,
		undefined,
		DEFAULT_TYPES as ICheckAddingEntityType[],
		undefined,
		selectedSpaces.length > 1,
		selectedSpaces
	);

	const handleConfirm = useCallback(
		(selectedStageId: string, processId: string) => {
			setChoosenStageId(selectedStageId);
			setChoosenProcessId(processId);
			openGlobalDialog();
		},
		[closeDialog, openGlobalDialog]
	);

	const handleCancel = useCallback(() => {
		closeDialog();
		onCancel?.();
	}, [closeDialog, onCancel]);

	const convertedAndFilteredStages = useMemo(
		() =>
			convertStages(stages).filter(item =>
				Object.keys(entitiesSelectMapProps).includes(item.id)
			),
		[stages, entitiesSelectMapProps]
	);

	const spaceStageSelectionDialog = (
		<>
			<StagesSelectDialog
				isOpen={localDialogOpen}
				title={title}
				stageTitle={stageTitle}
				saveLabel={saveLabel}
				cancelLabel={cancelLabel}
				onClose={closeDialog}
				onConfirm={handleConfirm}
				onCancel={handleCancel}
				stages={convertedAndFilteredStages}
				entitiesSelectMapProps={entitiesSelectMapProps}
				isMultiple={selectedSpaces.length > 1}
			/>
			{globalDialog}
		</>
	);

	return {spaceStageSelectionDialog, openStageSelectionDialog, globalDialog, openGlobalDialog};
};
