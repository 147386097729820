import {useMutation} from '@tanstack/react-query';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {
	IAddInspectionsResponse,
	IAddPresaleChecksResponse,
	IAddProblemsResponse,
	requestAddMultiplePresaleChecks
} from '@/shared/api/presaleChecks/addMultiplePresaleChecksWithEntities';
import {Dispatch, SetStateAction} from 'react';
import {ISavingProblem} from '../../../../../../../interfaces/saving/ISavingProblem';
import {ISavingInspection} from '../../../../../../../interfaces/saving/ISavingInspection';
import {IPresaleCheckAddingEntityType} from '../../slice/presaleCheckAdding/interfaces';

export interface IAddMultiplePresaleChecksWithEntitiesParams {
	objectId: string;
	spaceIds: string[];
	links: IPresaleCheck['links'];
	savingData: ISavingProblem | ISavingInspection;
	presaleCheckType: IPresaleCheckAddingEntityType;
	setSaving: Dispatch<SetStateAction<boolean>>;
	onClose: () => void;
}

export const useAddMultiplePresaleChecksWithEntities = () => {
	const mutation = useMutation<
		Record<
			string,
			IAddPresaleChecksResponse | IAddProblemsResponse | IAddInspectionsResponse | string[]
		>,
		Error,
		IAddMultiplePresaleChecksWithEntitiesParams
	>({
		mutationFn: async ({objectId, spaceIds, links, presaleCheckType, savingData}) =>
			requestAddMultiplePresaleChecks(objectId, spaceIds, links, savingData, presaleCheckType),
		onSuccess: (response, {setSaving, onClose}) => {
			setSaving(false);
			onClose();
		},
		onError: error => {
			console.error('Ошибка при создании предпродажных проверок:', error);
		}
	});

	return async ({
		objectId,
		spaceIds,
		links,
		savingData,
		presaleCheckType,
		setSaving,
		onClose
	}: IAddMultiplePresaleChecksWithEntitiesParams) => {
		await mutation.mutateAsync({
			objectId,
			spaceIds,
			links,
			presaleCheckType,
			savingData,
			setSaving,
			onClose
		});
	};
};
