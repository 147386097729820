import {useMemo} from 'react';
import {useStagesAndProcessesMap} from './useProcessesMap';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

/**
 * Хук для получения ObjectStageId по ProcessId
 *
 * @param processId Идентификатор процесса.
 * @returns Идентификатор стадии объекта или null
 * */
export const useShowStageByProcessId = (processId?: string) => {
	const stagesAndProcessesMap = useStagesAndProcessesMap();

	return useMemo(() => {
		if (!processId) {
			return null;
		}

		for (const [stageId, processIds] of Object.entries(stagesAndProcessesMap)) {
			if (processIds.includes(processId as ProcessIds)) {
				return stageId as ObjectStageIds;
			}
		}
		return undefined;
	}, [processId, stagesAndProcessesMap]);
};
