import {makeGenerateSpacesRequest} from '@src/api/backend/spaces';
import {IGenerateSpacesParams} from '@src/api/cache/spaces/defaults/useSpacesMutationDefaults';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {useMutation} from '@tanstack/react-query';
import {queryClient} from '../../../../api/QueryClient';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

export const useGenerateSpaces = (objectId: string) => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: spacesQueryKeys.generateById(objectId),
		mutationFn: ({withSpacesPlans, plans}: IGenerateSpacesParams) =>
			makeGenerateSpacesRequest(objectId, withSpacesPlans, plans),
		onSuccess: () => {
			void queryClient.invalidateQueries({
				queryKey: [...spacesQueryKeys.list(), {objects: [objectId]}]
			});
			void queryClient.invalidateQueries({
				queryKey: [...spacesQueryKeys.listWithStats(), {objects: [objectId]}]
			});
			addSuccessToast({title: t('toast.generateSpace.success.title')});
		},
		onError: () => {
			addErrorToast({title: t('toast.generateSpace.error.title')});
		}
	});
};
