import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {IEditSpaceStatusResponse} from '@src/api/backend/space/editStatus';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {addErrorToast} from '@tehzor/ui-components';
import {t} from 'i18next';

export type IEditSpaceStatusPayload = IEditSpaceStatusResponse;

const success = (response: IEditSpaceStatusResponse, pageObjectId: string) => {
	void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
	void queryClient.invalidateQueries({queryKey: spacesQueryKeys.listWithStats()});
	void queryClient.invalidateQueries({queryKey: spacesQueryKeys.detail(response.id)});

	return {
		type: types.EDIT_STATUS_SUCCESS,
		payload: {...response, pageObjectId}
	};
};

const failure = (error: IError) => {
	addErrorToast({title: t('toast.editSpaceStatus.error.title')});
	return {
		type: types.EDIT_STATUS_FAILURE,
		payload: error
	};
};

export const editSpaceStatusActions = {failure, success};
