import {IGetPaginateInspectionsResponse} from '@src/api/backend/inspections';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {extractLocalSpaceInspections} from './selectors';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {IInspectionsFiltersState} from '@src/store/modules/settings/pages/inspections/interfaces';
import {extractInspectionsPageSettings} from '@src/store/modules/settings/pages/inspections/selectors';
import {convertLocalAttachments} from '@src/utils/convertLocalAttachments';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import IListInspection from '@tehzor/tools/interfaces/inspections/IListInspection';
import {useCallback, useMemo} from 'react';
import {ISavingInspectionQuery} from '../../mutations/inspection/useAddInspection';
import useAppSelector from '../../useAppSelector';
import {IGetInspectionResponse} from '@src/api/backend/inspection';
import {extractInspectionsTotal} from './selectors';

export const useInspection = <T = IGetInspectionResponse>(
	inspectionId: string,
	objectId: string,
	select?: (data: IGetInspectionResponse) => T
) =>
	useQuery({
		queryKey: [...inspectionsQueryKeys.detail(inspectionId), objectId],
		meta: {
			error: 'при загрузке осмотра'
		},
		select
	});

export const useInspections = <T = IGetPaginateInspectionsResponse>(
	objectId: string,
	select?: (data: IGetPaginateInspectionsResponse) => T
) => {
	const {offset, filters, sort, pageSize} = useAppSelector(s =>
		extractInspectionsPageSettings(s, objectId)
	);
	const {state} = useEntitiesFiltersCtx<IInspectionsFiltersState>();
	return useQuery({
		queryKey: [
			...inspectionsQueryKeys.list(),
			objectId,
			state || filters,
			sort,
			offset,
			pageSize
		],
		meta: {
			error: 'при загрузке списка осмотров'
		},
		select
	});
};

export const useLocalInspections = <T = IListInspection[]>(
	objectId = 'all',
	select?: (data: IListInspection[]) => T
) => {
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	return useQuery<IListInspection[], IError, T>({
		queryKey: [...inspectionsQueryKeys.localList(), objectId],
		staleTime: Infinity,
		gcTime: Infinity,
		networkMode: 'offlineFirst',
		enabled: isOfflineModeAvailable,
		select
	});
};

export const useExtractLocalSpaceInspections = (objectId: string, spaceId: string) => {
	const selector = useCallback(
		(data: IListInspection[]) => extractLocalSpaceInspections(data, spaceId),
		[spaceId]
	);
	return useLocalInspections(objectId, selector);
};

export const useInspectionsList = (data?: IGetPaginateInspectionsResponse) =>
	useMemo(
		() =>
			data?.allIds.map(id => {
				if (data.byId[id].localNumber) {
					return {
						...data.byId[id],
						attachments: convertLocalAttachments<ISavingInspectionQuery>(
							id,
							inspectionsQueryKeys
						)
					};
				}
				return data.byId[id];
			}),
		[data]
	);

export const useInspectionsTotal = (objectId: string) =>
	useInspections(objectId, extractInspectionsTotal);
