import getTimezone from '@tehzor/tools/utils/getTimezone';
import getEmailsArray from '@src/utils/getEmailsArray';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {
	IExportWarrantyClaimsRequest,
	IExportWarrantyClaimsFilters,
	IDefferedExportResponse
} from '@tehzor/tools/interfaces/export';

/**
 * Отправляет запрос на экспорт гарантийных проверок
 */
export const makeWarrantyClaimsExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId?: string,
	filters?: IExportWarrantyClaimsFilters,
	sort?: Record<string, boolean>,
	selected?: string[]
): Promise<IDefferedExportResponse> => {
	const params: IExportWarrantyClaimsRequest = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			objectId,
			filters: {
				...filters,
				selected
			},
			sort: convertSortParam(sort)
		}
	};
	const response = await httpRequests.exportWithToken.post<IDefferedExportResponse>(
		'warranty-claims/export',
		params
	);
	return response.data;
};
