import {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {Dispatch, RefObject, SetStateAction} from 'react';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {IAddMultipleChecksWithEntitiesParams} from '@src/core/hooks/mutations/checks/useAddMultipleChecksWithEntities';

interface ISaveMultipleChecksProps {
	objectId: string;
	spaceIds: string[];
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingInspectionRefProps>;
	checkType: ICheckAddingEntityType;
	links: ICheck['links'];
	addMultipleChecksWithEntities: (params: IAddMultipleChecksWithEntitiesParams) => Promise<void>;
	setSaving: Dispatch<SetStateAction<boolean>>;
	onClose: () => void;
}

export const saveMultipleChecks = async ({
	objectId,
	spaceIds,
	addingEntityRef,
	checkType,
	links,
	addMultipleChecksWithEntities,
	setSaving,
	onClose
}: ISaveMultipleChecksProps) => {
	if (addingEntityRef.current) {
		const savingData = await addingEntityRef.current.getSavingData(true);
		if (!savingData) {
			return;
		}

		setSaving(true);
		onClose();

		await addMultipleChecksWithEntities({
			objectId,
			spaceIds,
			links,
			savingData,
			checkType,
			onClose,
			setSaving
		});
	}
};
