import {CSSProperties, ReactNode} from 'react';
import './TagsContainer.less';
import classNames from 'classnames';

interface ITagsContainerProps {
	className?: string;
	style?: CSSProperties;
	children?: ReactNode;
}

export const TagsContainer = ({className, style, children}: ITagsContainerProps) => (
	<div
		className={classNames('tags-cont', className)}
		style={style}
	>
		<div className="tags-cont__wrap">{children}</div>
	</div>
);
