import {memo, useEffect, useMemo, useState} from 'react';
import {IChanges} from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {MobileFilter} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {useActualUnitIndicatorsSetsExtended} from '@src/core/hooks/queries/unitIndicatorsSets/hooks';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {useFilterPageTransition} from '../utils/pagesRouting';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {ISpacesFiltersState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {useUnitIndicatorsAsMap} from '@src/core/hooks/queries/unitIndicators/hooks';
import {useShowStageByProcessId} from '@src/core/hooks/processes/useShowStageByProcessId';

interface ISpacesIndicatorsFilterMobileProps {
	objectId: string;
	value?: string[];
	entity: string;
	onChange: (changes: IChanges) => void;
}

export const SpacesIndicatorsFilterMobile = memo((props: ISpacesIndicatorsFilterMobileProps) => {
	const {t} = useTranslation();
	const [selected, setSelected] = useState([] as string[]);

	const {state} = useEntitiesFiltersCtx<ISpacesFiltersState>();

	const {processId: pageProcessId} = useAppSelector(state =>
		extractSpacesPageSettings(state, props.objectId)
	);
	const stageId = useShowStageByProcessId(pageProcessId);
	const {data: indicatorSets} = useActualUnitIndicatorsSetsExtended(
		props.objectId,
		stageId ?? undefined
	);
	const {data: indicators} = useUnitIndicatorsAsMap();

	useEffect(() => {
		setSelected(props.value || []);
	}, [props.value]);

	const handleChange = (v: string[] | undefined) => {
		setSelected(v || []);
		props.onChange({indicators: v || []});
	};

	const handleExpand = useFilterPageTransition(props.objectId, props.entity, 'indicators');

	const selectedIndicatorsConvert = useMemo(
		() =>
			state.indicators?.map(indicatorId => {
				const indicator = indicators ? indicators[indicatorId] : undefined;
				return indicator
					? {id: indicatorId, name: indicator.name}
					: {id: indicatorId, name: ''};
			}) || [],
		[state.indicators, indicators]
	);

	if (!indicatorSets) {
		return null;
	}

	return (
		<MobileFilter
			label={t('spacesPage.spacesFilters.entitiesFilters.statusesFilter.indicators.label')}
			elements={[...selectedIndicatorsConvert]}
			selected={selected}
			onChange={handleChange}
			onExpand={handleExpand}
		/>
	);
});
