import {
	IObjectStageWithProcesses,
	ObjectStageIds
} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ReactNode, useCallback, useMemo, useState} from 'react';
import {EntityButton} from './components/EntityButton/EntityButton';
import {StagesSelectDialog} from './components/StagesSelectDialog/StagesSelectDialog';
import {convertStages} from './utils/convertStages';
import './EntitySelectContainer.less';
import {IEntitySelectProps} from './components/EntitySelect/EntitySelect';
import {getTitle} from './utils/getTitle';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {ISpace} from '@tehzor/tools/interfaces/spaces/ISpace';

interface ISpacesTypesAndNames {
	spaceTypeId: string;
	spaceName: string;
}
interface IEntitySelectContainerProps {
	contentMap: Record<ObjectStageIds, Record<ProcessIds, ReactNode>>;
	entitiesSelectMapProps: Record<ObjectStageIds, Record<ProcessIds, IEntitySelectProps>>;
	stages: IObjectStageWithProcesses[];
	selectedStage: ObjectStageIds;
	selectedProcess: ProcessIds;
	onSelectStage?: (stage: ObjectStageIds) => void;
	onSelectProcess?: (processId: ProcessIds) => void;
	getEntitySelectContainerSubTitle: (stageName: string, processName: string) => ReactNode;
	getEntitySelectContainerSubSubTitle: (
		objectName?: string,
		spaceTypeId?: string,
		spaceName?: string,
		selectedSpacesTypesAndNames?: ISpacesTypesAndNames[],
		structureTypeId?: string,
		structureName?: string,
		isMultiple?: boolean
	) => ReactNode;
	objectName?: string;
	spaceTypeId?: SpaceTypeId;
	spaceName?: string;
	spaces?: ISpace[];
	selectedSpaces?: string[];
	structureTypeId?: string;
	structureName?: string;
	entityButtonTitle?: string;
	stagesSelectDialogTitle?: string;
	stageTitle?: string;
	stagesSelectDialogSaveLabel?: string;
	stagesSelectDialogCancelLabel?: string;
	disable?: boolean;
	isMultiple?: boolean;
}

const EntitySelectContainer = ({
	contentMap,
	entitiesSelectMapProps,
	stages,
	selectedStage,
	selectedProcess,
	onSelectStage,
	onSelectProcess,
	getEntitySelectContainerSubTitle,
	getEntitySelectContainerSubSubTitle,
	objectName,
	spaceTypeId,
	spaceName,
	spaces,
	selectedSpaces,
	structureTypeId,
	structureName,
	entityButtonTitle,
	stagesSelectDialogTitle,
	stageTitle,
	stagesSelectDialogCancelLabel,
	stagesSelectDialogSaveLabel,
	disable,
	isMultiple
}: IEntitySelectContainerProps) => {
	const convertedStages = useMemo(() => convertStages(stages), [stages]);
	const [isOpen, setOpen] = useState(false);
	const open = useCallback(() => setOpen(true), []);
	const close = useCallback(() => setOpen(false), []);

	const title = useMemo(
		() => getTitle(selectedStage, selectedProcess, entitiesSelectMapProps, entityButtonTitle),
		[selectedStage, selectedProcess, entitiesSelectMapProps, entityButtonTitle]
	);
	const convertedSelectedStage = useMemo(
		() => convertedStages.find(val => val.id === selectedStage) || convertedStages[0],
		[convertedStages, selectedStage]
	);
	const processName = useMemo(
		() =>
			convertedSelectedStage?.processes.find(val => val.id === selectedProcess)?.name ||
			convertedSelectedStage?.processes[0].name,
		[convertedSelectedStage?.processes, selectedProcess]
	);

	const selectedSpacesTypesAndNames = useMemo(() => {
		if (!spaces || !selectedSpaces) {
			return [];
		}
		return selectedSpaces
			.map(id => spaces.find(space => space.id === id))
			.filter((item): item is IListSpace => !!item)
			.map(item => ({
				spaceTypeId: item.type,
				spaceName: item.name
			}));
	}, [spaces, selectedSpaces]);

	const entityIcon = useMemo(() => {
		const stage = convertedStages.find(val => val.id === selectedStage) || convertedStages[0];
		const process =
			stage?.processes.find(val => val.id === selectedProcess) || stage?.processes[0];
		return process?.entityIcon || null;
	}, [convertedStages, selectedProcess, selectedStage]);

	const handleConfirm = useCallback(
		(stage: ObjectStageIds, processId: ProcessIds) => {
			if (onSelectStage) {
				onSelectStage(stage);
			}
			if (onSelectProcess) {
				onSelectProcess(processId);
			}
			close();
		},
		[onSelectStage, onSelectProcess, close]
	);

	const handleCancel = useCallback(() => close(), [close]);

	return (
		<div className="entity-select-container">
			<div className="entity-select-container__entity-btn-container">
				<EntityButton
					className="entity-select-container__entity-btn"
					icon={entityIcon}
					iconColor={convertedSelectedStage?.color}
					title={title}
					subTitle={getEntitySelectContainerSubTitle(
						convertedSelectedStage?.name,
						processName
					)}
					subSubTitle={getEntitySelectContainerSubSubTitle(
						objectName,
						spaceTypeId,
						spaceName,
						selectedSpacesTypesAndNames,
						structureTypeId,
						structureName,
						isMultiple
					)}
					onClick={open}
					disable={disable}
				/>
			</div>
			{selectedStage && contentMap[selectedStage][selectedProcess]}
			{!disable && (
				<StagesSelectDialog
					isOpen={isOpen}
					title={stagesSelectDialogTitle}
					stageTitle={stageTitle}
					saveLabel={stagesSelectDialogSaveLabel}
					cancelLabel={stagesSelectDialogCancelLabel}
					onClose={close}
					onConfirm={handleConfirm}
					onCancel={handleCancel}
					defaultStage={selectedStage}
					defaultProcessId={selectedProcess}
					stages={convertedStages}
					entitiesSelectMapProps={entitiesSelectMapProps}
					isMultiple={isMultiple}
				/>
			)}
		</div>
	);
};

export default EntitySelectContainer;
