export type IGetPaginateUnitIndicatorsSetsQueryKey = [] & {
	0: string; // unit-indicators-sets
	1: string; // paginate
	2?: Record<string, unknown>; // filters
	3?: Record<string, boolean>; // sort
	4?: number; // offset
	5?: number; // limit
};

export type IGetListUnitIndicatorsSetsQueryKey = [] & {
	0: string; // unit-indicators-sets
	1: string; // list
	2?: Record<string, unknown>; // filters
};

export type IGetUnitIndicatorsSetQueryKey = [] & {
	0: string; // unit-indicators-set
	1: string; // detail
	2: string; // unitIndicatorsSetId
};
export const unitIndicatorsSetsQueryKeys = {
	all: () => ['unit-indicators-sets'],
	one: () => ['unit-indicator-set'],
	lists: () => [...unitIndicatorsSetsQueryKeys.all(), 'list'],
	list: (filters?: Record<string, unknown>) => [...unitIndicatorsSetsQueryKeys.lists(), filters],
	paginates: () => [...unitIndicatorsSetsQueryKeys.all(), 'paginate'],
	paginate: (
		filters?: Record<string, unknown>,
		sort?: Record<string, boolean>,
		offset?: number,
		limit?: number
	) => [...unitIndicatorsSetsQueryKeys.paginates(), filters, sort, offset, limit],
	details: () => [...unitIndicatorsSetsQueryKeys.one(), 'detail'],
	detail: (unitIndicatorsSetId: string) => [
		...unitIndicatorsSetsQueryKeys.details(),
		unitIndicatorsSetId
	]
};
