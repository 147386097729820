import {
	IGetListUnitIndicatorsResponse,
	IGetPaginateUnitIndicatorsResponse,
	requestGetListUnitIndicators,
	requestGetPaginateUnitIndicators
} from '@src/api/backend/unitIndicators';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {
	extractUnitIndicatorsAsArray,
	extractUnitIndicatorsAsArrayBySets,
	extractUnitIndicatorsAsMap,
	extractUnitIndicatorsAsMapBySets
} from './selectors';
import {unitIndicatorsQueryKeys} from '@src/api/cache/unitIndicators/keys';
import {useUnitIndicatorsSetsPermissions} from '../../permissions/useUnitIndicatorsSetsPermissions';
import {IGetUnitIndicatorResponse, requestGetUnitIndicator} from '@src/api/backend/unitIndicator';
import {useUnitIndicatorsSetsByCompanyId} from '../unitIndicatorsSets/hooks';
import {useCallback} from 'react';

export const useUnitIndicatorsPaginate = <T = IGetPaginateUnitIndicatorsResponse>(
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number,
	select?: (data: IGetPaginateUnitIndicatorsResponse) => T
) => {
	const permissions = useUnitIndicatorsSetsPermissions();
	return useQuery<IGetPaginateUnitIndicatorsResponse, IError, T>({
		queryKey: unitIndicatorsQueryKeys.paginate(filters, sort, offset, limit),
		queryFn: () => requestGetPaginateUnitIndicators(filters, sort, offset, limit),

		meta: {
			error: 'ошибка при получении списка индикаторов'
		},
		enabled: permissions.canView,
		select
	});
};

export const useUnitIndicatorsList = <T = IGetListUnitIndicatorsResponse>(
	filters: Record<string, unknown> = {},
	select?: (data: IGetListUnitIndicatorsResponse) => T
) => {
	const permissions = useUnitIndicatorsSetsPermissions();
	return useQuery<IGetListUnitIndicatorsResponse, IError, T>({
		queryKey: unitIndicatorsQueryKeys.list(filters),
		queryFn: () => requestGetListUnitIndicators(filters),

		meta: {
			error: 'ошибка при получении списка индикаторов'
		},
		enabled: permissions.canView,
		select
	});
};

export const useUnitIndicator = <T = IGetUnitIndicatorResponse>(
	unitIndicatorId: string,
	select?: (data: IGetUnitIndicatorResponse) => T
) => {
	const permissions = useUnitIndicatorsSetsPermissions();

	return useQuery<IGetUnitIndicatorResponse, IError, T>({
		queryKey: [...unitIndicatorsQueryKeys.details(), unitIndicatorId],
		queryFn: () => requestGetUnitIndicator(unitIndicatorId),

		meta: {
			error: 'ошибка при получении данных индикатора'
		},
		enabled: permissions.canView && !!unitIndicatorId,
		select
	});
};

export const useUnitIndicatorsByCompanyIdAsArray = (companyId?: string) => {
	const {data: sets} = useUnitIndicatorsSetsByCompanyId(companyId);
	const selector = useCallback(
		(data: IGetListUnitIndicatorsResponse) => extractUnitIndicatorsAsArrayBySets(data, sets),
		[sets]
	);
	return useUnitIndicatorsList({}, selector);
};

export const useUnitIndicatorsByCompanyIdAsMap = (companyId?: string) => {
	const {data: sets} = useUnitIndicatorsSetsByCompanyId(companyId);
	const selector = useCallback(
		(data: IGetListUnitIndicatorsResponse) => extractUnitIndicatorsAsMapBySets(data, sets),
		[sets]
	);
	return useUnitIndicatorsList({}, selector);
};

export const useUnitIndicatorsAsArray = (filters: Record<string, unknown> = {}) =>
	useUnitIndicatorsList(filters, extractUnitIndicatorsAsArray);

export const useUnitIndicatorsAsMap = (filters: Record<string, unknown> = {}) =>
	useUnitIndicatorsList(filters, extractUnitIndicatorsAsMap);
