import classNames from 'classnames';
import {useCallback, useEffect} from 'react';
import {TabButton} from '../../../../buttons';
import './EntitySelect.less';

export interface IEntity {
	id: string;
	number?: string | number;
	localNumber?: string;
}

export interface IEntitySelectProps {
	name: string;
	newName: string;
	selected?: string;

	withoutNew?: boolean;

	entities?: IEntity[];
	localEntities?: IEntity[];
	onEntityChange: (id?: string) => void;
	isMultiple?: boolean;
}

export const EntitySelect = ({
	name,
	newName,
	selected,
	withoutNew,
	entities,
	localEntities,
	onEntityChange,
	isMultiple
}: IEntitySelectProps) => {
	const handleChange = useCallback(
		(id?: string) => {
			if (onEntityChange) {
				onEntityChange(id);
			}
		},
		[onEntityChange]
	);

	useEffect(() => {
		if (isMultiple) {
			onEntityChange(undefined);
		}
	}, [isMultiple, onEntityChange]);

	return (
		<div className="entity-select">
			{!withoutNew && (
				<TabButton
					className={classNames('entity-select__btn', {active: !selected})}
					label={newName}
					active={!selected}
					value="new"
					key={`${name}__new`}
					onClick={() => handleChange(undefined)}
				/>
			)}
			{entities &&
				entities.map(({id, number, localNumber}) => (
					<TabButton
						className={classNames('entity-select__btn', {active: selected === id})}
						label={`${name} №${number ?? localNumber}`}
						active={selected === id}
						value={id}
						key={`${name}${id}`}
						onClick={handleChange}
					/>
				))}
			{localEntities &&
				localEntities.map(({id, number}) => (
					<TabButton
						className={classNames('entity-select__btn', {active: selected === id})}
						label={`${name} №${number}`}
						active={selected === id}
						value={id}
						key={`${name}${id}__local`}
						onClick={handleChange}
					/>
				))}
		</div>
	);
};
