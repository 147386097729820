import {CSSProperties, ReactElement, ReactNode} from 'react';
import './EntityInfo.less';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';
import {determineColors} from './utils/determineColors';

export interface IEntityInfoProps {
	className?:
		| string
		| {
				root?: string;
				title?: string;
				subTitle?: string;
				subSubTitle?: string;
				icon?: string;
		  };
	style?: CSSProperties;
	title?: string;
	subTitle?: ReactNode;
	subSubTitle?: ReactNode;
	icon?: ReactNode;
	iconColor?: string;
	iconSize?: 32 | 40 | 44;
	backgroundColor?: string;
	markers?: ReactElement;
}

export const EntityInfo = ({
	className,
	style,
	title,
	subTitle,
	subSubTitle,
	icon,
	iconColor,
	iconSize = 44,
	markers,
	backgroundColor
}: IEntityInfoProps) => {
	const classes = convertClassNames(className);

	const [icnColor, bgColor] = determineColors(iconColor, backgroundColor);

	return (
		<div
			className={classNames('entity-info', classes.root, {'entity-info_no-title': !title})}
			style={style}
		>
			{icon && (
				<div
					className={classNames(
						'entity-info__icon-wrap',
						`entity-info__icon-wrap_size_${iconSize}`,
						classes.icon
					)}
					style={{
						backgroundColor: bgColor,
						color: icnColor
					}}
				>
					{icon}
				</div>
			)}

			{title ? (
				<div className="entity-info__title-block">
					<div className={classNames('entity-info__title', classes.title)}>
						{title}
						{markers}
					</div>
					{subTitle !== undefined && (
						<div className={classNames('entity-info__sub-title', classes.subTitle)}>
							{subTitle}
						</div>
					)}
					{subSubTitle !== undefined && (
						<div
							className={classNames(
								'entity-info__sub-sub-title',
								classes.subSubTitle
							)}
						>
							{subSubTitle}
						</div>
					)}
				</div>
			) : null}
		</div>
	);
};
