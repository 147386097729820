import { useCallback } from 'react';
import {Button} from '@tehzor/ui-components';
import {useEditableWorkAcceptanceDialog} from '@src/components/EditableWorkAcceptanceDialog/hooks/useEditableWorkAcceptanceDialog';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useTranslation} from 'react-i18next';

interface IDesktopWorkAcceptanceAddBtnProps {
	objectId: string;
}

const plusIcon = <i className="tz-plus-20"/>;

export const DesktopWorkAcceptanceAddBtn = ({objectId}: IDesktopWorkAcceptanceAddBtnProps) => {
	const {t} = useTranslation();
	const [addingDialog, openAddingDialog] = useEditableWorkAcceptanceDialog(objectId, ObjectStageIds.BUILDING);

	const handleAdding = useCallback(() => {
		openAddingDialog();
	}, [openAddingDialog]);

	return (
		<>
			<Button
				type="accent-blue"
				leftIcon={plusIcon}
				label={t('addButton.label')}
				onClick={handleAdding}
			/>
			{addingDialog}
		</>
	);
};
