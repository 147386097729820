import {IGetChecksResponse} from '@src/api/backend/checks';
import {useIsFetching, useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';
import {checksQueryKeys} from '@src/api/cache/checks/keys';
import {extractChecksAsArray, extractLocalSpaceChecks, extractChecksTotal} from './selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractChecksPageSettings} from '@src/store/modules/settings/pages/checks/selectors';
import {IChecksFiltersState} from '@src/store/modules/settings/pages/checks/interfaces';
import IListCheck from '@tehzor/tools/interfaces/checks/IListCheck';
import {extractOfflineModeAvailability} from '@src/store/modules/settings/offlineMode/selectors/availability';
import {useCallback, useMemo} from 'react';
import {useExtractLatestChecksAsArray} from './latest/hooks';
import {extractNetworkStatus} from '@src/store/modules/offlineMode/selectors';

export const useChecks = <T = IGetChecksResponse>(
	select?: (data: IGetChecksResponse) => T,
	filters?: IChecksFiltersState,
	objectId = 'all'
) => {
	const checksPageSettings = useAppSelector(s => extractChecksPageSettings(s, objectId));

	return useQuery<IGetChecksResponse, IError, T>({
		queryKey: [
			...checksQueryKeys.list(),
			filters || checksPageSettings?.filters,
			checksPageSettings?.sort,
			checksPageSettings?.offset,
			checksPageSettings?.pageSize,
			objectId
		],
		meta: {
			error: 'при загрузке списка проверок'
		},
		select
	});
};

export const useLocalChecks = <T = IListCheck[]>(
	objectId = 'all',
	select?: (data: IListCheck[]) => T
) => {
	const isOfflineModeAvailable = useAppSelector(extractOfflineModeAvailability);
	return useQuery<IListCheck[], IError, T>({
		queryKey: [...checksQueryKeys.localList(), objectId],
		staleTime: 0,
		gcTime: Infinity,
		networkMode: 'offlineFirst',
		enabled: isOfflineModeAvailable,
		select
	});
};

export const useExtractLocalSpaceChecks = (objectId: string, spaceId: string) => {
	const selector = useCallback(
		(data: IListCheck[]) => extractLocalSpaceChecks(data, spaceId),
		[spaceId]
	);
	return useLocalChecks(objectId, selector);
};

export const useChecksIsLoading = () => !!useIsFetching({queryKey: checksQueryKeys.list()});

export const useChecksQuery = (objectId?: string, filters?: IChecksFiltersState) =>
	useChecks(undefined, filters, objectId);

/**
 * Возвращает проверки в виде массива
 */
export const useExtractChecksAsArray = (objectId?: string) =>
	useChecks(extractChecksAsArray, undefined, objectId);

export const useMergedSpaceLocalAndLatestChecks = (objectId: string, spaceId: string) => {
	const offlineMode = useAppSelector(extractOfflineModeAvailability);
	const isOnline = useAppSelector(extractNetworkStatus);
	const appIsOffline = offlineMode && !isOnline;
	const {data: latestChecks} = useExtractLatestChecksAsArray(objectId, spaceId);
	const {data: localChecks} = useExtractLocalSpaceChecks(objectId, spaceId);

	return useMemo(
		() => (appIsOffline ? [...(latestChecks ?? []), ...(localChecks ?? [])] : localChecks),
		[latestChecks, localChecks, appIsOffline]
	);
};

export const useChecksTotal = (objectId: string) =>
	useChecks(extractChecksTotal, undefined, objectId);
