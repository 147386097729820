import './ToolBarDialog.less';
import Modal from 'react-modal';
import classNames from 'classnames';
import {ReactNode} from 'react';

interface IToolBarDialogProps {
	className?: string;
	children?: ReactNode;
	isOpen: boolean;
	onRequestClose?(): void;
}

export const ToolBarDialog = (props: IToolBarDialogProps) => {
	const {className, children, isOpen, onRequestClose} = props;

	const classes = classNames('toolbar-dialog', className);

	return (
		<>
			<Modal
				isOpen={isOpen}
				className={classes}
				overlayClassName="toolbar-dialog-overlay"
				onRequestClose={onRequestClose}
				ariaHideApp={false}
				closeTimeoutMS={100}
				shouldCloseOnOverlayClick={false}
				shouldCloseOnEsc
				parentSelector={() => document.body}
			>
				{children}
			</Modal>
		</>
	);
};
