import {makeCheckAddRequest} from '@src/api/backend/check';
import {addCheckActions, deleteCheckActions} from '@src/store/modules/entities/check/actions';
import {useQueryClient} from '@tanstack/react-query';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useAddChildren} from '../../hooks/useAddChildren';
import {useUpdateEntity} from '../../hooks/useUpdateEntityList';
import {checksQueryKeys} from '../keys';
import {spacesQueryKeys} from '../../spaces/keys';

export interface IAddCheckParams {
	key: string;
	objectId: string;
	links: ICheck['links'] | undefined;
	stage?: ObjectStageIds;
}
/**
 * Хук для предачи дефолтной функции мутации в QueryClient
 *  - Дефолтная функия нужна для того, чтобы не указывть её в самом хуке мутации явно
 *  - Если после запуска приложения в кэше будет лежать незаврешенная мутация для этого ключа,
 * 		то для повтра мутации будет использована mutationFn отсюда
 */
export const useChecksMutationDefaults = () => {
	const queryClient = useQueryClient();
	const [addProblems, addInspections] = useAddChildren();
	const {updateEntity} = useUpdateEntity(checksQueryKeys);
	queryClient.setMutationDefaults(checksQueryKeys.add(), {
		mutationFn: async (params: IAddCheckParams) => {
			const {objectId, links, stage, key} = params;
			return makeCheckAddRequest(objectId, links, key, stage);
		},
		onSuccess: async (newCheck: ICheck, {key}: IAddCheckParams) => {
			updateEntity<ICheck>(
				newCheck,
				deleteCheckActions.success,
				addCheckActions.success,
				key
			);
			addProblems(key, newCheck.id, 'checkId');
			addInspections(key, newCheck.id, 'checkId');
			await queryClient.invalidateQueries({queryKey: checksQueryKeys.list()});
			await queryClient.refetchQueries({
				queryKey: checksQueryKeys.localList()
			});
			await queryClient.invalidateQueries({
				queryKey: checksQueryKeys.latest()
			});
			await queryClient.invalidateQueries({
				queryKey: spacesQueryKeys.checks()
			});
		}
	});
};
