import MobileMenu from './Menu.mobile';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {useCurrentTreeObject} from '@src/core/hooks/queries/objects/hooks';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';

interface IMobileActionsProps {
	objectId: string;
}

const MobileActions = ({objectId}: IMobileActionsProps) => {
	const permissions = useSpacesPermissions(objectId);
	const object = useCurrentTreeObject(objectId);
	const lastObject = object ? !object.children?.length : false;
	const {schemaView, displayMode} = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const isOnline = useOnlineManager();
	const hasSpaces = object?.hasSpaces;

	return (
		<MobileMenu
			objectId={objectId}
			canAddSpace={permissions.canAdd && lastObject}
			canExportSpaces={permissions.canExport && lastObject}
			canOpenSettings={schemaView !== SpacesSchemaVariants.REPORTS}
			canSelectSpaces={displayMode !== SpacesDisplayMode.LIST && hasSpaces && isOnline}
		/>
	);
};

export default MobileActions;
