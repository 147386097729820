import Checkbox from '../../../inputs/checkbox/Checkbox';
import {TableToggleCommonProps} from 'react-table';
import classNames from 'classnames';

const TableRowSelect = ({
	className,
	style,
	checked,
	indeterminate,
	onChange
}: TableToggleCommonProps) => (
	<Checkbox
		className={classNames('table2__row-select-checkbox', className)}
		style={style}
		checked={checked || indeterminate}
		semiChecked={indeterminate}
		onChange={onChange}
	/>
);

export default TableRowSelect;
