import {useMemo} from 'react';
import {useObject, useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {useSpaceStatuses} from '@src/core/hooks/queries/spaceStatuses/hooks';
import {useSpacesListWithStatsAsArray} from '../../../core/hooks/queries/spaces/hooks';
import {enrichSpaces} from '../../../core/hooks/queries/spaces/selectors';
import {useUnitIndicatorsByCompanyIdAsMap} from '@src/core/hooks/queries/unitIndicators/hooks';

export function useEnrichedList(objectId: string) {
	const {data: targetObjects} = useTargetObjects(objectId);
	const {data: spaces} = useSpacesListWithStatsAsArray(objectId, {objects: targetObjects});
	const {data: spaceStatuses} = useSpaceStatuses();
	const {data: object} = useObject(objectId);
	const {data: spaceIndicators} = useUnitIndicatorsByCompanyIdAsMap(object?.companyId);
	return useMemo(
		() =>
			spaceIndicators && spaceStatuses && spaces
				? enrichSpaces(spaces, spaceStatuses.byId, spaceIndicators.byId)
				: [],
		[spaceIndicators, spaceStatuses, spaces]
	);
}
