import {useQuery} from '@tanstack/react-query';
import {IGetTasksResponse} from '@src/api/backend/tasks';
import {tasksQueryKeys} from '@src/api/cache/tasks/keys';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useCallback} from 'react';
import {
	extractTasksCollectMode,
	extractTasksDisplayMode,
	extractTasksListSettings,
	extractTasksScheduleSettings
} from '@src/store/modules/settings/pages/tasks/selectors';
import {ITasksFiltersState} from '@src/store/modules/settings/pages/tasks/interfaces';
import {extractTasksAsArray, filterByLinks} from '@src/core/hooks/queries/tasks/selectors';
import {ITask} from '@tehzor/tools/interfaces/tasks/ITask';
import {ICheckDetailsEntity} from '@src/interfaces/ICheckDetailsEntity';

export const useTasks = <T = IGetTasksResponse>(
	state?: ITasksFiltersState,
	select?: (data: IGetTasksResponse) => T
) => {
	const {offset, filters, sort, pageSize} = useAppSelector(extractTasksListSettings);
	return useQuery({
		queryKey: tasksQueryKeys.list(state || filters, sort, offset, pageSize),
		meta: {
			error: 'при загрузке списка задач'
		},
		select
	});
};

export const useTasksForSchedule = <T = IGetTasksResponse>(
	state?: ITasksFiltersState,
	select?: (data: IGetTasksResponse) => T
) => {
	const {filters} = useAppSelector(extractTasksScheduleSettings);
	return useQuery({
		queryKey: [...tasksQueryKeys.schedule(), state || filters, {}],
		meta: {
			error: 'при загрузке графика задач'
		},
		select
	});
};

export const useTasksAsArray = (state?: ITasksFiltersState) => {
	const select = useCallback((data: IGetTasksResponse) => extractTasksAsArray(data), [state]);
	return useTasks(state, select);
};

interface ITasksFiltersByLinks {
	spaceId?: string;
	workAcceptanceId?: string;
}

export const useTasksByLinks = (
	state?: ITasksFiltersState,
	filtersByLinks?: ITasksFiltersByLinks
) => {
	const selector = useCallback(
		(data: IGetTasksResponse): ITask[] => {
			const tasks = extractTasksAsArray(data);
			return filterByLinks(tasks, filtersByLinks?.spaceId, filtersByLinks?.workAcceptanceId);
		},
		[filtersByLinks]
	);
	return useTasks(state, selector);
};

export const useTasksByLinksAsDetailsEntity = (
	state?: ITasksFiltersState,
	filtersByLinks?: ITasksFiltersByLinks
) => {
	const selector = useCallback(
		(data: IGetTasksResponse): ICheckDetailsEntity[] => {
			const tasks = extractTasksAsArray(data);
			return filterByLinks(
				tasks,
				filtersByLinks?.spaceId,
				filtersByLinks?.workAcceptanceId
			).map(task => ({id: task.id, type: 'task', data: task}));
		},
		[filtersByLinks]
	);
	return useTasks(state, selector);
};

export const useTasksPageDisplayMode = () => useAppSelector(extractTasksDisplayMode);
export const useTasksScheduleCollectMode = () => useAppSelector(extractTasksCollectMode);
