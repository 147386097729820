import {OutputFileType} from '@tehzor/tools/interfaces/IExportTemplate';
import {IExportLimits} from '@tehzor/tools/interfaces/export/IExportLimits';

export const getCurrentTemplateTypeCount = (
	currentTemplateType: string | undefined,
	exportLimitsByEntity: IExportLimits
) => {
	switch (currentTemplateType) {
		case OutputFileType.XLSX:
			return exportLimitsByEntity.xlsx;
		case OutputFileType.DOCX:
			return exportLimitsByEntity.docx;
		default:
			return Infinity;
	}
};
