import {IFieldSetting, ValueTypeId} from '@tehzor/tools/interfaces/fields';
import {EntityGridItem} from '@tehzor/ui-components';
import {memo} from 'react';
import './CustomField.less';
import {getValue} from './utils/getValue';
import {useCustomFieldById} from '@/entities/Fields';

interface ICustomFieldProps {
	fields?: {customFields?: Record<string, unknown>};
	setting: IFieldSetting;
	inline?: boolean;
	className?:
		| string
		| {
				root?: string;
				header?: string;
				content?: string;
				title?: string;
				icon?: string;
		};
}

const getIcon = (icon?: string) => (icon ? <i className={icon} /> : undefined);

const CustomFieldComponent = ({fields, setting, inline, className}: ICustomFieldProps) => {
	const {data: field} = useCustomFieldById(setting.fieldId);

	if (!field || !fields?.[field.key]) {
		return null;
	}

	const fullRow =
		field.valueType.id === ValueTypeId.TEXT || field.valueType.id === ValueTypeId.LINK;

	const value = getValue(fields?.[field.key], field.valueType.id);

	return (
		<EntityGridItem
			icon={getIcon(field.icon)}
			label={field.name}
			fullRow={fullRow}
			inline={inline}
			className={className}
		>
			{value}
		</EntityGridItem>
	);
};

export const CustomField = memo(CustomFieldComponent);
