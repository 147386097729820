import {IGetListUnitIndicatorsResponse} from '@src/api/backend/unitIndicators';
import {IUnitIndicator} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicator';
import {IUnitIndicatorsSet} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicatorsSet';
import {normalizeData} from '@tehzor/tools/utils/normalizeData';

/**
 * Возвращает индикаторы в виде массива
 */
export const extractUnitIndicatorsAsArray = (data: IGetListUnitIndicatorsResponse) =>
	data.allIds.map((id: string) => data.byId[id]);

/**
 * Возвращает индикаторы в виде набора
 */

export const extractUnitIndicatorsAsMap = (data: IGetListUnitIndicatorsResponse) => data.byId;

/**
 * Возвращает массив индикаторов по массиву наборов индикаторов
 */
export const extractUnitIndicatorsAsArrayBySets = (
	data: IGetListUnitIndicatorsResponse,
	sets?: IUnitIndicatorsSet[]
) =>
	extractUnitIndicatorsAsArray(data).filter(
		indicator => sets?.some(set => set.id === indicator.indicatorsSetId)
	);

/**
 * Возвращает мапу индикаторов по массиву наборов индикаторов
 */
export const extractUnitIndicatorsAsMapBySets = (
	data: IGetListUnitIndicatorsResponse,
	sets?: IUnitIndicatorsSet[]
) => {
	const indicatorsArray = extractUnitIndicatorsAsArrayBySets(data, sets);
	return normalizeData<IUnitIndicator>(indicatorsArray);
};
