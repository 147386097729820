import React, {Suspense, useCallback} from 'react';
import {Button} from '@tehzor/ui-components';
import '../../SpacesPage.less';
import {useSpacesStageSelectionDialog} from '../SpacesStageSelectionDialog/useSpacesStageSelectionDialog';
import {MobileSchemaLoader} from '@tehzor/ui-components/src/components/loaders/skeletons';
import {extractSelectedSpacesForObjectIds} from '@src/store/modules/settings/pages/spaces/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useStrictParams} from '@src/core/hooks/useStrictParams';

interface SpaceChoosingToolbarMobileProps {
	objectId: string;
	isBtnDisabled: boolean;
}

const addIcon = <i className="tz-plus-20" />;

export const SpaceChoosingToolbarMobile: React.FC<SpaceChoosingToolbarMobileProps> = ({
	objectId,
	isBtnDisabled
}) => {
	// Модальное окно с выбором стадии сущности
	const {spaceStageSelectionDialog, openStageSelectionDialog, globalDialog, openGlobalDialog} =
		useSpacesStageSelectionDialog({
			objectId
		});
	const {objectId: pageObjectId} = useStrictParams<{objectId: string}>();
	const {selectedSpaces} = useAppSelector(state =>
		extractSelectedSpacesForObjectIds(state, pageObjectId, [objectId])
	);

	const handleOpenStageSelection = useCallback(() => {
		if (selectedSpaces.length > 1) {
			openStageSelectionDialog(true);
		} else {
			openGlobalDialog();
		}
	}, [selectedSpaces.length, openStageSelectionDialog, openGlobalDialog]);
	return (
		<>
			<div className="spaces-page__toolbar-mobile-opendialog-btn">
				<Button
					type="accent-blue"
					leftIcon={addIcon}
					disabled={isBtnDisabled}
					className="spaces-page__toolbar-mobile-opendialog-btn"
					label="Добавить"
					onClick={handleOpenStageSelection}
				/>
			</div>
			<Suspense fallback={<MobileSchemaLoader />}>
				{objectId && spaceStageSelectionDialog}
				{objectId && selectedSpaces.length === 1 && globalDialog}
			</Suspense>
		</>
	);
};
