import {checkRecordsQueryKeys} from '@src/api/cache/checkRecords/keys';
import {plansQueryKeys} from '@src/api/cache/plans/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {queryClient} from '@src/api/QueryClient';

export const refetchCache = async (queryKeys: string[][], objects?: string[]) => {
	if (!objects || !objects.length) return;
	const promises = [];
	if (queryKeys.length) {
		for (const queryKey of queryKeys) {
			if (queryKey.join() === plansQueryKeys.lists().join()) {
				await queryClient.refetchQueries({
					queryKey: plansQueryKeys.list(objects),
					exact: true
				});
				continue;
			}
			promises.push(async () => queryClient.refetchQueries({queryKey, exact: true}));
		}
	}
	await Promise.allSettled(promises.map(async query => query()));
	// Кеширование помещений
	await queryClient.refetchQueries({
		queryKey: [...spacesQueryKeys.list(), {objects}, undefined, undefined, 100000]
	});
	await queryClient.refetchQueries({
		queryKey: [...spacesQueryKeys.listWithStats(), {objects}, undefined, undefined, 100000]
	});
	await queryClient.refetchQueries({queryKey: spacesQueryKeys.typesCount(objects)});
	await queryClient.refetchQueries({
		queryKey: [...checkRecordsQueryKeys.list(), undefined, objects]
	});
};
