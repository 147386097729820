import {startTransition, useCallback, useEffect, useMemo, useState} from 'react';
import {useAsync, useUpdateEffect} from 'react-use';
import {
	ActionButtons,
	Button,
	ModalDialog,
	Select2,
	SelectOption,
	SelectPopup,
	SelectSearch,
	TextFieldWithForwardedRef,
	TreeSelect
} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {makeObjectsTreeData} from '@src/utils/makeObjectsTreeData';
import {treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {IListSpace} from '@tehzor/tools/interfaces/spaces/IListSpace';
import {useObjectsIds, useObjectsMap} from '@src/core/hooks/queries/objects/hooks';
import {useSpacesAsArray} from '@src/core/hooks/queries/spaces/hooks';
import {useExtractSpaceTypesById} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useTranslation} from 'react-i18next';
import {useMoveCheck} from '@src/core/hooks/mutations/checks/useMoveCheck';
import './MovingCheckDialog.less';
import {ILinkedCheck} from '@tehzor/tools/interfaces/checks/ILinkedCheck';
import {SpaceTypeId} from '@tehzor/tools/interfaces/spaces/ISpaceType';
import {queryClient} from '../../api/QueryClient';
import {spacesQueryKeys} from '../../api/cache/spaces/keys';

const arrowIcon = <i className="tz-simple-arrow-20" />;

interface IMovingCheckDialogProps {
	objectId: string;
	check: ILinkedCheck;
	isOpen: boolean;

	onClose: () => void;
}

/**
 * Окно перемещения проверки
 */
export const MovingCheckDialog = ({objectId, check, isOpen, onClose}: IMovingCheckDialogProps) => {
	const {t} = useTranslation();
	const {replacePath} = useChangePath();

	const objectsMap = useObjectsMap();
	const {data: objectIds} = useObjectsIds();
	const {data: spaceTypesMap} = useExtractSpaceTypesById();
	const [objectSearch, setObjectSearch] = useState('');
	const [spaceSearch, setSpaceSearch] = useState('');

	const clearSpaceSearch = useCallback(() => setSpaceSearch(''), []);
	const clearObjectSearch = useCallback(() => setObjectSearch(''), []);

	const {filteredData, expanded} = useMemo(() => {
		const treeData = makeObjectsTreeData(
			objectIds && objectsMap ? objectIds.map(id => objectsMap[id]) : []
		);
		return treeFilter(treeData, 'content', objectSearch);
	}, [objectsMap, objectIds, objectSearch]);

	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);
	useUpdateEffect(() => {
		setExpandedObjects(expanded?.map(item => item.id));
	}, [expanded]);

	const [toObjectId, setToObjectId] = useState(objectId);
	const [toSpaceId, setToSpaceId] = useState(check.links?.spaceId);

	const [spaceTypeChange, setSpaceTypeChange] = useState(false);
	const [objectChange, setObjectChange] = useState(false);

	const {data: spaces} = useSpacesAsArray(toObjectId);
	const spaceTypeIds = useMemo(
		() => Array.from(new Set(spaces.map(space => space.type))),
		[spaces]
	);
	const [toSpaceType, setToSpaceType] = useState<SpaceTypeId | undefined>(check?.space?.type);

	const handleClose = useCallback(() => {
		onClose();
		setToObjectId(objectId);
		setToSpaceType(check?.space?.type);
		setToSpaceId(check.links?.spaceId);
		setSpaceTypeChange(false);
		setObjectChange(false);
	}, [check.links?.spaceId, check?.space?.type, objectId]);

	const handleObjectChange = useCallback((value: string) => {
		startTransition(() => {
			setToObjectId(value);
			setObjectChange(true);
			setSpaceTypeChange(true);
		});
	}, []);

	const handleSpaceTypeChange = useCallback((value: SpaceTypeId) => {
		setToSpaceType(value);
		setSpaceTypeChange(true);
	}, []);

	const handleSpaceChange = useCallback((value: string) => {
		setToSpaceId(value);
	}, []);

	useAsync(async () => {
		await queryClient.invalidateQueries({
			queryKey: [...spacesQueryKeys.listWithStats(), {objects: [toObjectId]}]
		});
	}, [toObjectId]);

	const filtredSpace = spaces
		.filter(
			el =>
				toSpaceType &&
				el.type === toSpaceType &&
				el.name.toLowerCase().indexOf(spaceSearch.toLowerCase()) > -1
		)
		.sort((a: IListSpace, b: IListSpace) => parseInt(a.name) - parseInt(b.name));

	useEffect(() => {
		if (spaceTypeIds.length && objectChange) {
			setToSpaceType(spaceTypeIds[0]);
		}
	}, [spaceTypeIds, objectChange]);

	useEffect(() => {
		if (toObjectId && toSpaceType && spaceTypeChange && check.links?.spaceId) {
			const newSpaces = spaces.filter(space => space.type === toSpaceType);
			if (newSpaces.length > 0) {
				setToSpaceId(newSpaces[0].id);
			}
		}
	}, [toObjectId, toSpaceType, spaces, spaceTypeChange, check.links?.spaceId]);

	const {mutateAsync: moveCheck} = useMoveCheck();

	const handleSave = useCallback(async () => {
		const newCheck = await moveCheck({
			objectId,
			checkId: check.id,
			toObjectId,
			toSpaceId
		});
		onClose();
		replacePath(`/objects/${toObjectId}/checks/${newCheck.id}`);
	}, [objectId, check, toObjectId, toSpaceId]);

	return (
		<ModalDialog
			open={isOpen}
			title={t('checksPage.actions.menu.move')}
			onRequestClose={handleClose}
			className={{
				root: 'moving-check-dialog'
			}}
			fullScreenOnMobile
			footer={
				<ActionButtons>
					<Button
						type="accent-blue"
						label={t('components.movingCheckDialog.dialog.button.accept')}
						onClick={handleSave}
					/>
					<Button
						type="cancel"
						label={t('components.movingCheckDialog.dialog.button.cancel')}
						onClick={handleClose}
					/>
				</ActionButtons>
			}
		>
			<div className="moving-check-dialog__content">
				<div>
					<div className="moving-check-dialog__select-title">
						{t('components.movingCheckDialog.newObject')}
					</div>
					{objectsMap && (
						<SelectPopup
							search={
								<SelectSearch
									value={objectSearch}
									onChange={setObjectSearch}
									onClear={clearObjectSearch}
									placeholder={t('components.movingCheckDialog.selectSearch')}
								/>
							}
							trigger={
								<TextFieldWithForwardedRef
									elementType="div"
									value={objectsMap[toObjectId]?.name}
									icon={arrowIcon}
								/>
							}
						>
							<TreeSelect
								data={filteredData}
								value={toObjectId || undefined}
								onChange={handleObjectChange}
								latestOnly
								expandedValue={expandedObjects}
								onExpand={setExpandedObjects}
							/>
						</SelectPopup>
					)}
				</div>

				<div>
					<div className="moving-check-dialog__select-title">
						{t('components.movingCheckDialog.spaceType')}
					</div>
					<SelectPopup
						noHeader
						trigger={
							<TextFieldWithForwardedRef
								elementType="div"
								disabled={!check.links?.spaceId}
								value={toSpaceType && spaceTypesMap?.[toSpaceType]?.name}
								icon={arrowIcon}
							/>
						}
					>
						<Select2
							value={toSpaceType}
							onChange={handleSpaceTypeChange}
						>
							{spaceTypeIds.map(id => (
								<SelectOption
									inputType="radio"
									key={id}
									itemKey={id}
									content={spaceTypesMap?.[id]?.name}
								/>
							))}
						</Select2>
					</SelectPopup>
				</div>

				<div>
					<div className="moving-check-dialog__select-title">
						{t('components.movingCheckDialog.newSpace')}
					</div>
					<SelectPopup
						search={
							<SelectSearch
								value={spaceSearch}
								onChange={setSpaceSearch}
								onClear={clearSpaceSearch}
								placeholder={t('components.movingCheckDialog.selectSearch')}
							/>
						}
						trigger={
							<TextFieldWithForwardedRef
								elementType="div"
								disabled={!check.links?.spaceId}
								value={
									check.links?.spaceId
										? spaces.find(s => s.id === toSpaceId)?.name
										: undefined
								}
								icon={arrowIcon}
							/>
						}
					>
						<Select2
							value={toSpaceId}
							onChange={handleSpaceChange}
						>
							{filtredSpace.map(item => (
								<SelectOption
									key={item.id}
									itemKey={item.id}
									content={item.name}
									inputType="radio"
								/>
							))}
						</Select2>
					</SelectPopup>
				</div>
			</div>
		</ModalDialog>
	);
};
