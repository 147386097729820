import {useMemo} from 'react';
import {FloatingActionButton} from '@tehzor/ui-components';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useGlobalAddingEntityDialog} from '@src/components/GlobalAddingEntityDialog/hooks/useGlobalAddingEntityDialog';
import {useChecksPermissions} from '@src/core/hooks/permissions/useChecksPermissions';
import {useOwnerAcceptancesPermissions} from '@src/core/hooks/permissions/useOwnerAcceptancesPermissions';
import {useWarrantyClaimsPermissions} from '@src/core/hooks/permissions/useWarrantyClaimsPermissions';
import {useInternalAcceptancesPermissions} from '@src/core/hooks/permissions/useInternalAcceptancesPermissions';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useSpacesListQuery} from '@src/core/hooks/queries/spaces/hooks';
import {useTranslation} from 'react-i18next';
import {useStagesAndProcessesMap} from '@src/core/hooks/processes/useProcessesMap';
import {usePresaleChecksPermissions} from '@/entities/PresaleCheck/model/hooks/usePresaleChecksPermissions';

const types: ICheckAddingEntityType[] = ['problem'];

interface IProblemAddingMobileProps {
	objectId: string;
}
const plusIcon = <i className="tz-plus-16" />;

const ProblemAddingMobile = ({objectId}: IProblemAddingMobileProps) => {
	const stagesAndProcessesMap = useStagesAndProcessesMap();
	const {data: object} = useObject(objectId);
	const {data: spaces, isFetching} = useSpacesListQuery(objectId);
	const {t} = useTranslation();

	const checksPermissions = useChecksPermissions(objectId, 'problems');
	const ownersAcceptancesPermissions = useOwnerAcceptancesPermissions(objectId, 'problems');
	const warrantyClaimPermissions = useWarrantyClaimsPermissions(objectId, 'problems');
	const internalAcceptancePermissions = useInternalAcceptancesPermissions(objectId, 'problems');
	const presaleChecksPermissions = usePresaleChecksPermissions(objectId, 'problems');

	const permissions = useMemo(
		() => ({
			checks: checksPermissions.canAdd,
			acceptances: ownersAcceptancesPermissions.canAdd,
			claims: warrantyClaimPermissions.canAdd,
			internalAcceptances: internalAcceptancePermissions.canAdd,
			presaleChecks: presaleChecksPermissions.canAdd
		}),
		[
			checksPermissions,
			ownersAcceptancesPermissions,
			warrantyClaimPermissions,
			internalAcceptancePermissions,
			presaleChecksPermissions
		]
	);

	const [checkDialog, openCheckDialog] = useAddingCheckDialog({
		objectId,
		stage: ObjectStageIds.BUILDING,
		types
	});
	const defaultStageId = object?.stage || ObjectStageIds.BUILDING;
	const defaultProcessId = stagesAndProcessesMap[defaultStageId][0];

	const [globalDialog, openGlobalDialog] = useGlobalAddingEntityDialog(
		objectId,
		undefined,
		undefined,
		defaultStageId,
		defaultProcessId,
		permissions,
		undefined,
		undefined,
		types
	);

	return (
		<>
			<FloatingActionButton
				icon={plusIcon}
				label={t('problemsPage.addButton.label')}
				disabled={isFetching}
				minifyOnScroll
				scrollContainer="scroll-container"
				onClick={
					object?.stage === ObjectStageIds.BUILDING && spaces?.allIds.length === 0
						? openCheckDialog
						: openGlobalDialog
				}
			/>
			{object?.stage === ObjectStageIds.BUILDING && spaces?.allIds.length === 0
				? checkDialog
				: globalDialog}
		</>
	);
};

export default ProblemAddingMobile;
