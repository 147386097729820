export const exportInspectionsLimit = {
	docx: 100,
	xlsx: 1000
};

export const exportChecksLimit = {
	docx: 100,
	xlsx: 1000
};

export const exportProblemsLimit = {
	docx: 100,
	xlsx: 350
};
