import {useCallback} from 'react';
import {IconButton} from '@tehzor/ui-components';
import {useSpacesSelection} from '../../hooks/useSpacesSelection';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {deselectAllSpaces} from '@src/store/modules/settings/pages/spaces/actions';

interface IMobileSelectionClearProps {
	objectId: string;
}

export const MobileSelectionClearing = ({objectId}: IMobileSelectionClearProps) => {
	const dispatch = useAppDispatch();
	const {toggleChoosing} = useSpacesSelection(objectId);

	const handleClick = useCallback(() => {
		dispatch(deselectAllSpaces(objectId));
		toggleChoosing(false);
	}, [objectId, dispatch, toggleChoosing]);

	return (
		<IconButton
			onClick={handleClick}
			type="inline-red-accent"
		>
			<i className="tz-close-24" />
		</IconButton>
	);
};
