import {IBriefUser} from './users/IBriefUser';

export enum ExportTemplatesTypeId {
	CHECKS_PROBLEMS = 'checks-problems',
	CHECKS = 'checks',
	CHECK = 'check',
	PROBLEMS = 'problems',
	PROBLEM = 'problem',
	COMMENT = 'comment',
	INSPECTIONS = 'inspections',
	INSPECTION = 'inspection',
	WARRANTY_CLAIMS = 'warranty-claims',
	WARRANTY_CLAIM = 'warranty-claim',
	INTERNAL_ACCEPTANCES = 'internal-acceptances',
	INTERNAL_ACCEPTANCE = 'internal-acceptance',
	WORK_ACCEPTANCES = 'work-acceptances',
	WORK_ACCEPTANCE = 'work-acceptance',
	SPACE = 'space',
	UNIT_HANDOVER = 'unit-handover',
	CHECKLIST = 'check-list',
	CHECKLISTS = 'check-lists',
	OBJECT = 'object'
}

export enum ExportTemplatesDestinationId {
	CHECKS_PROBLEMS = 'checksProblems',
	CHECKS = 'checks',
	CHECK = 'check',
	PROBLEMS = 'problems',
	PROBLEM = 'problem',
	PROBLEM_REPLY = 'problemReply',
	INSPECTIONS = 'inspections',
	INSPECTION = 'inspection',
	WARRANTY_CLAIMS = 'warranty-claims',
	WARRANTY_CLAIM = 'warranty-claim',
	INTERNAL_ACCEPTANCES = 'internal-acceptances',
	INTERNAL_ACCEPTANCE = 'internal-acceptance',
	WORK_ACCEPTANCE = 'work-acceptance',
	WORK_ACCEPTANCES = 'work-acceptances',
	SPACE = 'space',
	OWNER_ACCEPTANCE = 'owner-acceptance',
	CHECKLIST = 'check-list',
	CHECKLISTS = 'check-lists',
	OBJECT = 'object'
}
export interface IExportTemplatesDestination {
	id: ExportTemplatesDestinationId;
	name: string;
}

export enum ImageType {
	PLAN_IMAGE = 'planImage',
	ATTACHMENT_IMAGE = 'attachmentImage',
	FIRST_IMAGE = 'firstImage',
	CONVERTED_PLAN = 'convertedPlan',
	CONVERTED_ATTACHMENT = 'convertedAttachment'
}

export enum OutputFileType {
	DOCX = 'docx',
	XLSX = 'xlsx',
	PDF = 'pdf'
}

export default interface IExportTemplate {
	id: string;
	s3Name?: string;
	companyId?: string;
	objects?: string[];
	name: string;
	description?: string;
	company?: {
		id: string;
		name: string;
	};
	objectId?: string;
	destination: ExportTemplatesDestinationId;
	outputFileType: OutputFileType;
	prefix: string;
	fileNameTemplate: string;
	file?: {
		id: string;
		s3Name: string;
		type: string;
		size?: number;
	};
	documentTypeId?: string;
	options: {
		imageSizes: Record<ImageType, {width: number, height: number}>;
		sort?: {
			[x: string]: 1 | -1;
		};
	};
	createdAt?: number;
	createdBy?: IBriefUser;
	modifiedAt?: number;
	modifiedBy?: IBriefUser;
};
