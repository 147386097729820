import {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import {extractWarrantyClaimsPageSettings} from '@src/store/modules/settings/pages/warrantyClaims/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	changeOffset,
	changePageSize,
	changeSelectedRows,
	changeSort
} from '@src/store/modules/settings/pages/warrantyClaims/slices';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries/hooks';
import {getMobileColumns} from './mobileColumns';
import {convertClaims} from '../../utils/convertClaims';
import {IPreparedClaim} from '../../interfaces/IPreparedClaim';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {
	useLocalWarrantyClaims,
	useWarrantyClaimsAsArray,
	useWarrantyClaimsStatsData
} from '@src/core/hooks/queries/warrantyClaims/hooks';
import {queryClient} from '@src/api/QueryClient';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import {useWarrantyClaimStatusesMap} from '@src/core/hooks/queries/warrantyClaimsStatuses/hooks';
import {IGetWarrantyClaimsResponse} from '@src/api/backend/warrantyClaims';
import {useDeleteWarrantyClaim} from '@src/core/hooks/mutations/warrantyClaims/useDeleteWarrantyClaim';
import {useTranslation} from 'react-i18next';
import {useTranslatedDictionaryMap} from '@src/core/hooks/translations/useTranslatedDictionaryMap';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import {MobileRightButtons} from '../actions/MobileRightButtons';
import './WarrantyClaimsTable.less';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useCurrentTreeObject} from '@src/core/hooks/queries/objects/hooks';
import {useWarrantyClaimsTableColumns} from '../../hooks/useWarrantyClaimsTableColumns';
import {EmptyTableMessage} from '../../../../components/EmptyTableMessage/EmptyTableMessage';
import {Column} from 'react-table';
import {ClaimsEntitiesTable} from './ClaimsEntitiesTable';
import {IPreparedWarrantyClaimEntity} from '../../interfaces/IPreparedWarrantyClaimEntity';
import {useWarrantyClaimSettings} from '../../../../core/hooks/settings/useWarrantyClaimSettings';

const pageSizes = [10, 20, 50, 100];

interface IClaimsTableProps {
	objectId?: string;
	claimsData?: IGetWarrantyClaimsResponse;
}

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IPreparedClaim>) => void
>(() => ({}));

const ClaimsTable = ({objectId = 'all', claimsData}: IClaimsTableProps) => {
	const {pushPath} = useChangePath();
	const {t} = useTranslation();
	const {data: claims} = useWarrantyClaimsAsArray(objectId);
	const {data: localClaims} = useLocalWarrantyClaims(objectId);
	const pageSettings = useAppSelector(s => extractWarrantyClaimsPageSettings(s, objectId));
	const {data: statuses} = useWarrantyClaimStatusesMap();
	const {data: claimsStatsData} = useWarrantyClaimsStatsData(claimsData);
	const isDesktop = useIsDesktop();

	const translatedStatuses = useTranslatedDictionaryMap(
		dictionaryKeys.warrantyClaimStatuses,
		statuses
	);

	const user = useAppSelector(s => s.auth.profile);
	const networkStatus = useAppSelector(s => s.offlineMode.networkStatus);
	const dispatch = useAppDispatch();
	const {mutate: deleteWarrantyClaim} = useDeleteWarrantyClaim();

	const claimsTotal = claimsData?.total || 0;
	const claimsOffset = claimsData?.offset || 0;

	const pagesCount = Math.ceil(claimsTotal / pageSettings.pageSize);
	const currentPage = Math.floor(claimsOffset / pageSettings.pageSize);

	const loadClaims = useCallback(
		() =>
			queryClient.invalidateQueries({
				queryKey: warrantyClaimsQueryKeys.listWithObjectId(objectId)
			}),
		[objectId]
	);

	const preparedClaims = useMemo(() => {
		if (!translatedStatuses) return undefined;
		if (claims && !localClaims)
			return convertClaims(claims, translatedStatuses, networkStatus, claimsStatsData, user);
		if (claims && localClaims)
			return convertClaims(
				[...localClaims, ...claims],
				translatedStatuses,
				networkStatus,
				claimsStatsData,
				user
			);
		if (!claims && localClaims)
			return convertClaims(
				localClaims,
				translatedStatuses,
				networkStatus,
				claimsStatsData,
				user
			);
		return undefined;
	}, [claims, localClaims, networkStatus, user, translatedStatuses, claimsStatsData]);

	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('useConfirmDialog.warrantyClaims.deleteTitle'),
		message: ''
	});

	const handleRowClick = useCallback((claim: IPreparedWarrantyClaimEntity) => {
		const preparedClaim = claim.data;
		if (preparedClaim.object?.id) {
			pushPath(`/objects/${preparedClaim.object.id}/warranty-claims/${claim.id}`);
		}
	}, []);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => {
			dispatch(changeSelectedRows({objectId, selectedRows: value}));
		},
		[objectId]
	);

	const handleSortChange = useCallback(
		(value: {[key: string]: boolean}) => {
			dispatch(changeSort({objectId, sort: value}));
			void loadClaims();
		},
		[objectId]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize({objectId, pageSize: value}));
			dispatch(changeOffset({objectId, offset: Math.floor(claimsTotal / value)}));
			void loadClaims();
		},
		[objectId, claimsTotal]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (claimsTotal !== offset) {
				dispatch(changeOffset({objectId, offset}));
				void loadClaims();
			}
		},
		[objectId, claimsTotal, pageSettings.pageSize]
	);

	const handleContextMenuAction = useCallback(
		async (action: ITableContextMenuAction<IPreparedClaim>) => {
			if (action.type === 'delete') {
				if (action.payload.object && (await getDeleteConfirmation())) {
					deleteWarrantyClaim({
						objectId: action.payload.object.id,
						warrantyClaimId: action.payload.id
					});
					void loadClaims();
				}
			}
		},
		[deleteWarrantyClaim, getDeleteConfirmation, loadClaims]
	);

	const settings = useWarrantyClaimSettings(objectId);

	const [desktopColumns, activeColumnsLength] = useWarrantyClaimsTableColumns({
		objectId,
		settings,
		t
	});

	const [columns, setColumns] = useState<Array<Column<IPreparedWarrantyClaimEntity>>>([]);

	useEffect(() => {
		if (isDesktop) {
			setColumns(desktopColumns);
		} else {
			setColumns(getMobileColumns());
		}
	}, [isDesktop, desktopColumns]);

	const selectedEntities = useMemo(
		() => preparedClaims?.filter(item => pageSettings.selectedRows.includes(item.id)),
		[pageSettings.selectedRows, preparedClaims]
	);

	const object = useCurrentTreeObject(objectId);
	const sectionsMenu = useObjectSectionsMenu(objectId);

	useObjectAppHeader(
		objectId,
		{
			title: objectId === 'all' ? t('header.warrantyClaims') : object?.name,
			sectionsMenu: objectId === 'all' ? undefined : sectionsMenu,
			mobileRightButtons: !isDesktop ? (
				<DispatchActionCtx.Provider value={handleContextMenuAction}>
					<MobileRightButtons
						selectedEntities={selectedEntities}
						selectedClearing={() => {
							handleSelectedRowsChange([]);
						}}
						objectId={objectId !== 'all' ? objectId : undefined}
					/>
				</DispatchActionCtx.Provider>
			) : null
		},
		[objectId, sectionsMenu, selectedEntities, object?.name, isDesktop, t]
	);

	if (!preparedClaims) return null;

	const preparedWarrantyClaims: IPreparedWarrantyClaimEntity[] = preparedClaims.map(claim => ({
		id: claim.id,
		data: claim
	}));

	if (isDesktop && !activeColumnsLength) {
		return <EmptyTableMessage />;
	}

	return (
		<>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					<ClaimsEntitiesTable
						isDesktop={isDesktop}
						className="w-claims-page__d-table"
						columns={columns}
						data={preparedWarrantyClaims}
						selectedRows={pageSettings.selectedRows}
						sort={pageSettings.sort}
						onRowClick={handleRowClick}
						onSelectedRowsChange={handleSelectedRowsChange}
						onSortChange={handleSortChange}
					/>
				</Plate>
			</DispatchActionCtx.Provider>

			<PaginationAndSize
				pagination={
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>

			{deleteDialog}
		</>
	);
};

export default ClaimsTable;
