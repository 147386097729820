import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IUnitIndicatorsSet} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicatorsSet';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetListUnitIndicatorsSetsResponse extends INormalizedData<IUnitIndicatorsSet> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Получает список наборов индикаторов помещений
 * 	@param filters  Фильтры запроса
 */
export const requestGetListUnitIndicatorsSets = async (filters?: Record<string, unknown>) => {
	const params = {
		filters,
		sort: convertSortParam({name: true}),
		offset: 0,
		limit: 100000
	};

	const response = await httpRequests.withToken.post<IGetListUnitIndicatorsSetsResponse>(
		'unit-indicators-sets/get-paginate',
		params
	);

	return response.data;
};
