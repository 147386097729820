import {useCallback} from 'react';
import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {useEditableSpaceDialog} from '@src/components/EditableSpaceDialog/hooks/useEditableSpaceDialog';
import {SettingsDialog} from '@src/pages/SpacesPage/components/settings/SettingsDialog';
import useToggle from 'react-use/lib/useToggle';
import {useSpacesExport} from '@src/core/hooks/export/useSpacesExport';
import {useAppUpdateMenuItem} from '@src/components/AppUpdateData';
import {useTranslation} from 'react-i18next';
import {useSpacesSelection} from '../../hooks/useSpacesSelection';

interface IMobileMenuProps {
	objectId: string;
	canAddSpace?: boolean;
	canExportSpaces?: boolean;
	canOpenSettings?: boolean;
	canSelectSpaces?: boolean;
}

const plusIcon = <i className="tz-plus-24" />;
const exportIcon = <i className="tz-export-20" />;
const settingsIcon = <i className="tz-settings-24" />;
const chooseIcon = <i className="tz-choose-20" />;

const MobileMenu = ({
	objectId,
	canAddSpace,
	canExportSpaces,
	canOpenSettings,
	canSelectSpaces
}: IMobileMenuProps) => {
	const {t} = useTranslation();
	const items = [];

	// Диалог добавления помещения
	const [addDialog, openAddDialog] = useEditableSpaceDialog(objectId);
	// Диалог экспорта помещений
	const [exportDialog, openExportDialog] = useSpacesExport(objectId);
	// Кнопка обновления данных приложения
	const updateData = useAppUpdateMenuItem();

	const handleExport = useCallback(() => {
		openExportDialog(objectId);
	}, [objectId, openExportDialog]);

	items.push(updateData);

	if (canAddSpace) {
		items.push(
			<MenuItem
				key="add"
				icon={plusIcon}
				onClick={openAddDialog}
			>
				{t('spacesPage.mobileActions.mobileMenu.menuItem.add.children')}
			</MenuItem>
		);
	}

	const {toggleChoosing} = useSpacesSelection(objectId);
	if (canSelectSpaces) {
		items.push(
			<MenuItem
				key="selectSpaces"
				icon={chooseIcon}
				onClick={() => toggleChoosing(true)}
			>
				{t('spacesPage.mobileActions.mobileMenu.menuItem.choose.spaces')}
			</MenuItem>
		);
	}

	if (canExportSpaces) {
		items.push(
			<MenuItem
				key="export"
				icon={exportIcon}
				onClick={handleExport}
			>
				{t('spacesPage.mobileActions.mobileMenu.menuItem.export.children')}
			</MenuItem>
		);
	}

	const [settingsOpen, toggleSettingsOpen] = useToggle(false);
	if (canOpenSettings) {
		items.push(
			<MenuItem
				key="settings"
				icon={settingsIcon}
				onClick={toggleSettingsOpen}
			>
				{t('spacesPage.mobileActions.mobileMenu.menuItem.settings.children')}
			</MenuItem>
		);
	}
	return items.length ? (
		<>
			<IconMenu>{items}</IconMenu>

			{canAddSpace && addDialog}
			{canExportSpaces && exportDialog}

			{canOpenSettings && (
				<SettingsDialog
					isOpen={settingsOpen}
					onClose={toggleSettingsOpen}
				/>
			)}
		</>
	) : null;
};

export default MobileMenu;
