import {useCallback, useState} from 'react';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useActualUnitIndicatorsSetsExtended} from '@src/core/hooks/queries/unitIndicatorsSets/hooks';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {ISpacesFiltersState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {useTranslation} from 'react-i18next';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {LinkButton, Select2, SelectOption, Tag} from '@tehzor/ui-components';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getStageNames} from '@tehzor/ui-components/src/components/tags/SpaceIndicatorsSet/utils/getStageNames';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useShowStageByProcessId} from '@src/core/hooks/processes/useShowStageByProcessId';

const IndicatorsFilterPage = () => {
	const {t} = useTranslation();
	const {objectId} = useStrictParams<{objectId: string; entity: string}>();
	const {goBack} = useChangePath();

	const {state, change} = useEntitiesFiltersCtx<ISpacesFiltersState>();

	const {processId: pageProcessId} = useAppSelector(state =>
		extractSpacesPageSettings(state, objectId)
	);
	const stageId = useShowStageByProcessId(pageProcessId as string | undefined);
	const {data: setsWithIndicators} = useActualUnitIndicatorsSetsExtended(
		objectId,
		stageId ?? undefined
	);

	const [selectedObjects, setSelectedObjects] = useState(state.indicators);

	const handleApply = useCallback(() => {
		change({indicators: selectedObjects});
		goBack();
	}, [change, selectedObjects, goBack]);

	const handleClear = useCallback(() => {
		setSelectedObjects(undefined);
	}, []);

	useAppHeader(
		{
			title: t('spacesPage.spacesFilters.entitiesFilters.statusesFilter.indicators.label'),
			showBackBtn: true,
			mobileRightButtons: selectedObjects?.length ? (
				<LinkButton
					label={t('entitiesFilters.cancelButton.label')}
					onClick={handleClear}
				/>
			) : null
		},
		[selectedObjects]
	);

	return (
		<TranslatedFilterPage
			applyDisabled={isLocalStateEqual(state.indicators, selectedObjects)}
			onApplyClick={handleApply}
		>
			<Select2
				multiple
				value={selectedObjects}
				onChange={setSelectedObjects}
			>
				<div className="space-page__indicators-filter--sets-mobile-wrapper">
					{setsWithIndicators
						?.filter(set => set.indicators.length > 0)
						.map(set => (
							<div className="space-page__indicators-filter--set-mobile-wrapper">
								<div className="space-page__indicators-filter--set-mobile-header">
									<div className="space-page__indicators-filter--indicator-set-item_name-mobile">
										{set.name}
									</div>
									<div className="space-page__indicators-filter--indicator-set-item_stages-mobile">
										{` ${getStageNames(set.stageIds)}`}
									</div>
								</div>
								{set.indicators?.map(indicator => (
									<SelectOption
										key={indicator.id}
										itemKey={indicator.id}
										content={<Tag label={indicator.name} />}
									/>
								))}
							</div>
						))}
				</div>
			</Select2>
		</TranslatedFilterPage>
	);
};

export default IndicatorsFilterPage;
