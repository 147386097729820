import classNames from 'classnames';
import {CSSProperties, ReactNode} from 'react';

interface ITableRowProps {
	className?: string;
	style?: CSSProperties;
	role?: string;
	children?: ReactNode;
}

const TableHeadRow = ({className, style, role, children}: ITableRowProps) => (
	<div
		className={classNames('table2__row', className)}
		style={style}
		role={role}
	>
		{children}
	</div>
);

export default TableHeadRow;
