import {useCallback} from 'react';
import {WorkAcceptancesPage} from './WorkAcceptancesPage';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import EntitiesFiltersProvider from '@src/components/EntitiesFilters/utils/EntitiesFiltersProvider';
import {IWorkAcceptancesFiltersState} from '@src/store/modules/settings/pages/workAcceptances/reducers/byPage';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	changeFilters,
	clearFilters,
	changeOffset
} from '@src/store/modules/settings/pages/workAcceptances/actions';
import {extractWorkAcceptancesPageSettings} from '@src/store/modules/settings/pages/workAcceptances/selectors';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useWorkAcceptancesPermissions} from '@src/core/hooks/permissions/useWorkAcceptancesPermissions';
import {ActionsMobile} from '@src/pages/WorkAcceptancesPage/components/actions/Actions.mobile';
import {useTargetObjects} from '@src/core/hooks/queries/objects/hooks';

/**
 * Обёртка над страницей приёмок работ
 * необходима для того чтобы при переходе от одной страницы к другой отрабатывали все методы жизненного цикла
 *
 * @constructor
 */
export const WorkAcceptancesPageWrap = () => {
	const dispatch = useAppDispatch();

	const {objectId} = useStrictParams<{objectId: string}>();
	const {data: descendants} = useTargetObjects(objectId, false);

	const permissions = useWorkAcceptancesPermissions(objectId);

	const sectionsMenu = useObjectSectionsMenu(objectId);
	useObjectAppHeader(objectId, {
		sectionsMenu,
		mobileRightButtons: (
			<ActionsMobile
				objectId={objectId}
				canAdd={permissions.canAdd}
				canExport={permissions.canExport}
			/>
		)
	});
	const {filters} = useAppSelector(s => extractWorkAcceptancesPageSettings(s, objectId));

	const applyFilters = useCallback(
		(value: IWorkAcceptancesFiltersState) => {
			dispatch(changeFilters(objectId, value, !!descendants?.length));
			dispatch(changeOffset(objectId, 0));
		},
		[objectId, dispatch, descendants]
	);

	const onClear = useCallback(() => {
		dispatch(clearFilters(objectId, !!descendants?.length));
	}, [objectId, dispatch, descendants]);

	return (
		<EntitiesFiltersProvider
			objectId={objectId}
			filters={filters}
			onApply={applyFilters}
			onClear={onClear}
		>
			<WorkAcceptancesPage key={objectId} />
		</EntitiesFiltersProvider>
	);
};
