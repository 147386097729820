import {IExportTemplateTypeInfo} from '@tehzor/tools/interfaces/export/IExportTemplateTypeInfo';
import {OutputFileType} from '@tehzor/tools/interfaces/IExportTemplate';
import {addWarningToast} from '@tehzor/ui-components';
import {TFunction} from 'i18next';

interface IShowWarningExportToastParams {
	selected: string[] | undefined;
	total: number | undefined;
	templateTypeInfo: IExportTemplateTypeInfo;
	t: TFunction;
}
export const showWarningExportToast = ({
	selected = [],
	total = 0,
	templateTypeInfo,
	t
}: IShowWarningExportToastParams) => {
	const {templateTypeMaxCount, currentTemplateType} = templateTypeInfo;
	const isSelectedExceedsLimit = selected && selected.length > templateTypeMaxCount;
	const isEmptySelectedExceedsLimit =
		selected && selected.length === 0 && total && total > templateTypeMaxCount;
	const isNotPdf = currentTemplateType && currentTemplateType !== OutputFileType.PDF;

	if ((isSelectedExceedsLimit || isEmptySelectedExceedsLimit) && isNotPdf) {
		addWarningToast({
			title: `${t('toast.export.warning.title')} (${templateTypeMaxCount})`
		});
	}
};
