import {useMutation} from '@tanstack/react-query';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import {makeChecksAddRequest} from '@src/api/backend/checks';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {Dispatch, SetStateAction} from 'react';

export interface IAddMultipleChecksWithEntitiesParams {
	objectId: string;
	spaceIds: string[];
	links: ICheck['links'];
	savingData: ISavingProblem | ISavingInspection;
	checkType: ICheckAddingEntityType;
	setSaving: Dispatch<SetStateAction<boolean>>;
	onClose: () => void;
}
export const useAddMultipleChecksWithEntities = () => {
	const checksMutation = useMutation({
		mutationFn: async ({
			objectId,
			spaceIds,
			links,
			savingData,
			checkType
		}: IAddMultipleChecksWithEntitiesParams) =>
			makeChecksAddRequest(objectId, spaceIds, links, checkType, savingData),
		onSuccess: (response, variables) => {
			const {onClose, setSaving} = variables;
			setSaving(false);
			onClose();
		},
		onError: error => {
			console.error('Ошибка при создании:', error);
		}
	});

	return async ({
		objectId,
		spaceIds,
		links,
		savingData,
		checkType,
		setSaving,
		onClose
	}: IAddMultipleChecksWithEntitiesParams) => {
		await checksMutation.mutateAsync({
			objectId,
			spaceIds,
			links,
			savingData,
			checkType,
			setSaving,
			onClose
		});
	};
};
