import {makeProblemsExportRequest} from '@src/api/backend/problems';
import {problemsQueryKeys} from '@src/api/cache/problems/keys';
import {useMutation} from '@tanstack/react-query';
import {IDefferedExportResponse} from '@tehzor/tools/interfaces/export';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';
import {useProblemsTotal} from '@src/core/hooks/queries/problems/hooks';
import {exportProblemsLimit} from '@src/constants/export/exportLimits';
import {useCurrentExportTemplateTypeInfo} from '@src/core/hooks/useCurrentExportTemplateTypeInfo';
import {showWarningExportToast} from '@src/utils/exportTemplates/showWarningExportToast';

interface IExportProblemsParams {
	templateId: string;
	objectId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	filters: Record<string, unknown> | undefined;
	sort: Record<string, boolean> | undefined;
	selected?: string[] | undefined;
}

/**
 * Экспортирует список нарушений
 * {mutate, mutateAsync}
 * @param templateId id шаблона экспорта
 * @param objectId id объекта
 * @param createDocument флаг создания документа
 * @param email email для отправки
 * @param filters фильтры
 * @param sort сортировка
 * @param selected выбранные элементы
 */

export const useExportProblems = (objectId: string) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {data: total} = useProblemsTotal(objectId);

	const templateTypeInfo = useCurrentExportTemplateTypeInfo('problems', exportProblemsLimit);
	return useMutation<IDefferedExportResponse, Error, IExportProblemsParams>({
		mutationKey: problemsQueryKeys.export(),
		mutationFn: params => {
			const {templateId, objectId, createDocument, email, filters, sort, selected} = params;
			showWarningExportToast({
				selected,
				total,
				templateTypeInfo,
				t
			});
			return makeProblemsExportRequest(
				templateId,
				objectId,
				createDocument,
				email,
				filters,
				sort,
				selected
			);
		},
		onSuccess: (response, params) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!params.email}));
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
