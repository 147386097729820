import {Button, IconButton} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {useWarrantyClaimsExport} from '@src/core/hooks/export/useWarrantyClaimsExport';

interface IDesktopWarrantyClaimsExportProps {
	objectId?: string;
}

const exportIcon = <i className="tz-export-20" />;

export const WarrantyClaimsExportDesktop = ({objectId}: IDesktopWarrantyClaimsExportProps) => {
	const {t} = useTranslation();
	const [exportDialog, openExportDialog] = useWarrantyClaimsExport(objectId);

	return (
		<>
			{objectId ? (
				<IconButton
					type="accent-blue"
					onClick={openExportDialog}
				>
					{exportIcon}
				</IconButton>
			) : (
				<Button
					type="accent-blue"
					label={t('exportButton.label')}
					leftIcon={exportIcon}
					onClick={openExportDialog}
				/>
			)}
			{exportDialog}
		</>
	);
};
