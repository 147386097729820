import {useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {useUpdateEffect} from 'react-use';
import {convertClassNames} from '../../../utils/convertClassNames';
import {Menu, MenuItem} from '../../menu';
import {TextField} from '../../inputs';
import SearchFieldsMenu from '../../menu/SearchFieldsMenu';
import styles from './FullTextSearch.module.less';

export type InputType = 'text' | 'number' | 'password';

interface IField {
	id: string;
	name: string;
	iconName?: string;
	color?: string;
	backgroundColor?: string;
	isCustom?: boolean;
	inputType?: InputType;
}

interface ISelectSearchProps {
	className?:
		| string
		| {
				root?: string;
				wrap?: string;
				element?: string;
				placeholder?: string;
				icon?: string;
				clearbutton?: string;
		  };
	isMobile?: boolean;
	placeholder?: string;
	header?: string;
	value?: string;
	disabled?: boolean;
	fields?: IField[];
	field: string;
	onSearch?: (value: string, field: string) => void;
	onFieldChange?: (field: string) => void;
	onClear?: (field?: string) => void;
}

const searchIcon = <i className="tz-search-20" />;

const getSearchFieldsMenu = (field: IField, selectedField: string) => (
	<MenuItem
		className={styles.menuItem}
		titleClass={styles.menuItemTitle}
		key={field.id}
		itemKey={field.id}
		icon={field.iconName ? <i className={field.iconName} /> : null}
		selected={field.id === selectedField}
	>
		{field.name}
	</MenuItem>
);

// Компонент полнотекстового поиска с выпадающим меню полей
export const FullTextSearch = ({
	className,
	isMobile,
	placeholder = 'Поиск',
	header = 'Поиск по полю',
	value: defaultValue = '',
	disabled,
	fields,
	field: defaultField,
	onSearch,
	onClear,
	onFieldChange
}: ISelectSearchProps) => {
	const classes = convertClassNames(className);
	const [value, setValue] = useState(defaultValue);
	const [field, setField] = useState(defaultField);
	const [isPressed, setPressed] = useState(false);
	const ref = useRef<HTMLInputElement>(null);

	const label = fields?.find(f => f.id === field);

	const getActualPlaceholder = useCallback(
		() =>
			fields && isMobile
				? `${placeholder} ${fields?.find(f => f.id === field)?.name.toLowerCase()}`
				: placeholder,
		[fields, field, placeholder, isMobile]
	);

	useUpdateEffect(() => {
		setValue(val => (val !== defaultValue ? defaultValue : val));
	}, [defaultValue]);

	useUpdateEffect(() => {
		setField(val => (val !== defaultField ? defaultField : val));
	}, [defaultField, defaultValue]);

	const handleChange = useCallback((v: string) => {
		setValue(v);
	}, []);

	const handleChangeField = useCallback(
		(v: string) => {
			if (onFieldChange) {
				onFieldChange(v);
			}
			setField(v);
			setValue('');
		},
		[onFieldChange]
	);

	const handleSearch = useCallback(() => {
		if (onSearch && value.trim() !== '') {
			onSearch(value, field);
		}
	}, [value, onSearch, field]);

	const handleClear = useCallback(() => {
		if (onClear) {
			onClear(field);
		}
	}, [onClear, field]);

	useEffect(() => {
		const current = ref?.current;
		if (!current) {
			return;
		}

		const handleKeyDown = (ev: KeyboardEvent): void => {
			if (ev.key === 'Enter') {
				handleSearch();
				setPressed(true);
			}
		};

		const handleKeyUp = (ev: KeyboardEvent): void => {
			if (ev.key === 'Enter') {
				setPressed(false);
			}
		};

		current.onkeydown = handleKeyDown;
		current.onkeyup = handleKeyUp;
	}, [handleSearch]);

	return (
		<div className={styles.container}>
			{fields?.length && (
				<SearchFieldsMenu
					className={{
						root: styles.menu,
						button: styles.button
					}}
					label={label?.name}
					isMobile={isMobile}
					disabled={disabled}
				>
					<Menu
						onChange={handleChangeField}
						value={field}
					>
						<>
							<p
								className={classNames(styles.header, {
									[styles.headerMobile]: isMobile
								})}
							>
								{header}
							</p>
							<hr className={styles.divider} />
							<div className={styles.menuItems}>
								{fields
									.filter(f => !f.isCustom)
									.map(f => getSearchFieldsMenu(f, field))}
							</div>
							<div className={styles.customMenuItems}>
								{fields
									.filter(f => f.isCustom)
									.map(f => getSearchFieldsMenu(f, field))}
							</div>
						</>
					</Menu>
				</SearchFieldsMenu>
			)}
			<TextField
				className={{
					root: classNames(styles.root, classes?.root),
					wrap: classNames(styles.wrap, classes?.wrap, {[styles.disabled]: disabled}),
					element: classNames(styles.input, classes?.element),
					placeholder: classNames(styles.placeholder, classes?.placeholder),
					icon: classNames(styles.icon, classes?.icon, {
						[styles.isPressed]: isPressed,
						[styles.disabled]: disabled
					}),
					clearbutton: classNames(styles.clearbutton, classes?.clearbutton)
				}}
				elementType="input"
				inputType={fields?.find(currentField => currentField.id === field)?.inputType}
				elementRef={ref}
				value={value}
				onChange={handleChange}
				cleanable={!!value}
				onClearClick={handleClear}
				icon={searchIcon}
				onIconClick={onSearch ? handleSearch : undefined}
				placeholder={getActualPlaceholder()}
				disabled={disabled}
			/>
		</div>
	);
};
