import {useMemo, CSSProperties, ReactNode} from 'react';
import './EntityInnerTable.less';
import {
	Column,
	useExpanded,
	useFlexLayout,
	useResizeColumns,
	useRowSelect,
	useTable
} from 'react-table';
import Table from '../Table';
import TableHead from '../Table/components/TableHead';
import TableHeadCell from '../TableHeadCell';
import TableRow from '../Table/components/TableRow';
import TableBody from '../Table/components/TableBody';
import TableCell from '../TableCell';
import TableWrap from '../Table/components/TableWrap';
import TableHeadRow from '../Table/components/TableHeadRow';
import classNames from 'classnames';
import {useMemoizedSelectedRows} from '../shared/hooks/useMemoizedSelectedRows';
import {convertSelectedRowsFromInternal} from '../shared/utils/convertSelectedRowsFromInternal';
import {ISelectionRowProps} from '../shared/interfaces';
import TableHeadSelectionRow from '../TableHeadSelectionRow';

const getRowId = (row: {id: string}) => row.id;

interface ISpaceEntitiesTableProps<D extends {id: string}> {
	className?: string;
	style?: CSSProperties;
	columns: Array<Column<D>>;
	data: D[];
	selectedRows?: string[];
	hideHead?: boolean;
	selectable?: boolean;
	renderSelectionRow?: (props: ISelectionRowProps<D>) => ReactNode;

	onRowClick?: (data: D) => void;
	onSelectedRowsChange?: (value: string[]) => void;
}

const EntityInnerTable = <D extends {id: string}>(props: ISpaceEntitiesTableProps<D>) => {
	const {
		className,
		style,
		columns,
		data,
		selectedRows,
		hideHead,
		selectable,
		renderSelectionRow,
		onRowClick,
		onSelectedRowsChange
	} = props;
	const isRowsClickable = !!onRowClick;

	const memoizedSelectedRows = useMemoizedSelectedRows(selectedRows);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		selectedFlatRows,
		state: {selectedRowIds},
		toggleAllRowsSelected
	} = useTable<D>(
		{
			columns,
			data,
			getRowId,
			initialState: {selectedRowIds: memoizedSelectedRows ?? {}},
			// Передача controlled значений в state таблицы
			useControlledState: state =>
				useMemo(
					() => ({
						...state,
						selectedRowIds: memoizedSelectedRows ?? state.selectedRowIds
					}),
					[state]
				),
			// Передача controlled значений из state наружу
			stateReducer: (newState, action) => {
				// setTimeout необходим для избежания вызова хуков в методе рендера и получения ошибки:
				// Cannot update a component while rendering a different component
				setTimeout(() => {
					if (
						(action.type === 'toggleRowSelected' ||
							(action.type === 'toggleAllRowsSelected' && action.value)) &&
						selectable &&
						onSelectedRowsChange
					) {
						onSelectedRowsChange(
							convertSelectedRowsFromInternal(newState.selectedRowIds)
						);
					}
					if (action.type === 'resetSelectedRows' && onSelectedRowsChange) {
						onSelectedRowsChange([]);
					}
					if (
						action.type === 'toggleAllRowsSelected' &&
						!action.value &&
						onSelectedRowsChange
					) {
						onSelectedRowsChange([]);
					}
				});
				if (action.type === 'resetSelectedRows') {
					return {...newState, selectedRowIds: {} as Record<string, boolean>};
				}
				return newState;
			}
		},
		useFlexLayout,
		useExpanded,
		useRowSelect,
		useResizeColumns
	);

	return (
		<TableWrap
			className={classNames('ei-table', className)}
			style={style}
		>
			<Table {...getTableProps()}>
				{!hideHead && (
					<TableHead className="ei-table__header">
						{headerGroups.map(headerGroup => {
							const {key: headerGroupKey, ...headerGroupProps} =
								headerGroup.getHeaderGroupProps();
							return (
								<TableHeadRow
									key={headerGroupKey}
									{...headerGroupProps}
								>
									{headerGroup.headers.map(column => {
										const {key: columnKey, ...columnProps} =
											column.getHeaderProps();
										return (
											<TableHeadCell
												key={columnKey}
												{...columnProps}
											>
												{column.render('Header')}
											</TableHeadCell>
										);
									})}
								</TableHeadRow>
							);
						})}

						{renderSelectionRow && (
							<TableHeadSelectionRow
								visible={selectedFlatRows.length > 0}
								selectedRows={selectedRows}
								selectedRowIds={selectedRowIds}
								selectedFlatRows={selectedFlatRows}
								toggleAllRowsSelected={toggleAllRowsSelected}
								renderSelectionRow={renderSelectionRow}
							/>
						)}
					</TableHead>
				)}
				<TableBody {...getTableBodyProps()}>
					{rows.map(row => {
						prepareRow(row);
						const {key: rowKey, ...rowProps} = row.getRowProps();

						return (
							<TableRow
								key={rowKey}
								{...rowProps}
								className={classNames(
									'ei-table__row',
									{'ei-table__row_nested': row.depth > 0},
									{'ei-table__row_selected': row.isSelected}
								)}
								clickable={isRowsClickable}
							>
								{row.cells.map(cell => {
									const {key: cellKey, ...cellProps} = cell.getCellProps();
									return (
										<TableCell
											key={cellKey}
											{...cellProps}
											className={classNames(
												cell.column.className,
												'ei-table__cell'
											)}
											data={row.original}
											onClick={
												cell.column.isNonClickable ? undefined : onRowClick
											}
										>
											{cell.render('Cell')}
										</TableCell>
									);
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableWrap>
	);
};

export default EntityInnerTable;
