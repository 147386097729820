import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {useMutation} from '@tanstack/react-query';
import {IDefferedExportResponse} from '@tehzor/tools/interfaces/export';
import {workAcceptancesQueryKeys} from '@src/api/cache/workAcceptances/keys';
import {makeWorkAcceptancesExportRequest} from '@src/api/backend/workAcceptances/export';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';
import {addErrorToast} from '@tehzor/ui-components';

interface IExportWorkAcceptancesParams {
	templateId: string;
	objectId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	filters: Record<string, unknown> | undefined;
	sort: Record<string, boolean> | undefined;
	selected?: string[] | undefined;
}

export const useExportWorkAcceptances = () => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	return useMutation<IDefferedExportResponse, Error, IExportWorkAcceptancesParams>({
		mutationKey: workAcceptancesQueryKeys.export(),
		mutationFn: ({templateId, objectId, createDocument, email, filters, sort, selected}) =>
			makeWorkAcceptancesExportRequest(
				templateId,
				objectId,
				createDocument,
				email,
				filters,
				sort,
				selected
			),
		onSuccess: (response, params) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!params.email}));
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
