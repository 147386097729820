import {useMutation} from '@tanstack/react-query';
import {makeProblemsAddMultipleRequest} from '@src/api/backend/problems';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';

export interface IAddMultipleProblemsParams {
	objectId: string;
	spaceIds: string[];
	ownerAcceptanceIds?: string[];
	warrantyClaimIds?: string[];
	links: IProblem['links'];
	fields: ISavingProblem;
	processId: ProcessIds;
	stage: ObjectStageIds;
	onClose: () => void;
	setSaving: (value: boolean) => void;
	parentEntityType: 'owner-acceptance' | 'warranty-claim';
}
/**
 * Хук для добавления нескольких нарушений в рамках сущностей передачи собственнику и гарантийных обращений
 */

export const useAddMultipleProblems = () =>
	useMutation({
		mutationFn: async ({
			objectId,
			spaceIds,
			ownerAcceptanceIds,
			warrantyClaimIds,
			links,
			fields,
			processId,
			stage
		}: IAddMultipleProblemsParams) =>
			makeProblemsAddMultipleRequest(
				objectId,
				spaceIds,
				ownerAcceptanceIds,
				warrantyClaimIds,
				links,
				fields,
				processId,
				stage
			),
		onSuccess: (response, {onClose, setSaving}) => {
			setSaving(false);
			onClose();
		},
		onError: error => {
			console.error(error, 'Ошибка при множественном создании нарушений');
		}
	});
