const domain = 'settings/pages/spaces';

export const CHANGE_DISPLAY_MODE = `${domain}/display-mode/change`;
export const CHANGE_SCHEMA_VIEW = `${domain}/schema-view/change`;
export const CHANGE_STAGE = `${domain}/stage/change`;
export const CHANGE_PROCESS = `${domain}/process/change`;
export const CHANGE_TYPE = `${domain}/type/change`;
export const CHANGE_FILTERS = `${domain}/filters/change`;
export const CLEAR_FILTERS = `${domain}/filters/clear`;
export const CHANGE_SORT = `${domain}/sort/change`;
export const CHANGE_PAGE_SIZE = `${domain}/page-size/change`;
export const CHANGE_FLOOR_SORT = `${domain}/floor-sort/change`;
export const TOGGLE_PENDING = `${domain}/pending/toggle`;
export const CHANGE_SCHEMA_PAGE_SIZE = `${domain}/schema-page-size/change`;
export const CHANGE_SCHEMA_OFFSET = `${domain}/schema-offset/change`;
export const CHANGE_LIST_OFFSET = `${domain}/list-offset/change`;

export const SELECT_SPACES_BY_VALUE = `${domain}/spaces/select-by-value`;
export const SELECT_SPACES_BY_ONE = `${domain}/spaces/select-by-one`;
export const SELECT_SPACES_MULTIPLE = `${domain}/spaces/select-multiple`;
export const DESELECT_SPACES_ALL = `${domain}/spaces/deselect-all`;
export const SELECT_SPACES_ALL = `${domain}/spaces/select-all`;
export const SELECT_SPACES_ALL_VISIBLE = `${domain}/spaces/select-all-visible`;
export const TOGGLE_SELECTION_SPACES_VISIBILITY = `${domain}/selection-spaces-visibility/toggle`;

export const CHANGE_CURRENT_OBJECT = `${domain}/current-object/change`;
export const GET_SCHEMA_REQUEST = `${domain}/schema/get/request`;
export const GET_LIST_REQUEST = `${domain}/list/get/request`;

export const TOGGLE_ALT_NAMES_VISIBILITY = `${domain}/alt-names-visibility/toggle`;

export const SET_OBJECT_SPACES_COUNT = `${domain}/spaces-count/set`;
