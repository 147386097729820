import {forwardRef, Ref, Dispatch, SetStateAction, useEffect} from 'react';
import './AddingWarrantyClaimDialog.less';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import AddingWarrantyClaim, {IAddingWarrantyClaimRefProps} from './components/AddingWarrantyClaim';
import DialogTitle from './components/DialogTitle';
import AddingProblem, {IAddingProblemRefProps} from '../AddingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export type AddingWarrantyClaimView = 'claim' | 'problem' | undefined;

interface IAddingWarrantyClaimDialogProps {
	objectId: string;
	spaceId?: string;
	links?: IWarrantyClaim['links'];
	defaultProblemData?: ISavingProblem;
	selectedWarrantyClaimId?: string;

	view: AddingWarrantyClaimView;
	setView: Dispatch<SetStateAction<AddingWarrantyClaimView>>;

	saving: boolean;
	setSaving: Dispatch<SetStateAction<boolean>>;

	onClose: () => void;
	isMultiple?: boolean;
}

const AddingWarrantyClaimGlobal = (
	props: IAddingWarrantyClaimDialogProps,
	ref?: Ref<IAddingWarrantyClaimRefProps | IAddingProblemRefProps>
) => {
	const {
		objectId,
		spaceId,
		links,
		defaultProblemData,
		selectedWarrantyClaimId,
		view,
		setView,
		saving,
		setSaving,
		onClose,
		isMultiple
	} = props;

	useEffect(() => {
		setView(!selectedWarrantyClaimId || isMultiple ? 'claim' : 'problem');
	}, [selectedWarrantyClaimId, isMultiple, setView]);

	return (
		<div>
			<div className="adding-warranty-claim-dialog__header adding-warranty-claim-dialog__header_global">
				<DialogTitle view={view} />
			</div>
			{view === 'problem' ? (
				<AddingProblem
					objectId={objectId}
					links={{...links, warrantyClaimId: selectedWarrantyClaimId}}
					stage={ObjectStageIds.WARRANTY}
					processId={ProcessIds.WARRANTY_SERVICE}
					defaultData={defaultProblemData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={ref}
					isMultiple={isMultiple}
				/>
			) : view === 'claim' ? (
				<AddingWarrantyClaim
					objectId={objectId}
					spaceId={spaceId}
					links={links}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={ref}
				/>
			) : null}
		</div>
	);
};

export default forwardRef<
	IAddingProblemRefProps | IAddingWarrantyClaimRefProps,
	IAddingWarrantyClaimDialogProps
>(AddingWarrantyClaimGlobal);
