import {useMultipleChecksCreationEventHandler} from './hooks/useMultipleChecksCreationEventHandler';
import {useMultipleInternalAcceptancesCreationEventHandler} from './hooks/useMultipleInternalAcceptancesCreactionEventHandler';
import {useMultipleOwnerAcceptancesCreationEventHandler} from './hooks/useMultipleOwnerAcceptancesCreationEventHandler';
import {useMultiplePresaleChecksCreationEventHandler} from './hooks/useMultiplePresaleChecksCreationEventHandler';
import {useMultipleWarrantyClaimsCreationEventHandler} from './hooks/useMultipleWarrantyClaimsCreationEventHandler';

export const MultipleEntitiesCreationNotifications = () => {
	useMultipleChecksCreationEventHandler();
	useMultipleInternalAcceptancesCreationEventHandler();
	useMultiplePresaleChecksCreationEventHandler();
	useMultipleOwnerAcceptancesCreationEventHandler();
	useMultipleWarrantyClaimsCreationEventHandler();
	return null;
};
