import {ReactNode} from 'react';
import './SpaceIndicatorsSet.less';
import {getStageNames} from './utils/getStageNames';
import classNames from 'classnames';

interface IIndicatorSetProps {
	set: {
		id: string;
		name: string;
		stageIds: string[];
	};
	children: ReactNode;
	className?: string;
}

export const SpaceIndicatorsSet = ({set, children, className}: IIndicatorSetProps) => (
	<div className={classNames('indicators-set__container', className)}>
		<div className={classNames('indicators-set__header', className)}>
			<div className="indicators-set__name">{set.name}</div>
			<div className={classNames('indicators-set__stages', className)}>
				{getStageNames(set.stageIds)}
			</div>
		</div>
		<div className={classNames('indicators-set__indicators', className)}>{children}</div>
	</div>
);
