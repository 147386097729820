import {addTempFiles} from '@src/api/cache/utils/addTempFiles';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import ICheck from '@tehzor/tools/interfaces/checks/ICheck';
import IInspection from '@tehzor/tools/interfaces/inspections/IInspection';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export type IAddChecksResponse = ICheck[];
export type IAddProblemsResponse = IProblem[];
export type IAddInspectionsResponse = IInspection[];

/**
 * Отправляет запрос на добавления нескольких проверок
 */
export const makeChecksAddRequest = async (
	objectId: string,
	spaceIds: string[],
	links: ICheck['links'] | undefined,
	entityType: ICheckAddingEntityType,
	savingData: ISavingProblem | ISavingInspection
) => {
	savingData.newAttachments = await addTempFiles(savingData.newAttachments);

	const response = await httpRequests.withToken.post<
		Record<string, IAddChecksResponse | IAddProblemsResponse | IAddInspectionsResponse>
	>('checks/add-checks-with-entities', {
		objectId,
		spaceIds,
		links,
		entityType,
		savingData: {
			...savingData,
			objectId,
			stage: ObjectStageIds.BUILDING,
			processId: ProcessIds.OPERATIONAL_CONTROL
		}
	});
	return response.data;
};
