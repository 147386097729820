import {ReactNode, Suspense, useCallback, useState} from 'react';
import GlobalAddingEntityDialog, {IGlobalAddingLinks} from '../GlobalAddingEntityDialog';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';

export function useGlobalAddingEntityDialog(
	objectId: string,
	spaceId: string | undefined,
	links: IGlobalAddingLinks | undefined,
	stage: ObjectStageIds,
	processId: ProcessIds,
	permissions: {
		checks?: boolean;
		internalAcceptances?: boolean;
		acceptances?: boolean;
		claims?: boolean;
		presaleChecks?: boolean;
	},
	defaultData?: ISavingProblem | ISavingInspection,
	onSuccess?: () => void | Promise<void>,
	types?: ICheckAddingEntityType[],
	externalContentMap?: Record<ObjectStageIds, Record<ProcessIds, ReactNode>>,
	isMultiple?: boolean,
	spaceIds?: string[]
): [ReactNode, () => void] {
	const [isOpen, setOpen] = useState<boolean>(false);

	const open = useCallback(() => {
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = (
		<Suspense>
			<GlobalAddingEntityDialog
				objectId={objectId}
				spaceId={spaceId}
				spaceIds={spaceIds}
				links={links}
				defaultProblemData={defaultData}
				defaultInspectionData={defaultData}
				isOpen={isOpen}
				onClose={close}
				onSuccess={onSuccess}
				stage={stage}
				processId={processId}
				permissions={permissions}
				types={types}
				externalContentMap={externalContentMap}
				isMultiple={isMultiple}
			/>
		</Suspense>
	);

	return [dialog, open];
}
