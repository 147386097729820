import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IUnitIndicator} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicator';

export type IGetUnitIndicatorResponse = IUnitIndicator;

/**
 * Получает индикатор помещения по id
 *
 * @param id id индикатора помещений
 */
export const requestGetUnitIndicator = async (id: string) => {
	const response = await httpRequests.withToken.get<IGetUnitIndicatorResponse>(
		`unit-indicators/${id}`
	);
	return response.data;
};
