import {LanguageSelect} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import {languages} from '@src/core/i18n';
import {editProfile} from '@src/store/modules/auth/profile/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import classNames from 'classnames';
import styles from './ProfileLanguageSelect.module.less';

interface IProfileLanguageSelectProp {
	className?: string;
}

export const ProfileLanguageSelect = ({className}: IProfileLanguageSelectProp) => {
	const {t, i18n} = useTranslation();
	const dispatch = useAppDispatch();

	const changeLanguage = async (lng: string) => {
		try {
			await dispatch(editProfile({language: lng}));
			await i18n.changeLanguage(lng);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className={classNames(styles.wrap, className)}>
			<div>{t('profilePage.settings.language')}</div>
			<LanguageSelect
				languages={languages}
				value={i18n.language}
				onChange={changeLanguage}
			/>
		</div>
	);
};
