export const customIcons: string[] = [
	'tz-custom-rhombus-20',
	'tz-custom-socket-20',
	'tz-custom-lamp-20',
	'tz-custom-shovel-20',
	'tz-custom-exclamation-point-20',
	'tz-custom-rectangles-20',
	'tz-custom-block-20',
	'tz-custom-snowflake-20',
	'tz-custom-shield-20',
	'tz-custom-triangle-20',
	'tz-custom-hexagon-20',
	'tz-custom-polygon-20',
	'tz-user-20',
	'tz-description-20',
	'tz-calendar-20',
	'tz-status-20',
	'tz-name-20',
	'tz-percent-20',
	'tz-info-20',
	'tz-price-20',
	'tz-bank-account-20'
];
