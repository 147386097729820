import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';

export const groupFilteredSpacesByObjectId = (
	spaces: IConvertedSpace[]
): Record<string, IConvertedSpace[]> =>
	spaces
		.filter(space => space.filtered)
		.reduce(
			(acc, space) => {
				const {objectId} = space;

				if (!acc[objectId]) {
					acc[objectId] = [];
				}

				acc[objectId].push(space);

				return acc;
			},
			{} as Record<string, IConvertedSpace[]>
		);
