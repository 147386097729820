import {objectsQueryKeys} from '@src/api/cache/objects/keys';
import {useAddingCheckDialog} from '@src/components/AddingCheckDialog/hooks/useAddingCheckDialog';
import {useAddingInternalAcceptanceDialog} from '@src/components/AddingInternalAcceptanceDialog/hooks/useAddingInternalAcceptanceDialog';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import {useCurrentTreeObject} from '@src/core/hooks/queries/objects/hooks';
import {
	useLocalProblemsWithAttachments,
	useProblemsList
} from '@src/core/hooks/queries/problems/hooks';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {IProblemEntity} from '@src/interfaces/IProblemEntity';
import {deleteProblem} from '@src/store/modules/pages/problem/actions';
import {ICheckAddingEntityType} from '@src/store/modules/settings/checkAdding/reducers/entityType';
import {problemsActions} from '@src/store/modules/settings/pages/problems/slice';
import {extractProblemsPageSettings} from '@src/store/modules/settings/pages/problems/selectors';
import {useQueryClient} from '@tanstack/react-query';
import {ITableContextMenuAction} from '@tehzor/tools/interfaces/table/ITableContextMenuAction';
import {EntitiesTable, Pagination, PaginationAndSize, Plate} from '@tehzor/ui-components';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {createContext, Suspense, useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {IPreparedProblem} from '../../interfaces/IPreparedProblem';
import ProblemAddingMobile from '../actions/ProblemAdding.mobile';
import {MobileRightButtons} from '../actions/RightButtons.mobile';
import MobileSelectionClearing from '../selection/SelectionClearing.mobile';
import SelectionRow from './SelectionRow';
import {IEntitySettings} from '@src/core/hooks/settings/useEntitySettings';
import {useExtractLatestCheckId} from '@src/core/hooks/queries/checks/latest/hooks';
import {useExtractLatestInternalAcceptanceId} from '@src/core/hooks/queries/internalAcceptances/latest/hooks';
import {useTranslation} from 'react-i18next';
import {useCanProblemAdd} from '@src/pages/ProblemsPage/hooks/useCanProblemAdd';
import {TranslatedPaginationPageSize} from '@src/components/TranslatedPaginationPageSize';
import {useTranslatedConfirmDialog} from '@src/core/hooks/translations/useTranslatedConfirmDialog';
import {useOnlineManager} from '@src/core/hooks/useOnlineManager';
import {IProblem} from '@tehzor/tools/interfaces/problems/IProblem';
import {usePreparedProblems} from '../../hooks/usePreparedProblems';
import {EmptyTableMessage} from '@src/components/EmptyTableMessage/EmptyTableMessage';
import {useProblemsTableColumns} from '@src/pages/ProblemsPage/hooks/useProblemsTableColumns';

const pageSizes = [10, 20, 50, 100];
const types: ICheckAddingEntityType[] = ['problem'];

interface IProblemsTableProps {
	objectId?: string;
	settings?: IEntitySettings;
}

export const DispatchActionCtx = createContext<
	(action: ITableContextMenuAction<IPreparedProblem>) => void
>(() => ({}));

const ProblemsTable = ({objectId = 'all', settings}: IProblemsTableProps) => {
	const {t} = useTranslation();
	const {search} = useLocation();
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const isDesktop = useIsDesktop();
	const networkStatus = useOnlineManager();
	const {changeOffset, changePageSize, changeSort, changeSelectedRows} = problemsActions;

	const {data: list} = useProblemsList(objectId);
	const {data: localProblems} = useLocalProblemsWithAttachments(objectId);
	const {data: latestCheckId} = useExtractLatestCheckId(objectId);
	const {data: latestInternalAcceptanceId} = useExtractLatestInternalAcceptanceId(objectId);

	const pageSettings = useAppSelector(s => extractProblemsPageSettings(s, objectId));
	const sectionsMenu = useObjectSectionsMenu(objectId, !!search);
	const pagesCount = useMemo(() => {
		if (networkStatus) {
			return Math.ceil((list?.total || 0) / pageSettings.pageSize);
		}
		return Math.ceil((localProblems?.length || 0) / pageSettings.pageSize);
	}, [list, pageSettings.pageSize, localProblems, networkStatus]);
	const currentPage = Math.floor(pageSettings.offset / pageSettings.pageSize);
	const canProblemAdd = useCanProblemAdd({objectId});
	const preparedProblems = usePreparedProblems({
		allIds: list?.allIds,
		problems: list?.problems,
		localProblems
	});

	const problemsEntities = useMemo(
		() =>
			preparedProblems.map(item => ({
				id: item.id,
				type: 'problem',
				data: item
			})) as IProblemEntity[],
		[preparedProblems]
	);
	const selectedEntities = useMemo(
		() => problemsEntities.filter(item => pageSettings.selectedRows.includes(item.id)),
		[pageSettings.selectedRows, problemsEntities]
	);
	const [problemToCopy, setProblemToCopy] = useState<IPreparedProblem>();
	const [deleteDialog, getDeleteConfirmation] = useTranslatedConfirmDialog({
		title: t('problemsPage.deleteDialog.title'),
		message: ''
	});

	const problem = useMemo(() => {
		if (list && problemToCopy?.id) {
			return list.problems.find(item => item.id === problemToCopy.id);
		}
		return undefined;
	}, [list, problemToCopy?.id]);

	const handleRowClick = useCallback(
		(problem: IProblemEntity) => {
			if (problem.data.object && problem.type === 'problem') {
				pushPath(`/objects/${problem.data.object.id}/problems/${problem.data.id}`);
			}
		},
		[pushPath]
	);

	const handleSelectedRowsChange = useCallback(
		(value: string[]) => dispatch(changeSelectedRows({objectId, selectedRows: value})),
		[changeSelectedRows, dispatch, objectId]
	);
	const object = useCurrentTreeObject(objectId);

	const handleSortChange = useCallback(
		(value: {[key: string]: boolean}) => {
			dispatch(changeSort({objectId, sort: value}));
		},
		[changeSort, dispatch, objectId]
	);

	const handlePageSizeChange = useCallback(
		(value: number) => {
			dispatch(changePageSize({objectId, pageSize: value}));
			dispatch(changeOffset({objectId, offset: Math.floor(pageSettings.offset / value)}));
		},
		[changeOffset, changePageSize, dispatch, objectId, pageSettings.offset]
	);

	const handlePageChange = useCallback(
		({selected}: {selected: number}) => {
			const offset = selected * pageSettings.pageSize;
			if (pageSettings.offset !== offset) {
				dispatch(changeOffset({objectId, offset}));
			}
		},
		[changeOffset, dispatch, objectId, pageSettings.offset, pageSettings.pageSize]
	);

	const formProblemCopyData = (
		entity: keyof NonNullable<IProblem['links']>,
		entityLink?: string
	) => ({
		objectId: problemToCopy?.objectId || 'all',
		[entity]: entityLink,
		stage: problem?.stage,
		types,
		defaultProblemData: {
			...problem,
			categoryId: problem?.categoryId,
			location: undefined,
			floor: undefined
		},
		problemToCopyId: problemToCopy?.id
	});
	const [copyToCheckDialog, openCopyToCheckDialog] = useAddingCheckDialog(
		formProblemCopyData('checkId', latestCheckId)
	);

	const [copyToInternalDialog, openCopyToInternalDialog] = useAddingInternalAcceptanceDialog(
		formProblemCopyData('internalAcceptanceId', latestInternalAcceptanceId)
	);

	const handleContextMenuAction = useCallback(
		async (action: ITableContextMenuAction<IPreparedProblem>) => {
			if (action.type === 'delete') {
				if (action.payload.object && (await getDeleteConfirmation())) {
					await dispatch(deleteProblem(action.payload.object.id, action.payload.id));
					await queryClient.refetchQueries({
						queryKey: [...objectsQueryKeys.stats(), [action.payload.object.id]]
					});
				}
			}
			if (action.type === 'copy') {
				setProblemToCopy(action.payload);
				if (action.payload.links?.checkId) {
					openCopyToCheckDialog();
				} else if (action.payload.links?.internalAcceptanceId) {
					openCopyToInternalDialog();
				}
			}
		},
		[
			dispatch,
			getDeleteConfirmation,
			openCopyToCheckDialog,
			openCopyToInternalDialog,
			queryClient
		]
	);

	useObjectAppHeader(
		objectId,
		{
			title: objectId === 'all' ? t('problemsPage.header.title') : object?.name,
			sectionsMenu: objectId === 'all' ? undefined : sectionsMenu,
			showBackBtn: objectId !== 'all',
			mobileLeftButton:
				selectedEntities.length > 0 ? (
					<MobileSelectionClearing
						onSelectedRowsChange={() => handleSelectedRowsChange([])}
					/>
				) : undefined,
			mobileRightButtons: !isDesktop ? (
				<DispatchActionCtx.Provider value={handleContextMenuAction}>
					<MobileRightButtons
						objectId={objectId !== 'all' ? objectId : undefined}
						selectedEntities={selectedEntities}
						selectedClearing={() => {
							handleSelectedRowsChange([]);
						}}
					/>
				</DispatchActionCtx.Provider>
			) : null
		},
		[objectId, sectionsMenu, selectedEntities, pageSettings.selectedRows, isDesktop, t]
	);

	const [columns, activeColumnsLength] = useProblemsTableColumns({
		objectId,
		settings,
		isDesktop,
		t
	});

	if (isDesktop && !activeColumnsLength) {
		return <EmptyTableMessage />;
	}

	return (
		<>
			<DispatchActionCtx.Provider value={handleContextMenuAction}>
				<Plate withoutPadding>
					<EntitiesTable
						className="problems-page__table"
						columns={columns}
						data={problemsEntities}
						selectedRows={pageSettings.selectedRows}
						sort={pageSettings.sort}
						selectable={isDesktop}
						onRowClick={handleRowClick}
						onSelectedRowsChange={handleSelectedRowsChange}
						onSortChange={handleSortChange}
						hideLastHeaderCell={isDesktop}
						headVisible={isDesktop}
						renderSelectionRow={props => (
							<SelectionRow
								{...props}
								objectId={objectId}
							/>
						)}
					/>
					{!isDesktop && canProblemAdd && objectId !== undefined && (
						<Suspense>
							<ProblemAddingMobile objectId={objectId} />
						</Suspense>
					)}
				</Plate>
			</DispatchActionCtx.Provider>

			<PaginationAndSize
				pagination={
					<Pagination
						pageCount={pagesCount}
						forcePage={currentPage}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						onPageChange={handlePageChange}
					/>
				}
				pageSize={
					<TranslatedPaginationPageSize
						pageSize={pageSettings.pageSize}
						pageSizeOptions={pageSizes}
						onPageSizeChange={handlePageSizeChange}
					/>
				}
			/>
			{copyToCheckDialog}
			{copyToInternalDialog}
			{deleteDialog}
		</>
	);
};

export default ProblemsTable;
