import {useMutation} from '@tanstack/react-query';
import {spaceStatusesSetsQueryKeys} from '@src/api/cache/spaceStatusesSets/keys';
import {ISavingSpaceStatusesSet} from '@tehzor/tools/interfaces/spaceStatusesSets/ISavingSpaceStatusesSet';
import {queryClient} from '@src/api/QueryClient';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {requestEditSpaceStatusesSet} from '@src/api/backend/spaceStatusesSets/edit';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';

interface IUseEditSpaceStatusesSetsMutationParams {
	spaceStatusesSetId: string;
	fields: ISavingSpaceStatusesSet;
}

export const useEditSpaceStatusesSets = () => {
	const {t} = useTranslation();

	return useMutation({
		mutationKey: spaceStatusesSetsQueryKeys.edit(),
		mutationFn: (params: IUseEditSpaceStatusesSetsMutationParams) => {
			const {spaceStatusesSetId, fields} = params;
			return requestEditSpaceStatusesSet(spaceStatusesSetId, fields);
		},
		onError: () => {
			addErrorToast({
				title: t('toast.editSpaceStatusesSet.error.title')
			});
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({queryKey: spaceStatusesSetsQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: spacesQueryKeys.listWithStats()});
		}
	});
};
