import classNames from 'classnames';
import {LoadingPanel} from '@tehzor/ui-components';
import {ClaimsPageCounter} from './components/ClaimsPageHeader';
import ClaimsFilters from './components/filters/ClaimsFilters';
import ClaimsTable from './components/table/ClaimsTable';
import useScrollRestoration from '@src/core/hooks/useScrollRestoration';
import ObjectPageBreadcrumbs from './components/ObjectPageBreadcrumbs';
import AllPageBreadcrumbs from './components/AllPageBreadcrumbs';
import {useParams} from 'react-router-dom';
import {useWarrantyClaims} from '@src/core/hooks/queries/warrantyClaims/hooks';
import './WarrantyClaimsPage.less';
import {useCallback} from 'react';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {WarrantyClaimsSearch} from '@src/pages/WarrantyClaimsPage/components/WarrantyClaimsSearch/WarrantyClaimsSearch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {extractWarrantyClaimsPageSettings} from '@src/store/modules/settings/pages/warrantyClaims/selectors';
import {ColumnsCustomizer} from '@src/components/ColumnsCustomizer/ColumnsCustomizer';
import {
	changeColumnVisible,
	showAllColumns
} from '@src/store/modules/settings/pages/warrantyClaims/slices';
import {useWarrantyClaimSettings} from '@src/core/hooks/settings/useWarrantyClaimSettings';
import {WarrantyClaimsActionsDesktop} from '@src/pages/WarrantyClaimsPage/components/actions/WarrantyClaimsActionsDesktop';

const WarrantyClaimsPage = ({className}: {className?: string}) => {
	useScrollRestoration();

	const {objectId} = useParams<{objectId?: string}>();
	const {data: claimsData, isLoading} = useWarrantyClaims(objectId);
	const {tableSettings} = useAppSelector(s => extractWarrantyClaimsPageSettings(s, objectId));
	const dispatch = useAppDispatch();
	const isDesktop = useIsDesktop();
	const {custom} = useWarrantyClaimSettings(objectId);

	const onChangeColumnVisible = useCallback(
		(columnKey: string) => {
			dispatch(changeColumnVisible({objectId: objectId || 'all', columnKey}));
		},
		[dispatch, changeColumnVisible, objectId]
	);

	const onShowAllColumns = useCallback(() => {
		dispatch(showAllColumns({objectId: objectId || 'all'}));
	}, [dispatch, showAllColumns, objectId]);

	const actions = <WarrantyClaimsActionsDesktop objectId={objectId} />;

	return (
		<LoadingPanel
			className="w-claims-page__loading-panel"
			active={isLoading}
		>
			<div
				className={classNames(
					'page-cont',
					'w-claims-page',
					'warranty-claims-table',
					{'w-claims-page_small-margin': !objectId},
					className
				)}
			>
				<div className="w-claims-page__head-wrap">
					{objectId ? (
						<ObjectPageBreadcrumbs objectId={objectId}>
							{isDesktop && actions}
						</ObjectPageBreadcrumbs>
					) : (
						<AllPageBreadcrumbs>{isDesktop && actions}</AllPageBreadcrumbs>
					)}

					<div className="w-claims-page__filters-bar">
						{!isDesktop && (
							<div className="w-claims-page__search">
								<WarrantyClaimsSearch
									customFieldsSettings={custom}
									objectId={objectId}
								/>
							</div>
						)}
						<ClaimsFilters
							customFieldsSettings={custom}
							objectId={objectId}
						/>
					</div>

					<div className="w-claims-page__counter-bar">
						<ClaimsPageCounter objectId={objectId} />
						{isDesktop && (
							<ColumnsCustomizer
								tableSettings={tableSettings}
								onChangeColumnsVisible={onChangeColumnVisible}
								onShowAllColumns={onShowAllColumns}
							/>
						)}
					</div>
				</div>
				<ClaimsTable
					objectId={objectId}
					claimsData={claimsData}
				/>
			</div>
		</LoadingPanel>
	);
};

export default WarrantyClaimsPage;
