import {memo} from 'react';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {EntityGrid, EntityGridItem} from '@tehzor/ui-components';
import IObjectStage from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ObjectStages} from './ObjectStages';
import {ObjectManager} from './ObjectManager';
import {ObjectProblems} from './ObjectProblems';
import {useTranslation} from 'react-i18next';
import {ICustomFieldSetting} from '@tehzor/tools/interfaces/fields';
import {CustomField} from '@src/components/CustomField';

interface IDesktopInfoProps {
	object: IObject;
	stages: IObjectStage[];
	customFieldsSettings: Record<string, ICustomFieldSetting>;
}

const nameIcon = <i className="tz-name-20" />;
const cityIcon = <i className="tz-location-20" />;
const addressIcon = <i className="tz-address-20" />;
const contractorIcon = <i className="tz-company-20" />;

export const DesktopInfo = memo(({object, stages, customFieldsSettings}: IDesktopInfoProps) => {
	const {t} = useTranslation();
	return (
		<>
			<EntityGrid
				withBorders
				className={{wrap: 'object-page__d-entities-grid'}}
			>
				<EntityGridItem className={{content: 'object-page__d-entities-grid-title'}}>
					{t('objectPage.info.title')}
				</EntityGridItem>
				<EntityGridItem
					label={t('objectPage.info.name.label')}
					icon={nameIcon}
					inline
				>
					{object.name}
				</EntityGridItem>

				{object.city && (
					<EntityGridItem
						label={t('objectPage.info.city.label')}
						icon={cityIcon}
						inline
					>
						{object.city}
					</EntityGridItem>
				)}

				{object.address && (
					<EntityGridItem
						label={t('objectPage.info.address.label')}
						icon={addressIcon}
						inline
					>
						{object.address}
					</EntityGridItem>
				)}

				{object.generalContractor && (
					<EntityGridItem
						label={t('objectPage.info.generalContractor.label')}
						icon={contractorIcon}
						inline
					>
						{object.generalContractor}
					</EntityGridItem>
				)}

				<ObjectManager
					label={t('objectPage.info.projectManager.label')}
					value={object.projectManager}
					isDesktop
				/>

				<ObjectManager
					label={t('objectPage.info.constructionManager.label')}
					value={object.constructionManager}
					isDesktop
				/>
				{customFieldsSettings &&
					Object.values(customFieldsSettings).map(customFieldsSetting => (
						<CustomField
							key={customFieldsSetting.id}
							fields={object.customFields}
							setting={customFieldsSetting}
							inline
						/>
					))}
			</EntityGrid>

			<ObjectStages
				object={object}
				stages={stages}
				isDesktop
			/>

			<ObjectProblems
				object={object}
				isDesktop
			/>
		</>
	);
});
