import {Suspense, memo} from 'react';
import {DesktopSpaceExport} from './SpaceExport.desktop';
import {useSpacesPermissions} from '@src/core/hooks/permissions/useSpacesPermissions';
import {EntityAdding} from './EntityAdding';
import {LoadingPanel} from '@tehzor/ui-components';

interface IDesktopTopActionsProps {
	objectId: string;
	spaceId: string;
}

export const MainActionsDesktop = memo(({objectId, spaceId}: IDesktopTopActionsProps) => {
	const permissions = useSpacesPermissions(objectId);

	return (
		<>
			<Suspense fallback={<LoadingPanel />}>
				<EntityAdding
					objectId={objectId}
					spaceId={spaceId}
				/>
			</Suspense>

			{permissions.canExport && (
				<DesktopSpaceExport
					objectId={objectId}
					spaceId={spaceId}
				/>
			)}
		</>
	);
});
