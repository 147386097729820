import {forwardRef, Ref} from 'react';
import styles from './AddingPresaleCheckDialog.module.less';
import {EntityTypeSelect} from '../EntityTypeSelect';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useEntityPresaleCheckType} from '../../hooks/useEntityPresaleCheckType';
import {useAvailablePresaleCheckTypes} from '../../hooks/useAvailablePresaleCheckTypes';
import {ISavingInspection} from '@src/interfaces/saving/ISavingInspection';
import {useSpaceLocation} from '@src/components/AddingCheckDialog/hooks/useSpaceLocation';
import {ISavingProblem} from '@src/interfaces/saving/ISavingProblem';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {IPresaleCheckAddingEntityType} from '../../model/slice/presaleCheckAdding/interfaces';
import AddingInspection, {
	IAddingInspectionRefProps
} from '@src/components/AddingInspection/AddingInspection';
import {IAddingProblemRefProps} from '@src/components/AddingProblem';
import AddingProblem from '@src/components/AddingProblem/AddingProblem';

interface IAddingPresaleCheckDialogProps {
	objectId: string;
	links?: IPresaleCheck['links'];
	stage: ObjectStageIds;
	processId: ProcessIds;
	scope?: string;
	types?: IPresaleCheckAddingEntityType[];
	defaultProblemData?: ISavingProblem;
	defaultInspectionData?: ISavingInspection;
	selectedPresaleCheckId?: string;
	onClose: () => void;
	setSaving: (s: boolean) => void;
	saving: boolean;
	isMultiple?: boolean;
}

const AddingPresaleCheckGlobal = (
	props: IAddingPresaleCheckDialogProps,
	ref?: Ref<IAddingProblemRefProps | IAddingInspectionRefProps>
) => {
	const {
		objectId,
		links,
		stage,
		processId,
		scope,
		types,
		onClose,
		selectedPresaleCheckId,
		setSaving,
		saving,
		isMultiple
	} = props;

	const availableTypes = useAvailablePresaleCheckTypes(objectId, types);
	const type = useEntityPresaleCheckType(objectId, availableTypes);
	const [defaultProblemData, defaultInspectionData] = useSpaceLocation(
		objectId,
		selectedPresaleCheckId,
		props.defaultProblemData,
		props.defaultInspectionData
	);

	return (
		<div>
			<div className={styles.addingPresaleCheckDialogHeader}>
				{type !== undefined && (
					<EntityTypeSelect
						type={type}
						types={availableTypes}
						disabled={saving}
					/>
				)}
			</div>
			{type === 'problem' ? (
				<AddingProblem
					objectId={objectId}
					links={links}
					stage={stage}
					processId={processId}
					scope={scope}
					defaultData={defaultProblemData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={ref}
					isMultiple={isMultiple}
				/>
			) : type === 'inspection' ? (
				<AddingInspection
					objectId={objectId}
					links={links}
					stage={stage}
					processId={processId}
					defaultData={defaultInspectionData}
					saving={saving}
					onClose={onClose}
					setSaving={setSaving}
					ref={ref}
					isMultiple={isMultiple}
				/>
			) : null}
		</div>
	);
};

export default forwardRef<
	IAddingProblemRefProps | IAddingInspectionRefProps,
	IAddingPresaleCheckDialogProps
>(AddingPresaleCheckGlobal);
