import {useExtractExportTemplateById} from '@src/core/hooks/queries/exportTemplates/hooks';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getSelectedTemplate} from '@src/selectors/settings/export';
import {getCurrentTemplateTypeCount} from '@src/utils/exportTemplates/getCurrentTemplateTypeCount';
import {IExportLimits} from '@tehzor/tools/interfaces/export/IExportLimits';
import {useMemo} from 'react';

export const useCurrentExportTemplateTypeInfo = (
	entityName: string,
	exportLimitsByEntity: IExportLimits
) => {
	const selectedTemplateId = useAppSelector(s => getSelectedTemplate(s, entityName));
	const {data: template} = useExtractExportTemplateById(selectedTemplateId);
	const currentTemplateType = template?.outputFileType;

	const templateTypeMaxCount = getCurrentTemplateTypeCount(
		currentTemplateType,
		exportLimitsByEntity
	);
	return useMemo(
		() => ({templateTypeMaxCount, currentTemplateType}),
		[currentTemplateType, templateTypeMaxCount]
	);
};
