import {IAddingInspectionRefProps} from '@src/components/AddingInspection';
import {IAddingProblemRefProps} from '@src/components/AddingProblem';
import {Dispatch, RefObject, SetStateAction} from 'react';
import {IPresaleCheck} from '@tehzor/tools/interfaces/presaleChecks/IPresaleCheck';
import {IPresaleCheckAddingEntityType} from '../model/slice/presaleCheckAdding/interfaces';
import {IAddMultiplePresaleChecksWithEntitiesParams} from '../model/hooks/useAddMultiplePresaleChecksWithEntities/useAddMultiplePresaleChecksWithEntities';

interface ISaveMultiplePresaleCheckProps {
	objectId: string;
	spaceIds: string[];
	addingEntityRef: RefObject<IAddingProblemRefProps | IAddingInspectionRefProps>;
	presaleCheckType: IPresaleCheckAddingEntityType;
	links: IPresaleCheck['links'];
	addMultiplePresaleChecksWithEntities: (
		params: IAddMultiplePresaleChecksWithEntitiesParams
	) => Promise<void>;
	setSaving: Dispatch<SetStateAction<boolean>>;
	onClose: () => void;
}

/**
 * Логика для сохранения множества предпродажных проверок.
 */
export const saveMultiplePresaleChecks = async ({
	objectId,
	spaceIds,
	addingEntityRef,
	presaleCheckType,
	links,
	addMultiplePresaleChecksWithEntities,
	setSaving,
	onClose
}: ISaveMultiplePresaleCheckProps) => {
	if (!addingEntityRef.current) {
		return;
	}
	const savingData = await addingEntityRef.current.getSavingData(true);
	if (!savingData) {
		return;
	}

	setSaving(true);
	onClose();
	await addMultiplePresaleChecksWithEntities({
		objectId,
		spaceIds,
		links,
		savingData,
		presaleCheckType,
		setSaving,
		onClose
	});
};
