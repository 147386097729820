import {
	useCheckRecordStatusChangeItemMutation,
	useCheckRecordStatusChangeListMutation
} from '@src/api/cache/checkRecords/mutations';
import {ICheckItem} from '@tehzor/tools/interfaces/checkItems/ICheckItem';
import {ICheckList} from '@tehzor/tools/interfaces/checkLists/ICheckList';
import {ICheckRecord} from '@tehzor/tools/interfaces/checkRecords/ICheckRecord';
import {CheckRecordStatusId} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {useCallback} from 'react';
import {useObject} from '@src/core/hooks/queries/objects/hooks';
import {useCheckLists} from '@src/core/hooks/queries/checkLists/hooks';
import {useSpace} from '../../queries/space';
import {useCheckRecordsStatuses} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {useIsMutating} from '@tanstack/react-query';
import {checkRecordsQueryKeys} from '@src/api/cache/checkRecords/keys';
import {queryClient} from '@src/api/QueryClient';
import {checkListStoriesQueryKeys} from '@src/api/cache/checkListStories/keys';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';

export interface IChangeCheckRecordJournalData {
	objectName: string;
	locationName?: string;
	checkItemName?: string;
	checkListName?: string;
	newStatus: string;
}

export interface IChangeCheckRecordStatusParams {
	item: ICheckList | ICheckItem;
	objectId: string;
	spaceId: string | undefined;
	workAcceptanceId: string | undefined;
	stage: ObjectStageIds;
	status: CheckRecordStatusId;
	record: ICheckRecord;
}

export const useChangeCheckRecord = (
	objectId: string,
	spaceId: string | undefined,
	item?: ICheckList | ICheckItem
) => {
	const {data: space} = useSpace(spaceId, objectId);
	const {data: object} = useObject(objectId);
	const {mutate: mutateCheckListRecord} = useCheckRecordStatusChangeListMutation();
	const checkListIsLoading = !!useIsMutating({
		mutationKey: checkRecordsQueryKeys.changeList(),
		predicate: mutation => !mutation.state.isPaused
	});
	const {mutate: mutateCheckItemRecord} = useCheckRecordStatusChangeItemMutation();
	const checkItemIsLoading = !!useIsMutating({
		mutationKey: checkRecordsQueryKeys.changeItem(),
		predicate: mutation => !mutation.state.isPaused
	});
	const {data: checklists} = useCheckLists();
	const {data: statuses} = useCheckRecordsStatuses();
	const isLoading = checkListIsLoading || checkItemIsLoading;

	const changeCheckListRecord = useCallback(
		(params: IChangeCheckRecordStatusParams) => {
			const key = `local-${Date.now().toString(10)}`;

			if (!statuses) return;

			const journalData = {
				objectName: object ? object.name : '',
				locationName: space?.name,
				checkListName: params.item.name,
				newStatus: statuses.byId[params.status].name
			};
			mutateCheckListRecord(
				{
					key,
					journalData,
					...params
				},
				{
					onSuccess: () => {
						const checkItem = item as ICheckList;
						const links = {
							checkListId: checkItem.id,
							spaceId,
							workAcceptanceId: params.workAcceptanceId
						};
						void queryClient.invalidateQueries({
							queryKey: checkListStoriesQueryKeys.list(objectId, params.stage, links)
						});
						void queryClient.invalidateQueries({queryKey: spacesQueryKeys.list()});
						void queryClient.invalidateQueries({queryKey: spacesQueryKeys.problems()});
					}
				}
			);
		},
		[statuses, object, space?.name, mutateCheckListRecord, item, spaceId, objectId]
	);

	const changeCheckItemRecord = useCallback(
		(params: IChangeCheckRecordStatusParams) => {
			const key = `local-${Date.now().toString(10)}`;
			const checkItem = item as ICheckItem;
			const checkListName = checkItem.checkListId
				? checklists?.byId[checkItem.checkListId]?.name
				: undefined;

			if (!statuses) return;

			const journalData = {
				objectName: object ? object.name : '',
				locationName: space?.name,
				checkListName,
				checkItemName: params.item.name,
				newStatus: statuses?.byId[params.status].name
			};
			mutateCheckItemRecord(
				{
					key,
					journalData,
					...params
				},
				{
					onSuccess: () => {
						const links = {
							checkListId: checkItem.checkListId,
							spaceId,
							workAcceptanceId: params.workAcceptanceId
						};
						void queryClient.invalidateQueries({
							queryKey: checkListStoriesQueryKeys.list(objectId, params.stage, links)
						});
						void queryClient.invalidateQueries({
							queryKey: checkListStoriesQueryKeys.lists()
						});
						void queryClient.invalidateQueries({
							queryKey: spacesQueryKeys.listWithStats()
						});
						void queryClient.invalidateQueries({queryKey: spacesQueryKeys.problems()});
					}
				}
			);
		},
		[
			item,
			checklists?.byId,
			statuses,
			object,
			space?.name,
			mutateCheckItemRecord,
			spaceId,
			objectId
		]
	);
	return {
		changeCheckListRecord,
		changeCheckItemRecord,
		isLoading
	};
};
