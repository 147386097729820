import {queryClient} from '@src/api/QueryClient';
import SocketConnector from '@src/api/SocketConnector';
import {spacesQueryKeys} from '@src/api/cache/spaces/keys';
import {warrantyClaimsQueryKeys} from '@src/api/cache/warrantyClaims/keys';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {
	deselectAllSpaces,
	selectSpaces,
	toggleSelectionSpacesVisibility
} from '@src/store/modules/settings/pages/spaces/actions';
import {IMultipleWarrantyClaimsCreationFailed} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/warrantyClaims/IMultipleWarrantyClaimsCreationFailed';
import {IMultipleWarrantyClaimsCreationFinished} from '@tehzor/tools/interfaces/multipleEntitiesCreationNotifications/warrantyClaims/IMultipleWarrantyClaimsCreationFinished';
import declination from '@tehzor/tools/utils/declination';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {useCallback, useEffect} from 'react';

export const useMultipleWarrantyClaimsCreationEventHandler = () => {
	const socketConnector = SocketConnector.instance;
	const dispatch = useAppDispatch();
	const {objectId: parentObjectId} = useStrictParams();

	const handleFinishedWarrantyClaimsCreation = useCallback(
		(message: IMultipleWarrantyClaimsCreationFinished) => {
			dispatch(deselectAllSpaces(parentObjectId as string));
			dispatch(toggleSelectionSpacesVisibility(false));

			addSuccessToast({
				title: `${declination(message.data?.length, [
					'Добавлено',
					'Добавлено',
					'Добавлено'
				])} ${message.data?.length} ${declination(message.data?.length, [
					'гарантийное обращение',
					'гарантийных обращения',
					'гарантийных обращений'
				])}`
			});
			if (message?.data) {
				void queryClient.invalidateQueries({
					queryKey: warrantyClaimsQueryKeys.list()
				});
				void queryClient.invalidateQueries({queryKey: spacesQueryKeys.warrantyClaims()});
			}
		},
		[dispatch, parentObjectId]
	);
	const handleFailedWarrantyClaimsCreation = useCallback(
		(message: IMultipleWarrantyClaimsCreationFailed) => {
			const {objectId, data, spacesType} = message;
			const convertedData = data.map(spaceId => ({id: spaceId, type: spacesType}));
			dispatch(
				selectSpaces(parentObjectId as string, [...data], {[objectId]: convertedData})
			);
			addErrorToast({title: 'Ошибка при создании гарантийных обращений'});
		},
		[]
	);

	useEffect(() => {
		socketConnector.subscribeOnMultipleWarrantyClaimsCreationEvents(
			handleFinishedWarrantyClaimsCreation,
			handleFailedWarrantyClaimsCreation
		);
		return () => socketConnector.unsubscribeOnMultipleWarrantyClaimsCreationEvents();
	}, [handleFinishedWarrantyClaimsCreation, handleFailedWarrantyClaimsCreation]);
};
