export type IGetPaginateUnitIndicatorsQueryKey = [] & {
	0: string; // unit-indicators
	1: string; // paginate
	2?: Record<string, unknown>; // filters
	3?: Record<string, boolean>; // sort
	4?: number; // offset
	5?: number; // limit
};

export type IGetListUnitIndicatorsQueryKey = [] & {
	0: string; // unit-indicators
	1: string; // list
	2?: Record<string, unknown>; // filters
};

export type IGetUnitIndicatorQueryKey = [] & {
	0: string; // unit-indicator
	1: string; // detail
	2: string; // unitIndicatorId
};

export const unitIndicatorsQueryKeys = {
	all: () => ['unit-indicators'],
	one: () => ['unit-indicator'],
	lists: () => [...unitIndicatorsQueryKeys.all(), 'list'],
	list: (filters?: Record<string, unknown>) => [...unitIndicatorsQueryKeys.lists(), filters],
	paginates: () => [...unitIndicatorsQueryKeys.all(), 'paginate'],
	paginate: (
		filters?: Record<string, unknown>,
		sort?: Record<string, boolean>,
		offset?: number,
		limit?: number
	) => [...unitIndicatorsQueryKeys.paginates(), filters, sort, offset, limit],
	details: () => [...unitIndicatorsQueryKeys.one(), 'detail'],
	detail: (unitIndicatorId: string) => [...unitIndicatorsQueryKeys.details(), unitIndicatorId]
};
