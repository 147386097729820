import {httpRequests} from '@tehzor/tools/api/httpRequests';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IUnitIndicatorsSet} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicatorsSet';
import {convertSortParam} from '@tehzor/tools/utils/convertSortParam';

export interface IGetPaginateUnitIndicatorsSetsResponse
	extends INormalizedData<IUnitIndicatorsSet> {
	offset: number;
	limit: number;
	total: number;
}

/**
 * Получает пагинированный список наборов индикаторов помещений
 * 	@param filters  Фильтры запроса
 *  @param sort Сортировка
 *  @param offset Оффсет
 *  @param  limit Ограничение
 */
export const requestGetPaginateUnitIndicatorsSets = async (
	filters?: Record<string, unknown>,
	sort?: Record<string, boolean>,
	offset?: number,
	limit?: number
) => {
	const params = {
		filters,
		sort: convertSortParam(sort),
		offset,
		limit
	};

	const response = await httpRequests.withToken.post<IGetPaginateUnitIndicatorsSetsResponse>(
		'unit-indicators-sets/get-paginate',
		params
	);
	return response.data;
};
