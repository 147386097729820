import {useCallback, useEffect, useState, ReactNode} from 'react';
import {ExportDialog} from '@src/components/ExportDialog';
import IExportTemplate, {
	ExportTemplatesDestinationId
} from '@tehzor/tools/interfaces/IExportTemplate';
import {IWarrantyClaimEntity} from '@src/interfaces/IWarrantyClaimEntity';
import {IOwnerAcceptanceEntity} from '@src/interfaces/IOwnerAcceptanceEntity';
import {useExportProblems} from '@src/core/hooks/mutations/problems/useExportProblems';
import {useExportOwnerAcceptance} from '@src/core/hooks/mutations/ownerAcceptances/useExportOwnerAcceptance';

function useDestinations(selectedEntities: IOwnerAcceptanceEntity[]) {
	const [destinations, setDestinations] = useState<ExportTemplatesDestinationId[]>([
		ExportTemplatesDestinationId.OWNER_ACCEPTANCE
	]);

	useEffect(() => {
		let hasProblems = false;
		for (const item of selectedEntities) {
			if (item.type === 'problem') {
				hasProblems = true;
				break;
			}
		}

		// Экспорт заявления и списка нарушений
		if (hasProblems) {
			return setDestinations([
				ExportTemplatesDestinationId.OWNER_ACCEPTANCE,
				ExportTemplatesDestinationId.PROBLEMS
			]);
		}
		return undefined;
	}, [selectedEntities]);

	return destinations;
}

const splitSelectedEntities = (selectedEntities: IWarrantyClaimEntity[]) =>
	selectedEntities.reduce<{
		problems: string[];
	}>(
		(prev, item) => {
			switch (item.type) {
				case 'problem':
					prev.problems.push(item.data.id);
					break;
			}
			return prev;
		},
		{problems: []}
	);

/**
 * Хук для экспорта приёмки или ее нарушений в зависимости от того, что выбрано
 *
 * @param objectId id объекта
 * @param selectedEntities выбранные нарушения/осмотры
 */
export function useOwnerAcceptanceCombinedExport(
	objectId: string,
	selectedEntities: IOwnerAcceptanceEntity[]
): [ReactNode, (id: string) => void] {
	const [exportingId, setExportingId] = useState<string>();
	const destinations = useDestinations(selectedEntities);

	const [isOpen, setOpen] = useState<boolean>(false);
	const {mutateAsync: exportOwnerAcceptance} = useExportOwnerAcceptance();
	const {mutateAsync: exportProblems} = useExportProblems(objectId);
	const openDialog = useCallback((id: string) => {
		setExportingId(id);
		setOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setExportingId(undefined);
		setOpen(false);
	}, []);

	const handleExport = useCallback(
		async (template: IExportTemplate, email?: string, createDocument?: boolean) => {
			if (template.destination === ExportTemplatesDestinationId.OWNER_ACCEPTANCE) {
				if (exportingId) {
					const {problems} = splitSelectedEntities(selectedEntities);
					await exportOwnerAcceptance({
						email,
						templateId: template.id,
						objectId,
						acceptanceId: exportingId,
						problems,
						createDocument
					});
				}
			} else if (template.destination === ExportTemplatesDestinationId.PROBLEMS) {
				const selected = selectedEntities.map(item => item.data.id);
				await exportProblems({
					templateId: template.id,
					objectId,
					createDocument,
					email,
					filters: undefined,
					sort: undefined,
					selected
				});
			}
		},
		[exportingId, selectedEntities, objectId, exportProblems]
	);

	const dialog = (
		<ExportDialog
			objectId={objectId}
			pageKey="warranty-claim"
			destinations={destinations}
			isOpen={isOpen}
			onExport={handleExport}
			onClose={closeDialog}
		/>
	);

	return [dialog, openDialog];
}
