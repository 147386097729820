import {useMutation} from '@tanstack/react-query';
import {inspectionsQueryKeys} from '@src/api/cache/inspections/keys';
import {makeInspectionsExportRequest} from '@src/api/backend/inspections';
import {IDefferedExportResponse} from '@tehzor/tools/interfaces/export';
import {addErrorToast} from '@tehzor/ui-components';
import {useTranslation} from 'react-i18next';
import useAppDispatch from '../../useAppDispatch';
import {addExportTask} from '@src/store/modules/defferedTasks/export/slice';
import {exportInspectionsLimit} from '@src/constants/export/exportLimits';
import {useCurrentExportTemplateTypeInfo} from '@src/core/hooks/useCurrentExportTemplateTypeInfo';
import {showWarningExportToast} from '@src/utils/exportTemplates/showWarningExportToast';
import {useInspectionsTotal} from '@src/core/hooks/queries/inspections/hooks';

interface IExportInspectionsParams {
	templateId: string;
	objectId: string;
	createDocument: boolean | undefined;
	email: string | undefined;
	filters: Record<string, unknown> | undefined;
	sort: Record<string, boolean> | undefined;
	selected?: string[] | undefined;
}

export const useExportInspections = (objectId: string) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const {data: total} = useInspectionsTotal(objectId);
	const templateTypeInfo = useCurrentExportTemplateTypeInfo(
		'inspections',
		exportInspectionsLimit
	);

	return useMutation<IDefferedExportResponse, Error, IExportInspectionsParams>({
		mutationKey: inspectionsQueryKeys.export(),
		mutationFn: params => {
			const {templateId, objectId, createDocument, email, filters, sort, selected} = params;
			showWarningExportToast({
				selected,
				total,
				templateTypeInfo,
				t
			});
			return makeInspectionsExportRequest(
				templateId,
				createDocument,
				email,
				objectId,
				filters,
				sort,
				selected
			);
		},
		onSuccess: (response, params) => {
			dispatch(addExportTask({taskId: response.taskId, email: !!params.email}));
		},
		onError: () => {
			addErrorToast({title: t('toast.export.error.title')});
		}
	});
};
