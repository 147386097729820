import {IGetListUnitIndicatorsSetsResponse} from '@src/api/backend/unitIndicatorsSets';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IUnitIndicatorsSet} from '@tehzor/tools/interfaces/unitIndicatorsSets/IUnitIndicatorsSet';

/**
 * Селектор, который фильтрует наборы индикаторов по наличию `objectId` в поле `relations`.
 */
export const extractUnitIndicatorsByObjectId = (
	data: {sets: IUnitIndicatorsSet[]},
	objectId: string
): IUnitIndicatorsSet[] => {
	if (!data || !data.sets) return [];
	return data.sets.filter(
		(set: IUnitIndicatorsSet) => set.relations?.some(relation => relation.objectId === objectId)
	);
};

/**
 * Возвращает индикаторы в виде массива
 */
export const extractUnitIndicatorsSetsAsArray = (data: IGetListUnitIndicatorsSetsResponse) =>
	data.allIds.map((id: string) => data.byId[id]);

/**
 * Возвращает индикаторы в виде мапа
 */
export const extractUnitIndicatorsSetsAsMap = (data: IGetListUnitIndicatorsSetsResponse) =>
	data.byId;

const isStageMatch = (set: IUnitIndicatorsSet, stage?: string) => {
	if (!stage) {
		return true;
	}
	return set.stageIds.includes(stage);
};

const isObjectsMatch = (set: IUnitIndicatorsSet, objectId?: string) => {
	if (!objectId) {
		return true;
	}
	if (!set.relations || !set.relations?.length) {
		return true;
	}
	return set.relations?.some(relation => relation.objectId === objectId);
};

/**
 * Фильтрует индикаторы по актуальным стадиям и привязкам к объектам
 */
export const filterByRelationsAndStage = (
	sets: IUnitIndicatorsSet[],
	object?: IObject,
	stage?: string
): IUnitIndicatorsSet[] =>
	sets.filter(set => {
		if (set.companyId !== object?.companyId) {
			return false;
		}

		return isStageMatch(set, stage) && isObjectsMatch(set, object.id);
	});

/**
 * Возвращает наборы индикаторов по id компании
 */
export const extractUnitIndicatorsSetsByCompanyIdAsArray = (
	data: IGetListUnitIndicatorsSetsResponse,
	companyId?: string
) =>
	data.allIds.reduce((acc: IUnitIndicatorsSet[], id) => {
		const set = data.byId[id];
		if (companyId && set.companyId === companyId) {
			acc.push(set);
		}
		return acc;
	}, []);
