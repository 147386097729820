import {CSSProperties, ReactNode} from 'react';
import './ExpandablePlate.less';
import classNames from 'classnames';
import Plate from '../Plate';
import ExpandablePanel from '../ExpandablePanel';
import useToggle from 'react-use/lib/useToggle';
import ExpandButton from './components/ExpandButton';
import {convertClassNames} from '../../../utils/convertClassNames';

interface IExpandablePlateProps {
	className?:
		| string
		| {
				root?: string;
				expandButton?: string;
				content?: string;
		  };
	style?: CSSProperties;
	children?: ReactNode;
	title?: ReactNode;
	initiallyClosed?: boolean;
}

const ExpandablePlate = ({
	className,
	style,
	children,
	title,
	initiallyClosed
}: IExpandablePlateProps) => {
	const [expanded, toggleExpanded] = useToggle(!initiallyClosed);
	const classes = convertClassNames(className);

	return (
		<Plate
			className={{
				root: classNames('expandable-plate', classes.root),
				content: 'expandable-plate__plate-content'
			}}
			style={style}
			header={
				<ExpandButton
					className={classNames('expandable-plate__expand-btn', classes.expandButton)}
					label={title}
					expanded={expanded}
					onClick={toggleExpanded}
				/>
			}
		>
			<ExpandablePanel expanded={expanded}>
				<div className={classNames('expandable-plate__content', classes.content)}>
					{children}
				</div>
			</ExpandablePanel>
		</Plate>
	);
};

ExpandablePlate.displayName = 'ExpandablePlate';

export default ExpandablePlate;
