import {useMutation} from '@tanstack/react-query';
import {ISavingWarrantyClaim} from '@src/interfaces/saving/ISavingWarrantyClaim';
import {IWarrantyClaim} from '@tehzor/tools/interfaces/warrantyClaims/IWarrantyClaim';
import {makeWarrantyClaimsAddRequest} from '@src/api/backend/warrantyClaims';

export interface IAddMultipleWarrantyClaimsParams {
	objectId: string;
	spaceIds: string[];
	links: IWarrantyClaim['links'];
	savingData: ISavingWarrantyClaim;
	setWarrantyClaimView: (view: 'claim' | 'problem') => void;
}
/**
 * Хук для добавления нескольких гарантийных обращений
 */

export const useAddMultipleWarrantyClaims = () => {
	const warrantyClaimsMutation = useMutation<
		IWarrantyClaim[],
		Error,
		IAddMultipleWarrantyClaimsParams
	>({
		mutationFn: async ({
			objectId,
			spaceIds,
			links,
			savingData
		}: IAddMultipleWarrantyClaimsParams): Promise<IWarrantyClaim[]> =>
			makeWarrantyClaimsAddRequest(objectId, spaceIds, links, savingData),

		onSuccess: (response, {setWarrantyClaimView}) => {
			if (response.length) {
				setWarrantyClaimView('problem');
			} else {
				console.error('Обращения не созданы!');
			}
		},

		onError: error => {
			console.error('Ошибка при создании гарантийных обращений:', error);
		}
	});

	return async ({
		objectId,
		spaceIds,
		links,
		savingData,
		setWarrantyClaimView
	}: IAddMultipleWarrantyClaimsParams): Promise<IWarrantyClaim[]> =>
		warrantyClaimsMutation.mutateAsync({
			objectId,
			spaceIds,
			links,
			savingData,
			setWarrantyClaimView
		});
};
